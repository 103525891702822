import { AppAction, Common, User } from "../../constants/actionTypes";

export interface CraftsmanState {
  requests: any[];
  count: number;
}

const initialState: CraftsmanState = {
  requests: [],
  count: 0,
};

export const craftsmanReducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case User.GET_LINK_REQUESTS:
      return { ...state, loading: true };
    case User.GET_LINK_REQUESTS_SUCCEEDED:
      return { ...state, loading: false, requests: action.payload.results, count: action.payload.count };
    case Common.GOT_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};
