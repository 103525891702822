import React, { useState } from "react";
import { Company, CraftsmanType } from "../../../interfaces/models";
import { useUser } from "../../../common/hooks";
import { useUserActions } from "../../../pages/User/userActions";
import CreateCompanyDialog from "../../../shared/CreateCompanyDialog";
import DataTable, { DataTableProps } from "../../../shared/DataTable";
import { Button, Grid, IconButton, Menu, MenuItem, styled } from "@mui/material";
import { CraftsmanTypes } from "../../../constants/appConstants";
import { MoreVert } from "@mui/icons-material";
import GeneralDialog from "../../../shared/GeneralDialog";
import { StyledSmallIconButton } from "../../components/Styled";

const ActionsMenu = (props: {
  updateCompany: (company: Company | undefined) => void;
  deleteCompany: (company: Company | undefined) => void;
  item?: Company;
}) => {
  const { item } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateCompany = () => {
    setAnchorEl(null);
    props.updateCompany(item);
  };

  const deleteCompany = () => {
    setAnchorEl(null);
    props.deleteCompany(item);
  };

  return (
    <div>
      <StyledSmallIconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert fontSize="inherit" />
      </StyledSmallIconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={updateCompany}>update</MenuItem>
        <MenuItem onClick={deleteCompany}>delete</MenuItem>
      </Menu>
    </div>
  );
};

const CompanyTable = (props: DataTableProps<Company>) => <DataTable {...props} />;

const Companies = () => {
  const [current, setCurrent] = useState<Company | undefined>();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const actions = useUserActions();
  const user = useUser();

  const handleCloseCreate = (created?: any) => {
    setOpenCreateDialog(false);
    if (created) {
      setRefetch(true);
    }
  };

  const getCompanies = async (
    query: string,
    filter: any,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => {
    await actions.getCompanies(query, filter, skip, take, sortField, sortDescending, false);
    setRefetch(false);
  };

  const deleteCompany = async (confirmed?: boolean) => {
    if (confirmed && current) {
      try {
        await actions.deleteCompany(current.id);
        setRefetch(true);
      } catch (error: unknown) {
      } finally {
        setCurrent(undefined);
      }
    }
    setOpenDelete(false);
  };

  const openDeleteDialog = (company: Company | undefined) => {
    setCurrent(company);
    setOpenDelete(true);
  };

  const openUpdateDialog = (company: Company | undefined) => {
    setCurrent(company);
    setOpenCreateDialog(true);
  };

  return (
    <>
      <CompanyTable
        columns={[
          { label: "Name", key: "name" },
          { label: "Email", key: "email" },
          { label: "Address", key: "address" },
          { label: "City", key: "city" },
          { label: "CVR", key: "cvr" },
          { label: "Phone", key: "phone" },
        ]}
        dropdownFilterValues={Object.keys(CraftsmanTypes)
          .map((x) => parseInt(x) as CraftsmanType)
          .sort((a, b) => {
            if (b === CraftsmanType.None) return 1;
            if (a === CraftsmanType.Other) return 1;
            return CraftsmanTypes[a] > CraftsmanTypes[b] ? 1 : -1;
          })
          .map((x) => {
            return { id: x, value: CraftsmanTypes[x] };
          })}
        dropdownFilterTitle="Type"
        count={user.companiesCount}
        data={user.companies}
        getData={getCompanies}
        textFilter
        refetch={refetch}
        cellActionComponent={
          <ActionsMenu
            updateCompany={(company) => openUpdateDialog(company)}
            deleteCompany={(company) => openDeleteDialog(company)}
          />
        }
      />
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateDialog(true)}
            style={{ marginTop: 20 }}
          >
            Add company
          </Button>
        </Grid>
      </Grid>
      <CreateCompanyDialog open={openCreateDialog} handleClose={handleCloseCreate} company={current}/>
      <GeneralDialog
        open={openDelete}
        dialogTitle={"Ønske du at slette denne virksomhed?"}
        handleClose={deleteCompany}
      />
    </>
  );
};

export default Companies;
