import { applyMiddleware, createStore, combineReducers, compose, Middleware } from "redux";
import { thunk } from "redux-thunk";
import commonReducer, { CommonState } from "../common/commonReducer";
import userReducer, { UserState } from "../pages/User/userReducer";
import taskReducer, { TaskState } from "../actions/tasklist/taskReducer";
import { craftsmanReducer, CraftsmanState } from "../actions/craftsman/craftsmanReducer";
import { signalrMiddleware } from "./signalrMiddleware";
import adminReducer, { AdminState } from "../admin/adminReducer";

export type AppState = {
  common: CommonState;
  user: UserState;
  task: TaskState;
  craftsman: CraftsmanState;
  admin: AdminState;
};

const rootReducer = combineReducers<AppState>({
  common: commonReducer,
  user: userReducer,
  task: taskReducer,
  craftsman: craftsmanReducer,
  admin: adminReducer,
});

const actionLogger: Middleware<{}, AppState> = (store) => (next) => async (action) => {
  console.info(new Date().toLocaleString(), "action", action.type);
  console.info("prev state", store.getState());
  console.info("action", action);
  const result = next(action);
  console.info("next state", store.getState());
  return result;
};

//put here any additional middleware
const middlewares: Middleware<{}, AppState>[] = [thunk, signalrMiddleware];

//adding logger for the dev build
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewares.push(actionLogger);
}

const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)));
export default store;
