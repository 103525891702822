import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Common, User } from "../../constants/actionTypes";
import { handleError } from "../../common/commonActions";
import { LocalStorageItems } from "../../constants/appConstants";
import { updateLocalData, getLocalData, removeLocalData } from "../../common/utils";
import UserService from "../../services/UserService";
import { useDispatch } from "react-redux";
import {
  CompanyRole,
  CraftsmanType,
  CreateCompanyCommand,
  ManageTeamCommand,
  UpdateCompanyCommand,
  UpdateUserCommand,
  UserRole,
} from "../../interfaces/models";
import { UserState } from "./userReducer";

import { AppState } from "../../store/store";

import ApiService from "../../services/ApiService";
import { AppError } from "../../interfaces/frontend";

export const useUserActions = () => {
  const dispatch: ThunkDispatch<AppState, any, AnyAction> = useDispatch();

  return {
    login: (email: string, password: string) => dispatch(login(email, password)),
    logout: () => dispatch(logout()),
    authenticateLocal: () => dispatch(authenticateLocal()),
    getProfile: () => dispatch(getProfile()),
    checkProfile: () => dispatch(checkProfile()),
    updateProfile: (user: UpdateUserCommand) => dispatch(updateProfile(user)),
    changePassword: (currentPassword: string, newPassword: string) =>
      dispatch(changePassword(currentPassword, newPassword)),
    getRating: () => dispatch(getRating()),
    getRatingValues: (skip: number, take: number) => dispatch(getRatingValues(skip, take)),
    getTeam: () => dispatch(getTeam()),
    manageTeam: (command: ManageTeamCommand) => dispatch(manageTeam(command)),
    getCompanies: (
      query: string,
      filter: CraftsmanType,
      skip: number,
      take: number,
      sortField: string,
      sortDescending: boolean,
      excludeTeam?: boolean
    ) => dispatch(getCompanies(query, filter, skip, take, sortField, sortDescending, excludeTeam)),
    createCompany: (cmd: CreateCompanyCommand) => dispatch(createCompany(cmd)),
    updateCompany: (cmd: UpdateCompanyCommand) => dispatch(updateCompany(cmd)),
    getCompanyById: (id: string) => dispatch(getCompanyById(id)),
    deleteCompany: (id: string) => dispatch(deleteCompany(id)),
    updateEmployeeRole: (id: string, role: CompanyRole) => dispatch(updateEmployeeRole(id, role)),
    deleteEmployee: (id: string) => dispatch(deleteEmployee(id)),

  };
};

const login = (email: string, password: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: User.LOGIN_REQUEST });
  try {
    let user = await UserService.login(email, password);
    if (user && ![UserRole.Root, UserRole.Administrator, UserRole.Craftsman].includes(user.role)) {
      await dispatch(logout());
      handleError("Login mislykkedes", dispatch, { type: User.LOGIN_FAILED });
      return;
    }

    if (user) {
      await updateLocalData(LocalStorageItems.User, user);
      dispatch({ type: User.LOGIN_SUCCEEDED, payload: user });
      dispatch(performAfterLoginActions);
    }
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, { type: User.LOGIN_FAILED });
  }
};

const authenticateLocal = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  let user = await getLocalData<UserState>(LocalStorageItems.User);

  if (user && ![UserRole.Root, UserRole.Administrator, UserRole.Craftsman].includes(user.role)) {
    await dispatch(logout());
    handleError("Login mislykkedes", dispatch, { type: User.LOGIN_FAILED });
    return;
  }

  if (user && user.accessToken) {
    dispatch({ type: User.AUTH_LOCAL, payload: user });
    dispatch(performAfterLoginActions);
    return true;
  }
  dispatch({ type: User.AUTH_LOCAL_NOT_FOUND });
  return false;
};

const performAfterLoginActions = async (
  dispatch: ThunkDispatch<AppState, any, AnyAction>,
  getState: () => AppState
) => {
  const user = getState().user;
  if (user.role === UserRole.Craftsman) {
    dispatch({ type: User.GET_TEAM });
    const team = await UserService.getTeam();
    dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
  }

  dispatch({ type: Common.START_SIGNALR });
};

const logout = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  await removeLocalData(LocalStorageItems.User);
  dispatch({ type: User.LOGOUT });
  dispatch({ type: Common.STOP_SIGNALR });
  window.history.replaceState(undefined, "", "/");
  return Promise.resolve();
};

const getProfile = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    let profile = await UserService.getProfile();
    dispatch({ type: User.GET_PROFILE_SUCCEEDED, payload: profile });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, { type: User.GET_PROFILE_FAILED, payload: error, error: true });
  }
};

const checkProfile = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    let valid = await UserService.checkProfile();
    dispatch({ type: User.CHECK_PROFILE, payload: valid });
    return valid;
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, { type: User.GET_PROFILE_FAILED, payload: error, error: true });
  }
};

const updateProfile = (user: UpdateUserCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.UPDATE_PROFILE });
    let profile = await UserService.updateProfile(user);
    let localUser = await getLocalData<UserState>(LocalStorageItems.User);
    await updateLocalData(LocalStorageItems.User, { ...localUser, ...profile });
    dispatch({ type: User.UPDATE_PROFILE_SUCCEEDED, payload: profile });
    return true;
  } catch (error: unknown) {
    throw error;
  }
};

const changePassword =
  (currentPassword: string, newPassword: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      dispatch({ type: User.CHANGE_PASSWORD });
      let profile = await UserService.changePassword(currentPassword, newPassword);
      dispatch({ type: User.CHANGE_PASSWORD_SUCCEEDED, payload: profile });
    } catch (error: unknown) {
      throw error;
    }
  };

const getRating = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.GET_RATING });

    let rating = await UserService.getRating();
    dispatch({ type: User.GET_RATING_SUCCEEDED, payload: rating });
  } catch (error: unknown) {
    throw error;
  }
};

const getRatingValues = (skip: number, take: number) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.GET_RATING_VALUES });

    let values = await UserService.getRatingValues(skip, take);
    dispatch({ type: User.GET_RATING_VALUES_SUCCEEDED, payload: values });
  } catch (error: unknown) {
    throw error;
  }
};

const getTeam = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.GET_TEAM });
    const team = await UserService.getTeam();
    dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
  } catch (error: unknown) {
    throw error;
  }
};

const manageTeam = (command: ManageTeamCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.MANAGE_TEAM });

    await UserService.manageTeam(command);
    const team = await UserService.getTeam();
    dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
    throw error;
  }
};

const getCompanies =
  (
    query: string,
    filter: CraftsmanType,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean,
    excludeTeam?: boolean
  ) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      dispatch({ type: User.GET_COMPANIES });
      const result = await UserService.getCompanies(query, filter, sortField, sortDescending, excludeTeam, skip, take);
      dispatch({ type: User.GET_COMPANIES_SUCCEEDED, payload: result });
      return result;
    } catch (error: unknown) {
      throw error;
    }
  };

const getCompanyById = (id: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    return await UserService.getCompanyById(id);
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const createCompany = (cmd: CreateCompanyCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    return await ApiService.post("/api/company", cmd);
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const updateCompany = (cmd: UpdateCompanyCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ApiService.put("/api/company", cmd);
    //const team = await UserService.getTeam();
    //dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
    //return team;
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const deleteCompany = (id: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ApiService.delete(`/api/company/${id}`);
    return true;
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const updateEmployeeRole = (id: string, role: CompanyRole) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ApiService.put(`/api/company/employee/role`, { employeeId: id,  role });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const deleteEmployee = (id: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ApiService.delete(`/api/company/employee/${id}`);
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};
