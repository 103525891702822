import React, { useEffect, useReducer } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCompanyDialog from "../dialogs/AddCompanyDialog";
import { CraftsmanTypes } from "../../../constants/appConstants";
import { useUser } from "../../../common/hooks";
import { useUserActions } from "../userActions";
import { CompanyListItem, CraftsmanType, MemberAction } from "../../../interfaces/models";
import { StateAction } from "../../../interfaces/frontend";
import UpdateCompanyDialog from "../dialogs/UpdateCompanyDialog";
import GeneralDialog from "../../../shared/GeneralDialog";

const useStyles = makeStyles(() => ({
  head: {
    background: "rgba(0, 0, 0, 0.08)",
    height: 50,
    padding: 8,
    fontWeight: 600,
    alignItems: "center",
    display: "flex",
  },
  padding: {
    padding: 8,
  },
}));

type TeamPanelState = {
  addCompanyDialogOpen: boolean;
  addCompanyDialogType: CraftsmanType;
  selectedCompany: CompanyListItem | undefined;
  leaveTeamDialogOpen: boolean;
};

const reducer = (state: TeamPanelState, action: StateAction<TeamPanelState>) => {
  return { ...state, [action.type]: action.payload };
};

const initialState = {
  addCompanyDialogOpen: false,
  addCompanyDialogType: CraftsmanType.None,
  selectedCompany: undefined,
  leaveTeamDialogOpen: false,
};

const TeamPanel = () => {
  const classes = useStyles();
  const user = useUser();
  const userActions = useUserActions();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getAsync = async () => {
      await userActions.getTeam();
    }
    getAsync();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openChangeCompanyDialog = (company: CompanyListItem) => {
    dispatch({ type: "selectedCompany", payload: company });
  };

  const closeAddCompanyDialog = async (companyId?: string) => {
    dispatch({ type: "addCompanyDialogOpen", payload: false });
    if (companyId) {
      try {
        await userActions.manageTeam({
          actorId: user.id,
          teamId: user.team.id,
          memberId: companyId,
          action: MemberAction.AddMember,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const removeFromTeam = async (companyId: string) => {
    try {
      await userActions.manageTeam({
        actorId: user.id,
        teamId: user.team.id,
        memberId: companyId,
        action: MemberAction.RemoveMember,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const closeUpdateCompanyDialog = () => {
    dispatch({ type: "selectedCompany", payload: undefined });
  };

  const openLeaveTeamDialog = () => {
    dispatch({ type: "leaveTeamDialogOpen", payload: true });
  };

  const closeLeaveTeamDialog = async (accepted?: boolean) => {
    dispatch({ type: "leaveTeamDialogOpen", payload: false });
    try {
      if (accepted) {
        await removeFromTeam(user.companyId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="button">Team</Typography>
      </Grid>
      <Grid item container justifyContent="flex-end">
        {user.team.managerId === user.id && (
        <Button
          sx={{ marginBottom: 2, width: 200 }}
          variant="contained"
          color="primary"
          onClick={() => dispatch({ type: "addCompanyDialogOpen", payload: true })}
        >
          Tilføj et firma til dit hold
        </Button>)}
      </Grid>
      <Grid item container direction="column">
        <Grid container alignItems="center">
          <Grid className={classes.head} item xs={5}>
            Virksomhed
          </Grid>
          <Grid className={classes.head} item xs={4}>
            Kompetencer
          </Grid>
          <Grid container justifyContent="center" className={classes.head} item xs={3}>
            Action
          </Grid>
        </Grid>
        {user.team.members!.map((member) => (
          <Paper variant="outlined" square key={member.id}>
            <Grid container>
              <Grid className={classes.padding} container direction="column" item xs={5}>
                <Typography style={{ fontWeight: 600 }} variant="body1" gutterBottom>
                  {member.name}
                </Typography>
                <Typography variant="body2">{member.address + " " + member.postCode + " " + member.city}</Typography>
              </Grid>
              <Grid className={classes.padding} container direction="column" item xs={4}>
                {member.ownCompany ? (
                  <>
                    {Object.keys(CraftsmanTypes)
                      .map((x) => parseInt(x) as CraftsmanType)
                      .sort((a, b) => (CraftsmanTypes[a] > CraftsmanTypes[b] ? 1 : -1))
                      .filter((x) => user.competencies.some((competence) => competence === x))
                      .map((x) => {
                        return (
                          <Typography key={x} style={{ fontWeight: 600 }}>
                            {CraftsmanTypes[x]}
                          </Typography>
                        );
                      })}
                    {Object.keys(CraftsmanTypes)
                      .map((x) => parseInt(x) as CraftsmanType)
                      .sort((a, b) => (CraftsmanTypes[a] > CraftsmanTypes[b] ? 1 : -1))
                      .filter((x) => x !== CraftsmanType.None && x !== CraftsmanType.Other)
                      .filter((x) => !user.team.members?.flatMap((m) => m.competencies)?.some((c) => c === x))
                      .map((x) => {
                        return <Typography key={x}>{CraftsmanTypes[x]}</Typography>;
                      })}
                  </>
                ) : (
                  <>
                    {member.competencies
                      ?.sort((a, b) => (CraftsmanTypes[a] > CraftsmanTypes[b] ? 1 : -1))
                      // .filter((x) =>
                      //   member.ownCompany ? true : !user.competencies.some((competence) => competence === x)
                      // )
                      .map((x) => {
                        return (
                          <Typography
                            key={x}
                            style={user.competencies.some((competence) => competence === x) ? {} : { fontWeight: 600 }}
                          >
                            {CraftsmanTypes[x]}
                          </Typography>
                        );
                      })}
                  </>
                )}
              </Grid>
              <Grid
                container
                justifyContent="center"
                className={classes.padding}
                alignContent="center"
                item
                xs={3}
                spacing={1}
              >
                {user.team.managerId === user.id && !member.ownCompany ? (
                  <>
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={() => openChangeCompanyDialog(member)}>
                        Ændre
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" onClick={() => removeFromTeam(member.id)}>
                        Slet
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    {member.id === user.companyId && user.team.managerId !== user.id &&(
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={() => openLeaveTeamDialog()}>
                          Forlad hold
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
      <AddCompanyDialog
        handleClose={closeAddCompanyDialog}
        open={state.addCompanyDialogOpen}
        type={state.addCompanyDialogType}
      />
      <UpdateCompanyDialog
        open={!!state.selectedCompany}
        handleClose={closeUpdateCompanyDialog}
        company={state.selectedCompany!}
      />
      <GeneralDialog
        open={state.leaveTeamDialogOpen}
        handleClose={closeLeaveTeamDialog}
        dialogTitle="Forlad hold"
        dialogText="Er du sikker på at du vil forlade holdet?"
      />
    </>
  );
};

export default TeamPanel;
