import React, { useEffect, useRef } from "react";
import { Button, Grid, IconButton, styled } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { v4 as uuidv4 } from "uuid";
import { PictureRef } from "../interfaces/models";
import { PhotoCamera } from "@mui/icons-material";
import { EmptyGuid } from "../constants/appConstants";

const useStyles = makeStyles({
  cameraButton: {
    width: 100,
    height: 80,
    borderRadius: 2,
    borderStyle: "dotted",
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 3,
  },
});

type ChangePictureButtonProps = {
  objectId?: string;
  itemId?: string;
  getPicture: (picture: PictureRef) => void;
  noPicture?: boolean;
  buttonText?: string;
  simulateClick?: boolean;
};

const ChangePictureButton = (props: ChangePictureButtonProps) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    //const timeout = setTimeout(() => {
    if (props.simulateClick) {
      showFileUpload();
    }
    //}, 100);
    return () => {
      //clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showFileUpload = () => {
    if (inputRef && inputRef.current) inputRef.current.click();
  };

  const capture = (imageData: string) => {
    if (imageData) {
      const image: PictureRef = {
        id: uuidv4(),
        objectId: props.objectId || EmptyGuid,
        itemId: props.itemId || EmptyGuid,
        url: "",
        data: imageData,
        featureId: EmptyGuid,
      };
      props.getPicture(image);
    }
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          capture(reader.result as string);
        }
      };
      reader.onerror = (error) => console.error(error);
    }
  };

  return (
    <>
      {props.buttonText ? (
        <Button variant="outlined" color="secondary" onClick={showFileUpload}>
          {props.buttonText}
        </Button>
      ) : (
        <Grid item className={classes.cameraButton}>
          <IconButton onClick={showFileUpload} size="large">
            <PhotoCamera />
          </IconButton>
        </Grid>
      )}
      <HiddenInput
        ref={inputRef}
        className="FileInput"
        type="file"
        accept="image/*"
        capture="environment"
        multiple
        onChange={onFilesAdded}
      />
    </>
  );
};

export default ChangePictureButton;


const HiddenInput = styled("input")(() => ({ display: "none" }));