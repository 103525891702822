import { LocalStorageItems } from "../constants/appConstants";
import localForage from "localforage";

const store = localForage.createInstance({
  name: "HandyToolDB",
  version: 1.0,
});

export const updateLocalData = async (key: LocalStorageItems, data: any) => {
  if (data) {
    try {
      await store.setItem(key, data, (err, val) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          console.warn("LS set:", key, err, val);
        }
      });
    } catch (error: unknown) {
      console.warn(error);
    }
  }
};

export const getLocalData = async <T>(key: any) => {
  try {
    return await store.getItem<T>(key, (err, val) => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.warn("LS get: ", key, err, val);
      }
    });
  } catch (error: unknown) {
    console.warn(error);
  }
};

export const removeLocalData = async (key: any) => {
  try {
    await store.removeItem(key, (err) => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.warn("LS clear:", key, err);
      }
    });
  } catch (error: unknown) {
    console.warn(error);
  }
};

/**
 *
 * @param {{ street, houseNo, floor, doorNumber, postalCode, city }} address myHouse address model, it is used for autocomplete result
 * @param {boolean} short if true returns only street part , otherwise return the address with a city and a postal code
 */
export const formatAddressString = (
  address: {
    street: string;
    houseNo: string;
    floor: string | null;
    doorNumber: string | null;
    postalCode: string | number | null;
    city: string;
  },
  short: boolean
) => {
  let addressString = address.street;
  if (address.houseNo) {
    addressString += " " + address.houseNo;
  }
  if (address.floor) {
    addressString += ", " + address.floor;
  }
  if (address.doorNumber) {
    addressString += ", " + address.doorNumber;
  }
  if (short) return addressString;
  return addressString + ", " + address.postalCode + " " + address.city;
};

export const updateArrayValue = (
  array: any[] | undefined,
  payload: { id: string; key: string; value: any }
) => {
  if (array) {
    if (!array.filter((x) => x.id === payload.id).length) {
      let item: { id: string; [idx: string]: any } = { id: payload.id };
      item[payload.key] = payload.value;
      return [...array, item];
    }

    let newArray = array.map((x) => {
      if (x.id === payload.id) {
        let updated = { ...x };
        updated[payload.key] = payload.value;
        return updated;
      }
      return x;
    });
    return newArray;
  }
  return [];
};

export const toDataURL = (url: string) =>
  fetch(url, {
    method: "GET",
    mode: "cors",
  })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const getObjectKeyByValue = <T>(
  obj: T & { [key: string]: any },
  value: any
) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const getTimestamp = () => {
  const now = new Date();
  return parseInt(
    now.getHours().toString() + now.getMinutes() + now.getSeconds()
  );
};
