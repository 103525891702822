import React from "react";
import { IconButton, Menu, MenuItem, styled } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { User } from "../../../interfaces/models";
import { StyledSmallIconButton } from "../../components/Styled";

const UserActionsMenu = (props: {
  updateUser: (user: User | undefined) => void;
  deleteUser: (user: User | undefined) => void;
  lockUser: (user: User | undefined) => void;
  resetPassword: (user: User | undefined) => void;
  item?: User;
}) => {
  const { item } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateUser = () => {
    setAnchorEl(null);
    props.updateUser(item);
  };

  const deleteUser = () => {
    setAnchorEl(null);
    props.deleteUser(item);
  };

  const lockUser = () => {
    setAnchorEl(null);
    props.lockUser(item);
  };

  const resetPassword = () => {
    setAnchorEl(null);
    props.resetPassword(item);
  };

  return (
    <div>
      <StyledSmallIconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="inherit" />
      </StyledSmallIconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={lockUser}>{item?.locked ? "unlock" : "lock"}</MenuItem>
        <MenuItem onClick={updateUser}>update</MenuItem>
        <MenuItem onClick={deleteUser}>delete</MenuItem>
        <MenuItem onClick={resetPassword}>reset password</MenuItem>
      </Menu>
    </div>
  );
};

export default UserActionsMenu;
