import React, { ReactNode, useMemo, useReducer } from "react";
import {
  Grid,
  Typography,
  Theme,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import Picture from "../../../shared/Picture";
import { useTaskActions, useTaskState } from "../../../actions/tasklist/taskActions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { format } from "date-fns";
import { EmptyGuid } from "../../../constants/appConstants";
import { PhoneField } from "../../../shared/FormattedFields";
import { StateAction } from "../../../interfaces/frontend";

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 20,
  },
  picContainer: {
    marginBottom: -16,
    [theme.breakpoints.down("md")]: {
      width: "calc(100% + 48px)",
      marginLeft: -24,
      marginRight: -24,
      marginTop: -80,
    },
  },
  pictureWrapper: {
    maxWidth: "100%",
    overflow: "hidden",
    height: 200,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  marginTop: {
    marginTop: 60,
  },
  statusWrapper: {
    background: theme.palette.primary.main,
    margin: 0,
    borderRadius: 4,
    width: "100%",
    border: "none",
    display: "flex",
    alignItems: "center",
  },
  infoCard: {
    width: "100%",
  },
  date: {
    background: "rgba(30, 183, 17, .1)",
    color: theme.palette.primary.main,
    padding: "4px 8px",
    borderRadius: 20,
  },
  topInfo: {
    [theme.breakpoints.only("sm")]: {
      position: "relative",
      top: -8,
      width: "calc(100% + 8px)",
    },
  },
  infoBottom: {
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.only("sm")]: {
      position: "relative",
      marginTop: -8,
    },
  },
  topNoRadius: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    [theme.breakpoints.only("sm")]: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
  },
}));

interface TaskListInfoProps {
  allowUpdate: boolean;
  children: ReactNode;
  handleOpen: () => void;
}

interface PropertyInfo {
  propertyType: string;
  rooms: number;
  area: number;
  buildYear: number;
}

interface PropertyInfoState {
  data: PropertyInfo;
  isLoaded: boolean;
}

interface StateActionType extends PropertyInfoState {
  init: undefined;
}

export const initialState: PropertyInfoState = {
  data: {
    propertyType: "",
    rooms: 0,
    area: 0,
    buildYear: 0,
  },
  isLoaded: false,
};

//use local reduce for PropertyInfoState because it's only used in this component and do not save on backend 
const reducer = (state: PropertyInfoState, action: StateAction<StateActionType>) => {
  return { ...state, [action.type]: action.payload };
};

const TaskListInfo = (props: TaskListInfoProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const taskState = useTaskState();
  const taskActions = useTaskActions();
  const { children } = props;
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const [state, dispatch] = useReducer(reducer, initialState);

  useMemo(() => {
    if (taskState.data.houseId)
      taskActions.getHouseInfo(taskState.data.houseId).then((res) => {
        if (res) {
          dispatch({
            type: "data",
            payload: {
              propertyType: res.PropertyType,
              rooms: res.UnitData.Rooms,
              area: res.UnitData.Area,
              buildYear: res.UnitData.BuildYear,
            },
          });
          dispatch({ type: "isLoaded", payload: true });
        }
      });
    return () => {
      dispatch({ type: "init", payload: initialState });
    };
  }, [taskState.data.houseId]);

  return (
    <Grid container spacing={1} direction="column" className={classes.inputContainer}>
      <Grid item container className={classes.picContainer}>
        <Card variant="outlined" className={classes.infoCard}>
          {/* property picture */}
          {taskState.data?.picture && (
            <>
              <Grid item container justifyContent="center" className={classes.pictureWrapper}>
                <Picture
                  id={"house-image"}
                  {...props}
                  mainPicture
                  source={taskState.data.picture}
                  width={300}
                  onClick={() => {}}
                />
              </Grid>
            </>
          )}
          {/* picture */}
        </Card>
      </Grid>
      <Grid item container spacing={1} direction={!isSm ? "column" : "row"} className={classes.topInfo}>
        <Grid item container xs={12} sm={taskState.id !== EmptyGuid ? 6 : 12} md={12}>
          <Card variant="outlined" className={`${classes.infoCard} ${classes.topNoRadius}`}>
            <StyledCardContent>
              <Typography variant="body1">
                {taskState.data && taskState.data.address && `${taskState.data.address}, ${taskState.data.postcode}`}
              </Typography>
              <Typography variant="body1">
                {taskState.data && taskState.data.address && `${taskState.data.city}`}
              </Typography>
              {state.isLoaded && (
                <>
                  <Grid item container>
                    <Divider style={{ width: "100%", margin: "5px 0px" }} variant="fullWidth" />
                  </Grid>

                  <Grid container spacing={2} display="flex" direction="row" wrap="nowrap" xs={12} sm={4} md={12}>
                    <Grid item container direction="column">
                      <InputLabel>Type</InputLabel>
                      <Typography variant="body1">{state.data.propertyType}</Typography>
                    </Grid>

                    <Grid item container direction="column">
                      <InputLabel>Rum</InputLabel>
                      <Typography variant="body1">{state.data.rooms}</Typography>
                    </Grid>

                    <Grid item container direction="column">
                      <InputLabel>Areal</InputLabel>
                      <Typography variant="body1">{state.data.area}</Typography>
                    </Grid>

                    <Grid item container direction="column">
                      <InputLabel>Byggeår</InputLabel>
                      <Typography variant="body1">{state.data.buildYear}</Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </StyledCardContent>
          </Card>
        </Grid>
        {taskState.id !== EmptyGuid && (
          <Grid item container xs={12} sm={6} md={12}>
            <Card variant="outlined" className={classes.statusWrapper}>
              <StyledCardContent>
                <Grid item container direction="column">
                  <Typography gutterBottom variant="body2" style={{ color: "#fff" }}>
                    STATUS
                  </Typography>
                  {children}
                </Grid>
              </StyledCardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      {/* Personal info card */}
      <Grid item container xs={12} className={classes.infoBottom}>
        <Card variant="outlined" className={classes.infoCard}>
          <CardContent>
            <Grid container direction="column">
              <Grid item container justifyContent="space-between" alignItems="center">
                <Typography variant="h4">Information</Typography>
                <StyledIconButton disabled={!props.allowUpdate} onClick={props.handleOpen} color="primary">
                  <EditOutlinedIcon />
                </StyledIconButton>
              </Grid>
              <Grid item container>
                <Grid item container spacing={2} direction="column" xs={12} sm={4} md={12}>
                  <Grid item container direction="column">
                    <InputLabel>Fornavn</InputLabel>
                    <Typography variant="body1">
                      {taskState.data?.owner ? taskState.data?.owner : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                  <Grid item container direction="column">
                    <InputLabel>Efternavn</InputLabel>
                    <Typography variant="body1">
                      {taskState.data?.ownerLastName ? taskState.data?.ownerLastName : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                  <Grid item container direction="column">
                    <InputLabel>Telefon</InputLabel>
                    <Typography variant="body1" component={"span"}>
                      {taskState.data?.ownerPhone ? <PhoneField value={taskState.data?.ownerPhone} /> : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container spacing={2} direction="column" xs={12} sm={4} md={12}>
                  <Grid item container direction="column">
                    <InputLabel>Email</InputLabel>
                    <Typography variant="body1">
                      {taskState.data?.ownerEmail ? taskState.data?.ownerEmail : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                  <Grid item container direction="column">
                    <InputLabel>Adresse</InputLabel>
                    <Typography variant="body1">
                      {taskState.data?.address ? taskState.data?.address : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                  <Grid item container direction="column">
                    <InputLabel>Postnr.</InputLabel>
                    <Typography variant="body1">
                      {taskState.data?.postcode ? taskState.data?.postcode : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container spacing={2} direction="column" xs={12} sm={4} md={12}>
                  <Grid item container direction="column">
                    <InputLabel>By</InputLabel>
                    <Typography variant="body1">{taskState.data?.city ? taskState.data?.city : <>&nbsp;</>}</Typography>
                  </Grid>
                  <Grid item container direction="column">
                    <InputLabel>Kommunenr./Ejendomsnr.</InputLabel>
                    <Typography variant="body1">
                      {taskState.data?.cadastral ? taskState.data?.cadastral : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                  {!!taskState.data?.caseNumber && taskState.data?.caseNumber !== 0 && (
                    <Grid item container direction="column">
                      <InputLabel>Internt sagsnummer</InputLabel>
                      <Typography variant="body1">{taskState.data?.caseNumber}</Typography>
                    </Grid>
                  )}
                  <Grid item container direction="row">
                    <Typography className={classes.date} variant="body2">
                      Dato for besigtigelse:{" "}
                      {format(taskState.data ? new Date(taskState.data.reportDate) : new Date(), "dd.MM.yy")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Personal info card end */}
    </Grid>
  );
};

export default TaskListInfo;

const StyledIconButton = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}))(IconButton);

const StyledCardContent = withStyles(() => ({
  root: {
    width: "100%",
    "&:last-child": {
      paddingBottom: 16,
    },
  },
}))(CardContent);
