import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdminResetPasswordCommand, User } from "../../../interfaces/models";
import { useUser } from "../../../common/hooks";

interface UserDialogProps {
  setOpenDialog: (arg0: boolean) => void;
  clearData: () => void;
  resetPassword: (user: AdminResetPasswordCommand) => void;
  open: boolean;
  user: User | undefined;
}

const initialState = {
  newPassword: "",
  confirmedPassword: "",
};

const UserDialog = (props: UserDialogProps) => {
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(false);
  const currentUser  = useUser();

  const changeDataValue = (id: string, value: string) => setData({ ...data, [id]: value });

  const handleClose = () => {
    props.setOpenDialog(false);
    setData(initialState);
    props.clearData();
  };

  const resetPassword = (user: AdminResetPasswordCommand) => {
    if(!user.userId) 
      return;
    if (data.newPassword === data.confirmedPassword) {
      user.actorId = currentUser.id;
      props.resetPassword(user);
      props.setOpenDialog(false);
      setData(initialState);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.user ? props.user.userName : "user"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="newPassword"
            label="New password"
            type="password"
            fullWidth
            value={data.newPassword}
            onChange={(e) => changeDataValue(e.target.id, e.target.value)}
          />
          <TextField
            error={error}
            helperText={error ? "Different passwords" : null}
            margin="dense"
            id="confirmedPassword"
            label="Confirm password"
            type="password"
            fullWidth
            value={data.confirmedPassword}
            onChange={(e) => changeDataValue(e.target.id, e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuller
          </Button>
          <Button
            onClick={() =>
              resetPassword({
                userId: props.user?.id || "",
                newPassword: data.newPassword,
                actorId: "",
              })
            }
            color="primary"
          >
            Skift
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDialog;
