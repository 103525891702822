import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { handleError } from "../../common/commonActions";
import { Common, User } from "../../constants/actionTypes";
import { HouseLinkRequest, LinkRequestState } from "../../interfaces/models";
import ApiService from "../../services/ApiService";
import { AppState } from "../../store/store";
import { AppError } from "../../interfaces/frontend";

export const useCraftsmanState = () => useSelector((state: AppState) => state.craftsman);
export const useCraftsmanActions = () => {
  const dispatch: ThunkDispatch<AppState, any, AnyAction> = useDispatch();

  return {
    getCustomerRequests: (
      query: string,
      filter: LinkRequestState,
      skip: number,
      take: number,
      sortField: string,
      sortDescending: boolean
    ) => dispatch(getCustomerRequests(query, filter, skip, take, sortField, sortDescending)),
    link: (houseId: string, description: string) => dispatch(link(houseId, description)),
    getCustomerLink: (houseId: string) => dispatch(getCustomerLink(houseId)),
  };
};

const getCustomerRequests = (
  query: string,
  filter: LinkRequestState,
  skip: number,
  take: number,
  sortField: string,
  sortDescending: boolean
) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: User.GET_LINK_REQUESTS });
  try {
    const response = await ApiService.get(
      `/api/craftsmen/customers?query=${query}&state=${filter}&skip=${skip}&take=${take}&sort=${sortField}&desc=${sortDescending}`
    );
    dispatch({ type: User.GET_LINK_REQUESTS_SUCCEEDED, payload: response });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, { type: Common.GOT_ERROR });
    throw error;
  }
};

const getCustomerLink = (houseId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    return await ApiService.get<HouseLinkRequest>(`/api/craftsmen/getlink/${houseId}`);
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const link = (houseId: string, description: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({
    type: User.LINK_REQUEST_TO_HOUSE,
  });
  try {
    await ApiService.post(`/api/craftsmen/link/`, { houseId, description });

    dispatch({
      type: User.LINK_REQUEST_TO_HOUSE_SUCCEEDED,
    });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, {
      type: Common.GOT_ERROR,
      payload: error,
    });
  }
};
