import { Common, User } from "../constants/actionTypes";
import { LocalStorageItems } from "../constants/appConstants";
import { removeLocalData } from "./utils";
import { AppError } from "../interfaces/frontend";
import { AppState } from "../store/store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useAppDispatch } from "./hooks";

export const clearError = () => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Common.CLEAR_ERROR });
};

export const handleError = (
  error: AppError | string,
  dispatch: ThunkDispatch<AppState, any, AnyAction>,
  action?: any
) => {
  if (typeof error === "string") {
    dispatch({
      type: Common.GOT_ERROR,
      payload: { title: "Fejl", message: error },
    });
    return;
  }
  if (error.status) {
    switch (error.status) {
      case 401:
        dispatch({ type: User.CLEAR_USER_DATA });
        //TODO: handle promise or change to async
        removeLocalData(LocalStorageItems.User).then(() => {
          if (action) {
            dispatch(action);
          }
        });
        return;
      default:
        dispatch({ type: Common.GOT_ERROR, payload: { title: "Fejl", message: error.statusText } });
        if (action) {
          dispatch(action);
        }
        return;
    }
  }

  if (error.message === "Failed to fetch") {
    error.message = "Kunne ikke oprette forbindelse til serveren";
  }
  dispatch({ type: Common.GOT_ERROR, payload: error });
  if (action) {
    dispatch(action);
  }
};

export const useCommonActions = () => {
  const dispatch = useAppDispatch();

  return {
    clearError: () => dispatch(clearError()),
  };
};
