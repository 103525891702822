import React from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles({
  loadingSpinner: {
    position: "fixed",
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)"
  }
});

const LoadingOverlay = (props: { loading: boolean; value?: number; }) => {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.loadingSpinner}>
       {props.value ? (
        <CircularProgress variant="determinate" value={props.value} />
      ) : (
        <CircularProgress />
      )}
    </div>
  ) : null;
};

export default LoadingOverlay;
