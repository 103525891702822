import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PlaceholderHouse from "./../assets/img/placeholderHouse.jpg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    picture: (props: any) => ({
        width: props.width || 200,
        maxHeight: props.height || 200,
        display: "flex",
        justifyContent: "center",
      }),
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  mainPicture: {
      width: "100%",
      minWidth: "100%"
},
  hidden: {
    display: "none",
  },
}));

export interface PictureProps {
  onClick: (pictureId: any) => void;
  id: string;
  source: string | undefined;
  width: number;
  height?: number;
  mainPicture?: boolean;
};

const Picture = (props: PictureProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles({ ...props });

  useEffect(() => {
    if (error) {
      setError(false);
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.source]);

  const imageLoaded = (event: any) => {
    setLoaded(true);
    setError(false);
  };

  const handleError = () => {
    setError(true);
  };

  return (
         <>
            {props.source && !error ? (
            <Grid container justifyContent="center" alignItems="center" sx={{height: "100%"}}>
              <img
                src={props.source}
                alt=""
                className={!props.mainPicture? classes.img : clsx(classes.img, classes.mainPicture)}
                onLoad={imageLoaded}
                onError={handleError}
              />
              {!loaded && <CircularProgress style={{position: "absolute"}} />}
            </Grid>
            ) : (
            <img className={classes.img} src={PlaceholderHouse} alt="" />
          )}
          </>
  );
};

export default Picture;
