import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();

  return (
    <Grid
      item
      container
      sx={{
        //marginTop: 4,
        backgroundColor: theme.palette.divider,
        padding: "30px 0",
        "& p": {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "25px 0",
        },
      }}
      justifyContent="center"
    >
      <Grid container item justifyContent="center" direction="column" xs={10}>
        <Typography>myhouse</Typography>
        <Typography>Fredensborg Kongevej 56, 2980 Kokkedal</Typography>
        <Typography>cvr. 35056823</Typography>
        <Typography>e-mail: info@myhouse.dk</Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
