import React, { useState } from "react";
import { Modal, Button, CircularProgress, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ArrowBack } from "@mui/icons-material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import clsx from "clsx";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      zIndex: 1300,
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
    },
    topBar: {
      display: "flex",
      backgroundColor: "black",
      height: 64,
      width: "100%",
      alignItems: "center",
    },
    picture: (props) => ({
      display: "flex",
      width: "100%",
      maxHeight: "100%",
      flex: "1 auto",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    }),
    button: {
      color: "white",
    },
    buttonRight: {
      "&:only-child": {
        marginLeft: "auto",
      },
    },
    buttonBlock: {
      width: "100%",
      height: 100,
      backgroundColor: "black",
    },
    buttonContainer: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "space-between",
    },
    img: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    hidden: {
      display: "none",
    },
  })
);

const ImageModal = (props: { shown?: boolean; handleClose?: () => void; source: string | undefined; remove?: (event: React.MouseEvent<HTMLButtonElement>) => void; }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
   const classes = useStyles();

  const { source } = props;


  const imageLoaded = () => {
    setLoaded(true);
    setError(false);
  };

  return (
    <Modal open={props.shown || false} onClose={props.handleClose} className={classes.modal}>
      <div className={classes.mainContainer}>
        <div className={classes.topBar}>
          <Button onClick={props.handleClose} className={classes.button}>
            <ArrowBack style={{ color: "white" }} />
            Tilbage
          </Button>
        </div>
        <div className={classes.picture}>
          {props.source && !error ? (
            <>
              <img
                src={source}
                alt=""
                className={clsx(!loaded && classes.hidden, classes.img /*, orientation && classes.orientation*/)}
                onLoad={imageLoaded}
                onError={() => setError(true)}
              />
              {!loaded && !error && <CircularProgress />}
            </>
          ) : (
            <Typography variant="h4">Indlæsningsfejl</Typography>
          )}
        </div>
        <div className={classes.buttonBlock}>
          <div className={classes.buttonContainer}>
            {!!props.remove && (
              <Button onClick={props.remove} className={clsx(classes.button, classes.buttonRight)}>
                <DeleteForeverOutlinedIcon style={{ color: "white" }} />
                Slet
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageModal;
