import React, { useEffect } from "react";
import { Box, CssBaseline, Grid, Toolbar, Typography } from "@mui/material";
import Sidebar from "./components/AdminSidebar";
import { Route, Routes, useLocation, useMatch, useNavigate } from "react-router-dom";
import Users from "./sections/users/Users";
import UserProfile from "../pages/User/components/UserProfile";
import ToolBar from "./components/ToolBar";
import Companies from "./sections/companies/Companies";

const AdminPanel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch("/admin");

  useEffect(() => {
    if (match) {
      navigate("users");
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ToolBar >
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant={"h6"}>
              Admin panel / {location.pathname.split("/").pop()?.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
      </ToolBar>
      <Sidebar title="Admin panel" />
      <Box sx={{ toolbar: (theme) => theme.mixins.toolbar }} />
      <Box component="main" sx={{ flexGrow: 1, p: 6 }}>
        <Routes>
          <Route path="users" element={<Users />} />
          <Route path="companies" element={<Companies />} />
        </Routes>
      </Box>
      <Box sx={{ display: "block", position: "fixed", right: 0, bottom: 0 }}>
        <Typography variant="caption">
          {"v." + process.env.REACT_APP_VERSION + "." + process.env.REACT_APP_BUILD_NUMBER}
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminPanel;
