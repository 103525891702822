import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  useMediaQuery,
  useTheme,
  Grid,
  styled,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import DataTable, { DataTableProps } from "../../../shared/DataTable";
import { CompanyRole, Employee } from "../../../interfaces/models";
import { useUserActions } from "../userActions";
import { useUser } from "../../../common/hooks";
import GeneralDialog from "../../../shared/GeneralDialog";
import { StyledSmallIconButton } from "../../../admin/components/Styled";
import { MoreVert } from "@mui/icons-material";

const EmployeesTable = (props: DataTableProps<Employee>) => <DataTable {...props} />;

const ActionsMenu = (props: {
  changeRole: (employee: Employee | undefined) => void;
  remove: (employee: Employee | undefined) => void;
  item?: Employee;
}) => {
  const { item } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeRole = () => {
    setAnchorEl(null);
    props.changeRole(item);
  };

  const remove = () => {
    setAnchorEl(null);
    props.remove(item);
  };

  return (
    <div>
      <StyledSmallIconButton size="small" aria-label="more" aria-haspopup="true" onClick={handleClick}>
        <MoreVert fontSize="inherit" />
      </StyledSmallIconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={changeRole}>Ændre role</MenuItem>
        <MenuItem onClick={remove}>Slet</MenuItem>
      </Menu>
    </div>
  );
};

type EmployeesDialogProps = {
  handleClose: (value?: string) => void;
  open: boolean;
};

const EmployeesDialog = (props: EmployeesDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [count, setCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openChangeRole, setOpenChangeRole] = useState(false);
  const [current, setCurrent] = useState<Employee | undefined>();
  const [refetch, setRefetch] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const userActions = useUserActions();
  const user = useUser();

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    setRefetch(true);

    return () => {};
  }, [user.id]);

  const getEmployees = async (
    query: string,
    filter: any,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => {
    try {
      const company = await userActions.getCompanyById(user.companyId);
      setCount(company?.employees.length || 0);
      if (company?.employees.length) {
        setEmployees(company.employees);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRefetch(false);
    }
  };

  const openChangeRoleDialog = (employee?: Employee) => {
    setCurrent(employee);
    setOpenChangeRole(true);
  };

  const changeRole = async (role: CompanyRole) => {
    if (current) {
      try {
        await userActions.updateEmployeeRole(current.id, role);
        setRefetch(true);
      } catch (error) {
        console.error(error);
      } finally {
        setCurrent(undefined);
      }
    }
    setOpenChangeRole(false);
  };

  const openDeleteDialog = (employee?: Employee) => {
    setCurrent(employee);
    setOpenDelete(true);
  };

  const deleteEmployee = async (confirmed?: boolean) => {
    if (confirmed && current) {
      try {
        await userActions.deleteEmployee(current.id);
        setRefetch(true);
      } catch (error) {
        console.error(error);
      } finally {
        setCurrent(undefined);
      }
    }
    setOpenDelete(false);
  };

  return (
    <>
      <Dialog fullScreen={fullScreen} maxWidth="lg" open={props.open} onClose={() => props.handleClose()}>
        <DialogTitle>Medarbeidere</DialogTitle>
        <StyledDialogContent>
          <EmployeesTable
            //   createButtonClick={() => setOpenAddEmployee(true)}
            data={employees}
            getData={getEmployees}
            refetch={refetch}
            columns={[
              {
                label: "Navn",
                key: "firstName",
              },
              {
                label: "Efternavn",
                key: "lastName",
              },
              { label: "Email", key: "email" },
              {
                label: "Telefon",
                key: "phone",
              },
              {
                label: "Role",
                key: "role",
                transform: (role: CompanyRole) => CompanyRole[role],
              },
            ]}
            count={count}
            itemsPerPage={count}
            cellActionComponent={<ActionsMenu changeRole={openChangeRoleDialog} remove={openDeleteDialog} />}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <Grid item container xs={7} justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={() => handleClose()} color="primary">
                Ok
              </Button>
            </Grid>
          </Grid>
        </StyledDialogActions>
      </Dialog>
      <GeneralDialog
        open={openDelete}
        dialogTitle={"Ønske du at slette denne medarbeidere?"}
        handleClose={deleteEmployee}
      />
      <RoleChangeDialog
        open={openChangeRole}
        handleClose={() => setOpenChangeRole(false)}
        employeeRole={current?.role || CompanyRole.None}
        employeeId={current?.id || ""}
        handleChangeRole={changeRole}
      />
    </>
  );
};

export default EmployeesDialog;

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  root: {
    [theme.breakpoints.down("lg")]: {
      padding: 0,
    },
  },
}));

const StyledDialogActions = styled(DialogActions)({
  root: {
    justifyContent: "center",
  },
});

interface RoleChangeDialogProps {
  open: boolean;
  handleClose: () => void;
  employeeRole: CompanyRole;
  employeeId: string;
  handleChangeRole: (newRole: CompanyRole) => void;
}

const RoleChangeDialog: React.FC<RoleChangeDialogProps> = ({
  open,
  handleClose,
  employeeRole,
  employeeId,
  handleChangeRole,
}) => {
  const [newRole, setNewRole] = React.useState(CompanyRole.None);

  useEffect(() => {
    setNewRole(employeeRole);
  }, [employeeRole]);

  const handleRoleChange = () => {
    handleChangeRole(newRole);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Role</DialogTitle>
      <DialogContent>
        <Select value={newRole} onChange={(e) => setNewRole(e.target.value as CompanyRole)}>
          <MenuItem value={CompanyRole.Manager}>{CompanyRole[CompanyRole.Manager]}</MenuItem>
          <MenuItem value={CompanyRole.Worker}>{CompanyRole[CompanyRole.Worker]}</MenuItem>
          <MenuItem value={CompanyRole.None}>{CompanyRole[CompanyRole.None]}</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRoleChange} color="primary">
          Change Role
        </Button>
      </DialogActions>
    </Dialog>
  );
};
