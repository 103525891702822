import React, { useEffect, useReducer, useRef } from "react";
import {
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Card,
  CardContent,
  Theme,
  Slide,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
//import makeStyles from "@mui/styles/makeStyles";
import { EmptyGuid, getStatusText } from "../../../constants/appConstants";
import { CraftsmanTask, FeatureMetadata, TaskListStatus } from "../../../interfaces/models";
import { useTaskState, useTaskActions } from "../../../actions/tasklist/taskActions";
import Task from "./Task";
import NotificationBar, { NotificationType } from "../../../shared/Notification";
import GeneralDialog from "../../../shared/GeneralDialog";
import BottomBar from "../../../shared/BottomBar";
// import clsx from "clsx";
import LoadingOverlay from "../../../shared/LoadingOverlay";
import TaskListInfo from "./TaskListInfo";
import TaskStatus from "./TaskStatus";
import EditTaskInfoDialog from "../dialogs/EditTaskInfoDialog";
import TaskDialog from "../dialogs/TaskDialog";
import { useUser } from "../../../common/hooks";
import { useCraftsmanActions } from "../../../actions/craftsman/craftsmanActions";
import DiscountDialog, { DiscountDialogResult } from "../dialogs/DiscountDialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { makeStyles, withStyles } from "@mui/styles";
import NoteDialog from "../dialogs/NoteDialog";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 30,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.down("xl")]: {
      marginBottom: 140,
    },
    flex: 1,
    marginBottom: 60,
  },
  section: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  bottomButton: {
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  left: {
    marginLeft: 10,
  },
  sticky: {
    position: "fixed",
    background: theme.palette.background.default,
    top: 48,
    left: 240,
    right: 0,
    zIndex: 2,
    margin: 0,
    boxShadow: "0 4px 2px -2px gray;",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("lg")]: {
      left: 0,
      width: "100%",
    },
  },
  nowrap: {
    display: "flex",
    flexWrap: "nowrap",
  },
  infoCard: {
    width: "100%",
    padding: 16,
  },
}));

type CreateTaskListPageState = {
  emptyValuesDialogOpened: boolean;
  updateAddressDialogOpened: boolean;
  notificationShown: boolean;
  notificationType: NotificationType;
  notificationText: string;
  openDrawer: boolean;
  error: string;
  openSave: boolean;
  shareTaskDialogOpen: boolean;
  houseData: FeatureMetadata | null;
  openDiscountDialog: boolean;
  statusText: string;
  showlinkDialog: boolean;
  stickyTopBar: boolean;
  setOpenEditDialog: boolean;
  setOpenTaskDialog: boolean;
  setOpenNoteDialog: boolean;
  taskId: string;
};

const initialState: CreateTaskListPageState = {
  emptyValuesDialogOpened: false,
  updateAddressDialogOpened: false,
  notificationShown: false,
  notificationType: "success",
  notificationText: "",
  openDrawer: false,
  error: "",
  openSave: false,
  shareTaskDialogOpen: false,
  houseData: null,
  openDiscountDialog: false,
  statusText: "",
  showlinkDialog: false,
  stickyTopBar: false,
  setOpenEditDialog: false,
  setOpenTaskDialog: false,
  setOpenNoteDialog: false,
  taskId: "",
};

const reducer = (
  state: typeof initialState,
  action: { type: keyof CreateTaskListPageState | "showNotification"; payload: any }
) => {
  if (action.type === "showNotification") {
    return { ...state, ...action.payload };
  }
  return { ...state, [action.type]: action.payload };
};

const TaskListLayout = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const classes = useStyles();
  const taskState = useTaskState();
  const taskActions = useTaskActions();
  const craftsmanActions = useCraftsmanActions();
  const topbarRef = useRef<HTMLDivElement>(undefined!);
  const listRef = useRef<HTMLDivElement>(undefined!);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const user = useUser();

  const handleLinkRequest = async (accept?: boolean) => {
    dispatch({ type: "showlinkDialog", payload: false });
    if (accept) {
      await link();
    }
  };

  const link = async () => {
    if (taskState.data?.houseId) {
      try {
        await craftsmanActions.link(
          taskState.data.houseId,
          `${taskState.data.address}, ${taskState.data.postcode} ${taskState.data.city}`
        );

        dispatch({
          type: "showNotification",
          payload: {
            notificationShown: true,
            notificationText: "Anmodning er sendt",
            notificationType: "success",
          },
        });
      } catch (error) {
        dispatch({
          type: "showNotification",
          payload: {
            notificationShown: true,
            notificationText: JSON.stringify(error),
            notificationType: "error",
          },
        });
      }
    }
  };
  const uploadTaskList = async (share: boolean) => {
    if (taskState.totalPrice > 0 && taskState.totalPrice <= taskState.discount) {
      dispatch({
        type: "showNotification",
        payload: {
          notificationShown: true,
          notificationText: "Rabat værdi er forkert",
          notificationType: "error",
        },
      });
      return;
    }

    try {
      await taskActions.saveTaskList(share);

      dispatch({
        type: "showNotification",
        payload: {
          notificationShown: true,
          notificationText: share ? "Opgaverne er gemt og sendt til kunden" : "Opgavelisten gemt",
          notificationType: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: "showNotification",
        payload: {
          notificationShown: true,
          notificationText: error,
          notificationType: "error",
        },
      });
    }
  };

  const openShareTaskDialog = () => {
    dispatch({ type: "shareTaskDialogOpen", payload: true });
  };

  const shareTaskList = async (value?: boolean) => {
    dispatch({ type: "shareTaskDialogOpen", payload: false });
    if (value) {
      await uploadTaskList(true);
    }
  };

  const handleNotificationClose = () => {
    dispatch({ type: "notificationShown", payload: false });
  };

  const addDiscount = () => {
    dispatch({ type: "openDiscountDialog", payload: true });
  };

  const closeDiscountDialog = (dialogResult: DiscountDialogResult) => {
    dispatch({ type: "openDiscountDialog", payload: false });
    if (!dialogResult.apply) return;

    if (dialogResult.value) {
      if (dialogResult.percent) {
        let discount = (taskState.totalPrice / 100) * parseFloat(dialogResult.value);

        taskActions.setDiscount(Math.round((discount + Number.EPSILON) * 100) / 100);
        return;
      }
      taskActions.setDiscount(parseFloat(dialogResult.value));
    }
  };
  const openEditDialog = () => {
    dispatch({ type: "setOpenEditDialog", payload: true });
  };

  const openTaskDialog = (id: string) => {
    dispatch({ type: "taskId", payload: id });
    dispatch({ type: "setOpenTaskDialog", payload: true });
  };

  const openCreateDialog = () => {
    dispatch({ type: "taskId", payload: EmptyGuid });
    dispatch({ type: "setOpenTaskDialog", payload: true });
  };

  const openNoteDialog = (id: string) => {
    dispatch({ type: "setOpenNoteDialog", payload: true });
  };

  const handleCloseEditTaskDialog = (accepted: boolean | undefined, customerInfo?: FeatureMetadata) => {
    dispatch({ type: "setOpenEditDialog", payload: false });
    if (accepted && customerInfo) {
      taskActions.updateCustomerInfo(customerInfo);
    }
  };
  const handleCloseTaskDialog = (accepted: boolean | undefined, taskInfo?: CraftsmanTask) => {
    dispatch({ type: "setOpenTaskDialog", payload: false });
    if (accepted && taskInfo) {
      if (taskState.tasks.find((x) => x.id === taskInfo.id)) {
        taskActions.updateTaskInfo(taskInfo);
      } else {
        taskActions.saveTaskInfo(taskInfo);
        uploadTaskList(false);
        //give some time to render new task item
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollIntoView(true && { behavior: "smooth", block: "end" });
          }
        }, 300);
      }
    }
  };

  const handleCloseNoteDialog = (accepted: boolean | undefined, noteText?: any) => {
    dispatch({ type: "setOpenNoteDialog", payload: false });
    if (accepted && noteText) {
      taskActions.updateTaskNote(noteText);

      setTimeout(() => {
        if (listRef.current) {
          listRef.current.scrollIntoView(true && { behavior: "smooth", block: "end" });
        }
      }, 300);
    }
  };
  const errorClose = async () => {
    dispatch({ type: "error", payload: "" });
  };

  useEffect(() => {
    dispatch({ type: "statusText", payload: getStatusText(taskState.status) });
    return () => {
      dispatch({ type: "statusText", payload: "" });
    };
  }, [taskState.status]);

  const handleScroll = () => {
    if (topbarRef.current) {
      const top = topbarRef.current.getBoundingClientRect().top;
      if (top <= 100) {
        dispatch({ type: "stickyTopBar", payload: true });
      } else dispatch({ type: "stickyTopBar", payload: false });
    }
  };

  const handleMenuItemClick = (status?: TaskListStatus) => {
    if (status) {
      taskActions.changeTaskListStatus(taskState.id, status).then(
        (status) => {},
        (error) =>
          dispatch({
            type: "showNotification",
            payload: {
              notificationShown: true,
              notificationText: error,
              notificationType: "error",
            },
          })
      );
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (taskState.status === TaskListStatus.Finished && taskState.data) {
      craftsmanActions.getCustomerLink(taskState.data?.houseId).then((response) => {
        if (response) {
          if (response.houseId === EmptyGuid) dispatch({ type: "showlinkDialog", payload: true });
        }
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.status, taskState.data]);

  useEffect(() => {
    if (!taskState.tasks.length) {
      taskActions.setDiscount(0);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.tasks]);

  return (
    <>
      {state.stickyTopBar && (
        <Slide direction="down" in={state.stickyTopBar}>
          <Grid
            item
            xs={12}
            container
            className={state.stickyTopBar && classes.sticky}
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
              <Typography sx={{ paddingTop: "8px" }} variant="h5" noWrap>
                {taskState.data && taskState.data.address
                  ? `${taskState.data.address}, ${taskState.data.postcode} ${taskState.data.city}`
                  : "Ingen adresse"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} container justifyContent="flex-end" sx={{ padding: "4px" }}>
              <TaskStatus handleMenuItemClick={handleMenuItemClick} status={taskState.status} variant="default" />
            </Grid>
          </Grid>
        </Slide>
      )}
      <Container>
        <Grid
          container
          alignItems="flex-start"
          direction={isSm ? "column-reverse" : "row"}
          spacing={1}
          className={classes.container}
          ref={listRef}
        >
          <Grid item container xs={12} md={8}>
            {!isSm && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    {taskState.data &&
                      taskState.data.address &&
                      `${taskState.data.address}, ${taskState.data.postcode} ${taskState.data.city}`}
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center">
                  <Grid item xs={12} className={classes.section}>
                    <Typography variant="h4">Beskrivelse af arbejder</Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item container spacing={2} direction="column">
              {taskState.tasks.length === 0 && (
                <Grid item>
                  <Typography sx={{ marginTop: !isSm ? "20%" : "10px" }} variant={!isSm ? "h2" : "subtitle1"}>
                    Ingen registeringer endnu. Start din første...
                  </Typography>
                </Grid>
              )}

              {taskState.tasks &&
                taskState.tasks.map((x: CraftsmanTask, i: number) => (
                  <Task
                    key={i}
                    draft={taskState.status === TaskListStatus.New}
                    {...x}
                    allowUpdate
                    handleOpen={openTaskDialog}
                    companyName={user.team.members?.find((m) => m.id === x.craftsmanCompanyId)?.name || "Unset"}
                  />
                ))}
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginTop: !isSm && taskState.tasks.length === 0 ? "20px" : "0px" }}
              >
                <Card variant="outlined" className={classes.infoCard}>
                  <Grid item container justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">Note</Typography>
                    <StyledIconButton onClick={() => openNoteDialog(taskState.data.houseId)} color="primary">
                      <EditOutlinedIcon />
                    </StyledIconButton>
                  </Grid>

                  <Grid item container xs={12}>
                    <Typography variant="body1">{taskState.taskNote || "Ingen"}</Typography>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            {taskState.status !== TaskListStatus.New && !!taskState.tasks.length && (
              <Grid item container justifyContent="flex-end" spacing={2} sx={{ marginTop: "0px" }}>
                <Grid item md={8} xs={12} lg={7}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid item container direction="column" justifyContent="center" spacing={2}>
                        <Grid item container spacing={2} justifyContent="space-between">
                          <Grid item container justifyContent="space-between">
                            <Typography variant="h5">Anslået arbejdsløn inkl. moms:</Typography>
                            <Typography variant="h5">
                              {(!!taskState.totalPrice ? taskState.totalPrice : 0).toLocaleString("da-dk", {
                                currency: "DKK",
                                style: "currency",
                              })}
                            </Typography>
                          </Grid>
                          <Grid item container justifyContent="space-between">
                            <Typography variant="h5" color={taskState.totalPrice > taskState.discount ? "" : "error"}>
                              Rabat inkl. moms (
                              {taskState.totalPrice > taskState.discount
                                ? Math.floor(
                                    ((taskState.discount / taskState.totalPrice) * 100 + Number.EPSILON) * 100
                                  ) / 100
                                : 0}{" "}
                              %)
                            </Typography>
                            <Typography variant="h5" color={taskState.totalPrice > taskState.discount ? "" : "error"}>
                              {taskState.discount.toLocaleString("da-dk", { currency: "DKK", style: "currency" })}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item container justifyContent="space-between">
                            <Typography variant="h5">I alt inkl. moms</Typography>
                            <Typography variant="h5">
                              {(taskState.totalPrice > taskState.discount
                                ? taskState.totalPrice - taskState.discount
                                : 0
                              ).toLocaleString("da-dk", {
                                currency: "DKK",
                                style: "currency",
                              })}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={addDiscount}
                            disabled={!(taskState.tasks.length && taskState.status === TaskListStatus.ReadyToSend)}
                          >
                            Tilføj rabat
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
          {taskState.data && (
            <Grid item xs={12} md={4} container direction="column" justifyContent="center">
              <TaskListInfo
                handleOpen={openEditDialog}
                allowUpdate={taskState.status === TaskListStatus.New || taskState.status === TaskListStatus.ReadyToSend}
              >
                <span ref={topbarRef}>
                  <TaskStatus handleMenuItemClick={handleMenuItemClick} status={taskState.status} variant="defined" />
                </span>
              </TaskListInfo>
            </Grid>
          )}
          <BottomBar>
            <Grid container direction="row" justifyContent={isSm ? "space-between" : "flex-end"}>
              {(taskState.status === TaskListStatus.New || taskState.status === TaskListStatus.ReadyToSend) && (
                <Grid item>
                  <Button
                    className={`${classes.bottomButton} ${classes.left}`}
                    variant="contained"
                    color="primary"
                    onClick={openCreateDialog}
                  >
                    Ny opgave
                  </Button>
                </Grid>
              )}
              <Grid item className={classes.nowrap}>
                {(taskState.status === TaskListStatus.New || taskState.status === TaskListStatus.ReadyToSend) && (
                  <Button
                    className={classes.bottomButton}
                    color="primary"
                    variant="contained"
                    onClick={() => uploadTaskList(false)}
                  >
                    Gem
                  </Button>
                )}
                {taskState.status === TaskListStatus.ReadyToSend && (
                  <Grid item>
                    <Button
                      className={classes.bottomButton}
                      variant="contained"
                      color="primary"
                      onClick={openShareTaskDialog}
                    >
                      Del
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </BottomBar>
        </Grid>
      </Container>
      <GeneralDialog
        open={state.showlinkDialog}
        dialogTitle={"Blive boligens faste håndværker?"}
        dialogText={"Ønsker du at sende en anmodning om at blive boligens faste håndværker?"}
        handleClose={handleLinkRequest}
        okButtonText="Send"
        cancelButtonText="Nej"
      />
      <GeneralDialog
        open={state.shareTaskDialogOpen}
        dialogTitle={"Dele opgavelisten"}
        //dialogText={
        // "Vi har nu sendt det til kunden til godkendelse du kan se det i ”Igangværende opgaver” når det er godkendt"
        //}
        handleClose={shareTaskList}
      />
      <NotificationBar
        open={state.notificationShown}
        variant={state.notificationType}
        handleClose={handleNotificationClose}
        message={state.notificationText}
      />
      <GeneralDialog
        open={!!state.error}
        dialogTitle={"Fejl"}
        dialogText={state.error}
        handleClose={errorClose}
        hideCancel
        okButtonText="Ok"
      />
      {taskState.data && (
        <EditTaskInfoDialog
          open={state.setOpenEditDialog}
          handleClose={handleCloseEditTaskDialog}
          data={taskState.data}
        />
      )}
      <TaskDialog
        open={state.setOpenTaskDialog}
        handleClose={handleCloseTaskDialog}
        data={taskState.tasks.find((x) => x.id === state.taskId)}
        draft={taskState.status === TaskListStatus.New}
        showGetPicture={taskState.status === TaskListStatus.New}
        showFinishCheckbox={
          taskState.status === TaskListStatus.InProgress || taskState.status === TaskListStatus.Finished
        }
      />

      <DiscountDialog
        open={state.openDiscountDialog}
        handleClose={closeDiscountDialog}
        init={taskState.discount}
        maxValue={taskState.totalPrice}
      />
      <NoteDialog open={state.setOpenNoteDialog} handleClose={handleCloseNoteDialog} taskNote={taskState.taskNote} />
      <LoadingOverlay loading={taskState.loading} value={taskState.uploadProgress} />
    </>
  );
};

export default TaskListLayout;

const StyledIconButton = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}))(IconButton);
