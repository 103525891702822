import React, { useContext } from "react";
import { RegistrationContext, StepsComponentProps } from "../../RegistrationPage";
import { StyledForm } from "../StyledComponents";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import NavigationButtons from "./NavigationButtons";
import { FormattedTextField } from "../../../../shared/FormattedFields";
import UserService from "../../../../services/UserService";
import { Controller, useForm } from "react-hook-form";

const ExistingCompanyLookup = (props: StepsComponentProps) => {
  const context = useContext(RegistrationContext);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ cvr: string }>({ defaultValues: { cvr: "" } });

  const nextStep = async (data: { cvr: string }) => {
    context.cvr = data.cvr;
    try {
      setLoading(true);
      const existing = await UserService.getCompanyByCvr(data.cvr);

      if (existing) {
        context.address = existing.address || "";
        context.city = existing.city || "";
        context.postCode = existing.postCode || 0;
        context.organization = existing.organization || "";
        context.phone = existing.phone || "";
        context.competencies = existing.competencies || [];
        context.municipalCodes = existing.municipalCodes || [];
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      props.nextStep();
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(nextStep)}>
      <Grid item container xs={12} sx={{ flex: 1, px: 1, boxSizing: "border-box", alignItems: "center" }}>
        <Grid item container xs={12}>
          <Controller
            name="cvr"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormattedTextField
                format="########"
                fullWidth
                variant="outlined"
                placeholder="Indtast din virksomhed CVR nummer"
                autoComplete="off"
                error={!!errors.cvr}
                {...field}
              />
            )}
          />

          <Grid item xs={12} mt={2}>
            <Typography variant="body1">Hvis du ikke kender dit CVR nummer kan du finde det her</Typography>
            <Button
              variant="contained"
              sx={{ maxWidth: "200px" }}
              fullWidth
              color="secondary"
              target="_blank"
              href="https://datacvr.virk.dk/data/"
            >
              Søg CVR nummer
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <NavigationButtons prevStep={props.prevStep} loading={loading} isLastStep={props.isLastStep} />
    </StyledForm>
  );
};

export default ExistingCompanyLookup;
