import React from "react";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
  },
}));

const BottomBar = (props: { children: React.ReactNode }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));


  return (
    <AppBar
      position="fixed"
      color="default"
      style={{
        bottom: isMd ? 82 : 0,
        left: !isMd ? 240 : 0,
        width: !isMd ? "calc(100% - 240px)" : "100%",
      }}
      className={classes.appBar}
    >
      <Toolbar disableGutters>{props.children}</Toolbar>
    </AppBar>
  );
};

export default BottomBar;
