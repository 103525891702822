import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  useTheme,
  } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

export default function GeneralDialog(props: {
  handleClose: (value?: boolean) => Promise<void>;
  open: boolean;
  dialogTitle: string;
  dialogText?: string;
  hideCancel?: boolean;
  cancelButtonText?: string;
  okButtonText?: string;
}) {
  const handleClose = (value?: boolean) => {
    props.handleClose(value);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledDialog fullScreen={fullScreen} maxWidth="lg" open={props.open} onClose={() => props.handleClose()}>
      <DialogTitle variant="h5" align="center">
        {props.dialogTitle}
      </DialogTitle>
      {props.dialogText && (
        <StyledDialogContent>
          <DialogContentText variant="subtitle1">{props.dialogText}</DialogContentText>
        </StyledDialogContent>
      )}
      <StyledDialogActions>
        {!props.hideCancel && (
          <Button variant="outlined" onClick={() => handleClose()} color="primary">
            {props.cancelButtonText || "Nej"}
          </Button>
        )}
        <Button variant="contained" onClick={() => handleClose(true)} color="primary">
          {props.okButtonText || "Ja"}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}

const StyledDialog = withStyles({
  paper: {
    padding: 30,
    justifyContent: "center",
    alignItems: "center"
  },
})(Dialog);

const StyledDialogContent = withStyles({
 root: {
   flex: "inherit"
  },
})(DialogContent);

const StyledDialogActions = withStyles({
  root: {
    justifyContent: "center",
  },
})(DialogActions);
