import { Grid, Typography, FormControlLabel, Checkbox } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { RegistrationContext, StepsComponentProps } from "../../RegistrationPage";
import { StyledForm } from "../StyledComponents";
import NavigationButtons from "./NavigationButtons";
import { CraftsmanTypes } from "../../../../constants/appConstants";
import { CraftsmanType } from "../../../../interfaces/models";

const Competencies = (props: StepsComponentProps) => {
  const context = useContext(RegistrationContext);
  const [competencies, setCompetencies] = React.useState<number[]>([]);
  const [fee, setFee] = React.useState<number>(0);

  useEffect(() => {
    if (context.competencies) {
      setCompetencies(context.competencies);
    }
  }, [context.competencies]);

  useEffect(() => {
    setFee(competencies.length * 800);
  }, [competencies]);

  const nextStep = async () => {
    context.competencies = competencies;
    props.nextStep();
  };

  const changeCompetency = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = event.target.checked
      ? [...competencies, parseInt(event.target.id)]
      : competencies.filter((x: CraftsmanType) => x !== parseInt(event.target.id));
    setCompetencies(updated);
  };

  return (
    <StyledForm onSubmit={nextStep}>
      <Grid item xs={12} sx={{ flex: 1 }}>
        <Grid item container justifyContent={"flex-end"}>
          <Grid item>
            <Typography>
              Månedligt gebyr ex moms.:{" "}
              {fee.toLocaleString("da-dk", {
                currency: "DKK",
                style: "currency",
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container px={1} boxSizing={"border-box"} alignItems={"center"}>
          {Object.entries(CraftsmanTypes)
            //filter out CraftsmanType.None value
            .filter((x) => x[1] !== CraftsmanTypes[0])
            .sort((a, b) => {
              if (parseInt(a[0]) === 100) {
                return 1;
              }
              return a[1] > b[1] ? 1 : -1;
            })
            .map(([id, value]) => (
              <Grid key={id} item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      id={id + ""}
                      checked={competencies.indexOf(parseInt(id)) > -1}
                      onChange={changeCompetency}
                    />
                  }
                  label={value}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <NavigationButtons prevStep={props.prevStep} loading={false} isLastStep={props.isLastStep} />
    </StyledForm>
  );
};

export default Competencies;
