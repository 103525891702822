import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable, { DataTableProps } from "../../../shared/DataTable";
import { Solution, Vendor } from "../../../interfaces/models";
import { useTaskActions, useTaskState } from "../../../actions/tasklist/taskActions";

const SolutionsTable = (props: DataTableProps<Solution>) => <DataTable {...props} />;

type SolutionDialogProps = {
  open: boolean;
  onClose: () => void;
  addSolution: (solution: Solution) => void;
};

const SolutionsDialog = (props: SolutionDialogProps) => {
  const actions = useTaskActions();
  const taskState = useTaskState();

  useEffect(() => {
    const getAsync = async () => {
      await actions.getSolutions("", null, 0, 10, "Name", false);
    };
    getAsync();
    return () => {
      //
    };
  }, []);

  const getSolution = async (id: string) => {
    const solution = taskState.solutions.results?.find((s) => s.id === id);
    if (solution) {
      props.addSolution(solution);
    }
    closeDialog();
  };

  const closeDialog = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogTitle>Produkt</DialogTitle>
      <DialogContent>
        <SolutionsTable
          columns={[
            { label: "ProductName", key: "productName" },
            { label: "Code", key: "productCode", transform: (val) => val || "" },
            { label: "Vendor", key: "vendor", transform: (val: Vendor) => val?.name || "" },
            {
              label: "Price",
              key: "unitPrice",
              transform: (val: number) =>
                val
                  ? val.toLocaleString("da-dk", {
                      currency: "DKK",
                      style: "currency",
                    })
                  : "",
            },
          ]}
          data={taskState.solutions.results || []}
          getData={actions.getSolutions}
          onRowClick={getSolution}
          textFilter
          loading={taskState.loading}
          count={taskState.solutions.count}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SolutionsDialog;