import React from "react";
import { Divider, Grid, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import AppToolbar from "../../shared/AppToolbar";
import PortalLoginButtons from "../../shared/LoginPortalButtons";
import Footer from "../../shared/Footer";
import { useUser } from "../../common/hooks";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: "100vh",
      overflowX: "hidden",
      overflowY: "hidden",
      WebkitOverflowScrolling: "touch",
      maxWidth: "100%",
      padding: 0,
    },
    divider: {
      height: 12,
      background: theme.palette.grey[300],
      margin: "10px 0 30px 0",
      opacity: 0.5,
    },
    header: {
      maxHeight: 100,
      flex: 1,
    },
    info: {
      marginTop: 30,
      backgroundColor: theme.palette.primary.main,
      padding: "30px 0",
      "& p": {
        color: theme.palette.primary.contrastText,
        textAlign: "center",
        fontSize: 10,
      },
      [theme.breakpoints.down("sm")]: {
        padding: "25px 0",
      },
    },
  })
);

type MainContainerProps = {
  children: React.ReactNode;
  title: string;
  className?: string;
  ad?: React.ReactNode;
};

const MainContainer = (props: MainContainerProps) => {
  const classes = useStyles();
  const { children, title, ad } = props;
  const user = useUser();

  return (
    <>
      <AppToolbar light logo buttons={<>{!user.authorized && <PortalLoginButtons />}</>} fullWidth />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        //spacing={3}
        alignItems="center"
        sx={{ minHeight: "calc(100vh - 48px)", mt:6}}
      >
        <Grid
          item
          container
          spacing={2}
          direction="column"
          sx={{
            flex: "1 0 auto",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <Grid item justifyContent="center" alignItems="center" container className={classes.header}>
            <Typography variant="h4" style={{ letterSpacing: 5, fontWeight: 200, lineHeight: 2 }}>
              {title}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item container justifyContent="center" sx={{ paddingBottom: 0.5, flex: 1 }}>
            <Grid item xs={12} sm={10} md={9} lg={8} container justifyContent="center" spacing={2}>
              {children}
              {ad}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
      <Box sx={{ display: "block", position: "fixed", right: 0, bottom: 0 }}>
        <Typography variant="caption">
          {"v." + process.env.REACT_APP_VERSION + "." + process.env.REACT_APP_BUILD_NUMBER}
        </Typography>
      </Box>
    </>
  );
};

export default MainContainer;
