import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme, Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { useMediaQuery, Grid, Avatar } from "@mui/material";
import { menuItems, MenuItem } from "./sideMenuItems";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../common/hooks";
import { useUserActions } from "../User/userActions";
import GeneralDialog from "../../shared/GeneralDialog";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    // appBar: {
    //   [theme.breakpoints.up("sm")]: {
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     marginLeft: drawerWidth,
    //     bottom: 0,
    //   },
    // },
    photo: {
      width: 60,
      height: 60,
      marginTop: 20,
      "& .MuiAvatar-img": {
        width: "100%",
        minWidth: 60,
        minHeight: 60,
        height: "auto",
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    content: {
      flexGrow: 1,
    },
  })
);

interface SideMenuProps {
  children: React.ReactNode;
}

export default function SideMenu(props: SideMenuProps) {
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const user = useUser();
  const userActions = useUserActions();
  const [openLogout, setOpenLogoutDialog] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  const openLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const logout = async (accepted: boolean | undefined) => {
    setOpenLogoutDialog(false);
    if (accepted) {
      await userActions.logout();
    }
  };

  const drawer = (
    <div>
      <Grid style={{ margin: 0, width: "100%" }} container justifyContent="center" alignContent="center" spacing={2}>
        <Grid container item xs={11} direction="column" spacing={2}>
          <Grid item>
            <Avatar className={classes.photo}>
              <PersonIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h4" color="inherit">
              {user && user.firstName}
            </Typography>
            <Typography paragraph variant="h4" color="inherit">
              {user && user.lastName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider light />
      <StyledList>
        {menuItems.map((item: MenuItem) => (
          <StyledListItem
            key={item.key}
            onClick={() => navigate(item.path)}
            selected={window.location.pathname === item.root}
          >
            <StyledListItemIcon>{item.icon}</StyledListItemIcon>
            <StyledListItemText primary={item.key} />
          </StyledListItem>
        ))}
      </StyledList>
      <Divider light />
      <StyledList>
        <StyledListItem key="logout" onClick={openLogoutDialog}>
          <StyledListItemIcon>
            <ExitToAppTwoToneIcon />
          </StyledListItemIcon>

          {user.authorized && <StyledListItemText primary="Log af" />}
        </StyledListItem>
      </StyledList>
      <GeneralDialog
        open={openLogout}
        dialogTitle={"Ønsker du at logge af?"}
        dialogText={"Husk at gemme eventuelle ændringer inden."}
        handleClose={logout}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      {isMd && (
        <StyledAppBar color="secondary" position="fixed">
          <StyledToolbar>
            <Grid container justifyContent="center">
              {menuItems.map((item: MenuItem) => (
                <Grid
                  key={item.key}
                  item
                  container
                  xs={3}
                  sm={2}
                  md={1}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <MobileListItem
                    key={item.key}
                    onClick={() => navigate(item.path)}
                    selected={window.location.pathname === item.root}
                  >
                    <Grid item>{item.icon}</Grid>
                    <Grid item>
                      <Typography color="inherit" style={{ fontSize: 11 }}>
                        {item.mobileName}
                      </Typography>
                    </Grid>
                  </MobileListItem>
                </Grid>
              ))}
            </Grid>
          </StyledToolbar>
        </StyledAppBar>
      )}

      <nav className={classes.drawer}>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
const StyledList = withStyles({
  padding: {
    padding: "16px 8px",
  },
})(List);

const StyledListItem = withStyles((theme) => ({
  root: {
    padding: 8,
    borderRadius: 4,
    margin: "4px 0",
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.main,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.main,
      "&:hover, &:focus, &:active": {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.main,
      },
      "& > div > svg": {
        color: theme.palette.primary.main,
      },
    },
  },
}))(ListItemButton);

const MobileListItem = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    padding: 0,
    "&:hover, &:focus, &:active": {
      color: theme.palette.secondary.contrastText,
    },
    "&.Mui-selected": {
      color: theme.palette.secondary.contrastText,
      "&:hover, &:focus, &:active": {
        color: theme.palette.secondary.contrastText,
      },
      "& > div > svg": {
        color: theme.palette.primary.main,
      },
    },
  },
}))(ListItemButton);

const StyledListItemText = withStyles((theme) => ({
  primary: {
    fontSize: 13,
    color: "inherit",
    fontWeight: "normal",
  },
}))(ListItemText);

const StyledListItemIcon = withStyles({
  root: {
    minWidth: 40,
    color: "#8C8C9D",
  },
})(ListItemIcon);

const StyledAppBar = withStyles({
  root: {
    bottom: 0,
    top: "auto",
  },
})(AppBar);

const StyledToolbar = withStyles({
  root: {
    minHeight: 82,
  },
})(Toolbar);
