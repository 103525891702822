import {
  Grid,
  TextField,
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import React, { useEffect, useReducer } from "react";
import { CompanyListItem, CraftsmanType } from "../interfaces/models";
import ErrorBoundary from "./ErrorBoundary";
import { useUserActions } from "../pages/User/userActions";

type CompanyAutocompleteState = {
  filter: string;
  options: CompanyListItem[];
  selected: CompanyListItem | null;
  loading: boolean;
  changed: boolean;
};

const initialState: CompanyAutocompleteState = {
  filter: "",
  options: [],
  selected: null,
  loading: false,
  changed: false,
};

const reducer = (state: CompanyAutocompleteState, action: { type: keyof CompanyAutocompleteState; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const CompanyAutocomplete = (props: { selectedId: string; selectedAction: (selected: CompanyListItem) => void }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const userActions = useUserActions();

  useEffect(() => {
    const getAsync = async () => {
      if (props.selectedId) {
        try {
          const company = await userActions.getCompanyById(props.selectedId);
          if (company) {
            const val: CompanyListItem = {
              id: company.id,
              name: company.name,
              competencies: company.competencies,
              address: company.address,
              postCode: company.postCode,
              city: company.city,
              ownCompany: false,
              created: company.created,
              modified: company.modified,
            };
            dispatch({ type: "options", payload: [val] });
          }
        } catch (error: unknown) {
          console.error(error);
        }
      }
    };
    getAsync();
    return () => {};
  }, [props.selectedId]);

  useEffect(() => {
    if (!state.changed) {
      if (props.selectedId && state.options?.length > 0) {
        const val = state.options.find((x) => x.id === props.selectedId);
        if (val) {
          changeValue(undefined as unknown as React.ChangeEvent<unknown>, val, "selectOption");
        }
      }
    }
    return () => {};
  }, [props.selectedId, state.options]);

  useEffect(() => {
    return () => {
      dispatch({ type: "selected", payload: null });
      dispatch({ type: "options", payload: [] });
      dispatch({ type: "filter", payload: "" });
      dispatch({ type: "loading", payload: false });
      dispatch({ type: "changed", payload: false });
    };
  }, []);

  const changeFilter = (event: React.ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
    dispatch({ type: "changed", payload: true });
    dispatch({ type: "filter", payload: value });
    if (reason === "input") {
      dispatch({ type: "loading", payload: true });
      userActions
        .getCompanies(value, CraftsmanType.None, 0, 10, "name", false, false)
        .then((result) => {
          dispatch({ type: "options", payload: result.results });
        })
        .finally(() => dispatch({ type: "loading", payload: false }));
    }
    if (reason === "clear") {
      dispatch({ type: "selected", payload: null });
    }
  };

  const changeValue = (
    event: React.ChangeEvent<unknown>,
    value: CompanyListItem | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<CompanyListItem> | undefined
  ) => {
    switch (reason) {
      case "selectOption":
        dispatch({ type: "selected", payload: value });
        if (value) {
          props.selectedAction(value);
        }
        break;
      case "removeOption":
        dispatch({ type: "selected", payload: null });
        break;
    }
  };

  return (
    <ErrorBoundary errorMessage="Autocomplete error">
      <Grid item container>
        <Autocomplete
          fullWidth
          id="company-autocomplete"
          noOptionsText={"Ikke fundet"}
          loadingText={"Indlæser..."}
          loading={state.loading}
          value={state.selected}
          onInputChange={changeFilter}
          onChange={changeValue}
          getOptionLabel={(option) => `${option.name}, ${option.address}, ${option.postCode} ${option.city}` || ""}
          options={state.options}
          renderInput={(params) => (
            <TextField placeholder="Indtast firmaet navn" {...params} InputProps={{ ...params.InputProps }} />
          )}
        />
      </Grid>
    </ErrorBoundary>
  );
};

export default CompanyAutocomplete;
