import React, { useReducer, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PhoneField } from "../../../shared/FormattedFields";
import { FeatureMetadata } from "../../../interfaces/models";
import { EmptyGuid, EmailRegExp } from "../../../constants/appConstants";
import { useTaskState } from "../../../actions/tasklist/taskActions";
import DawaService from "../../../services/AddressService";
import { AddressOption } from "../../../interfaces/frontend";
import AddressAutocomplete from "../../../shared/AddressAutocomplete";



const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dividerPagging: {
    paddingTop: 20,
    paddingBottom: 30,
  },
  reportInput: {
    minWidth: 300,
    "& input": {
      color: "black",
    },
  },
  rootContainer: {
    marginTop: 20,
  },
  pictureWrapper: {
    maxWidth: "100%",
    overflow: "hidden",
    height: 200,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  marginTop: {
    marginTop: 60,
  },
  statusWrapper: {
    background: theme.palette.primary.main,
    margin: 0,
    borderRadius: 4,
  },
  infoCard: {
    width: "100%",
  },
  date: {
    background: "rgba(30, 183, 17, .1)",
    color: theme.palette.primary.main,
    padding: "4px 8px",
    borderRadius: 20,
  },
}));

const initialState: FeatureMetadata = {
  owner: "",
  ownerLastName: "",
  ownerPhone: "",
  ownerEmail: "",
  municipality: "",
  cadastral: "",
  caseNumber: 0,
  reportDate: new Date(),
  houseId: EmptyGuid,
  postcode: 0,
  latitude: 0,
  longitude: 0,
  area: 0,
  livingArea: 0,
  unitArea: 0,
  groundArea: 0,
  basementArea: 0,
  buildYear: 0,
  rooms: 0,
  address: "",
  city: "",
  propertyType: "",
  unitType: "",
  energyLabel: "",
  picture: ""
};

interface EditTaskInfoDialogProps {
  data?: FeatureMetadata;
  open: boolean;
  handleClose: (accepted?: boolean, customerInfo?: FeatureMetadata) => void;
}
const reducer = (
  state: typeof initialState,
  action: { type: keyof typeof initialState | "reset" | "houseData"; payload: any }
) => {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        ...action.payload,
      };
    case "houseData":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const EditTaskInfoDialog = (props: EditTaskInfoDialogProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...props.data });
  const taskState = useTaskState();

  const handleDateChange = (value: Date | null) => {
    dispatch({ type: "reportDate", payload: value });
  };

  const changeLocalValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({ type: name as keyof FeatureMetadata, payload: value });
  };

  const changeAutocompleteValue = async (value: AddressOption) => {
    if (value.value) {
      let newData = await DawaService.getHouseData(value.houseData);
      dispatch({ type: "houseData", payload: { ...value.houseData, ...newData } });
    }
  };

  const handleClose = (accepted?: boolean, customerInfo?: FeatureMetadata) => {
    if(customerInfo) {
      if(!customerInfo.caseNumber) {
        customerInfo.caseNumber =  Date.now();
      }
    }
    props.handleClose(accepted, customerInfo);
  };

  useEffect(() => {
    dispatch({ type: "reset", payload: props.data });
    return () => {};
  }, [props.data]);

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={() => props.handleClose()}>
      <DialogTitle>Information</DialogTitle>
      <DialogContent>
        {taskState.id === EmptyGuid ? (
          <Grid item container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <AddressAutocomplete selectedAction={changeAutocompleteValue} />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h2">
              {taskState.data &&
                taskState.data.address &&
                `${taskState.data.address}, ${taskState.data.postcode} ${taskState.data.city}`}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              fullWidth
              error={!state.owner}
              className={classes.reportInput}
              name="owner"
              label="Fornavn"
              value={state.owner || ""}
              onChange={changeLocalValue}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              fullWidth
              error={!state.ownerLastName}
              className={classes.reportInput}
              name="ownerLastName"
              label="Efternavn"
              value={state.ownerLastName || ""}
              onChange={changeLocalValue}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <PhoneField
              fullWidth
              error={!state.ownerPhone}
              className={classes.reportInput}
              name="ownerPhone"
              label="Telefon"
              value={state.ownerPhone || ""}
              onChange={changeLocalValue}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              fullWidth
              error={!EmailRegExp.test(state.ownerEmail)}
              className={classes.reportInput}
              name="ownerEmail"
              label="Email"
              value={state.ownerEmail || ""}
              onChange={changeLocalValue}
              type="email"
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField fullWidth className={classes.reportInput} label="Adresse" value={state.address || ""} disabled />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              fullWidth
              className={classes.reportInput}
              label="Postnr."
              value={state.postcode || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField fullWidth className={classes.reportInput} label="By" value={state.city || ""} disabled />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              fullWidth
              className={classes.reportInput}
              name="municipality"
              label="Kommunenr./Ejendomsnr."
              value={state.municipality || ""}
              onChange={changeLocalValue}
              disabled
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              fullWidth
              className={classes.reportInput}
              name="cadastral"
              label="Matrikel/Ejerlav"
              value={state.cadastral || ""}
              onChange={changeLocalValue}
              disabled
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            {!!state.caseNumber && state.caseNumber !== 0 && (
              <TextField
                fullWidth
                disabled
                className={classes.reportInput}
                name="caseNumber"
                label="Internt sagsnummer"
                value={state.caseNumber || ""}
              />
            )}
          </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Dato for besigtigelse"
                className={classes.reportInput}
                value={new Date(state.reportDate)}
                onChange={(value) => handleDateChange(value)}
              />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputContainer}></Grid>
          
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            onClick={() => handleClose(true, { ...state })}
            style={{ marginBottom: 20 }}
            variant="contained"
            color="primary"
          >
            GEM
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditTaskInfoDialog;
