import React, { FormEvent, useContext } from "react";
import { RegistrationContext, StepsComponentProps } from "../../RegistrationPage";
import { Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from "@mui/material";
import { StyledForm } from "../StyledComponents";
import NavigationButtons from "./NavigationButtons";
import { CraftsmanTypes } from "../../../../constants/appConstants";
import UserService from "../../../../services/UserService";
import { useForm } from "react-hook-form";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Summary = (props: StepsComponentProps) => {
  const context = useContext(RegistrationContext);
  const [loading, setLoading] = React.useState(false);
  const { handleSubmit } = useForm();

  const nextStep = async () => {
    setLoading(true);
    try {
      await UserService.registerCraftsman({
        email: context.email || "",
        organization: context.organization || "",
        cvr: context.cvr || "",
        phone: context.phone || "",
        mobile: context.mobile || "",
        address: context.address || "",
        postCode: context.postCode || 0,
        city: context.city || "",
        firstName: context.firstName || "",
        lastName: context.lastName || "",
        municipalCodes: context.municipalCodes || [],
        competencies: context.competencies || [],
      });


      context.firstName = "";
      context.lastName = "";
      context.email = "";
      context.organization = "";
      context.cvr = "";
      context.address = "";
      context.postCode = 0;
      context.city = "";
      context.phone = "";
      context.mobile = "";
      context.municipalCodes = [];
      context.competencies = [];
      context.municipalities = "";

      props.nextStep();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(nextStep)}>
      <Grid item xs={12} sx={{ flex: 1 }}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Virksomhed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Navn"}</TableCell>
                <TableCell align="right">{context.organization}</TableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"CVR"}</TableCell>
                <TableCell align="right">{context.cvr}</TableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Adresse"}</TableCell>
                <TableCell align="right">{context.address + ", " + context.postCode + " " + context.city}</TableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Telefon"}</TableCell>
                <TableCell align="right">{context.phone}</TableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Komunner"}</TableCell>
                <TableCell align="right">{context.municipalities}</TableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Kompetencer"}</TableCell>
                <TableCell align="right">
                  {context.competencies
                    ?.map((x) => CraftsmanTypes[x])
                    .sort((a, b) => {
                      if (a === CraftsmanTypes[100]) return 1;
                      return a > b ? 1 : -1;
                    })
                    .join(", ")}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bruger</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Navn"}</TableCell>
                <TableCell align="right">{context.firstName + " " + context.lastName}</TableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Email"}</TableCell>
                <TableCell align="right">{context.email}</TableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell scope="row">{"Mobil"}</TableCell>
                <TableCell align="right">{context.mobile}</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <NavigationButtons prevStep={props.prevStep} loading={loading} isLastStep={props.isLastStep} />
    </StyledForm>
  );
};

export default Summary;
