import * as React from 'react';
import SettingsIcon from "@mui/icons-material/Settings";
import BuildIcon from "@mui/icons-material/Build";
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';

export type MenuItem = {
  key: string;
  root: string;
  path: string;
  icon: React.ReactElement;
  mobileName?: string;
};


export const menuItems: MenuItem[] = [
  
    {
      key: "OPGAVER",     
      mobileName: "OPGAVER",
      path: "/tasklist",
      root: "/tasklist",
      icon: <BuildIcon color="inherit" />,
    },
    {
      key: "START NY OPGAVE",
      mobileName: "NY OPGAVE",
      path: "/tasklist/create/choose",
      root: "/tasklist/create",
      icon:  <AssignmentIcon color="inherit" />,
    },
    {
      key: "MINE FASTE KUNDER",
      mobileName: "KUNDER",
      path: "/customers",
      root: "/customers",
      icon: <PeopleIcon color="inherit" />,
    },
    {
      key: "STAMDATA",
      mobileName: "STAMDATA",
      path: "/profile",
      root: "/profile",
      icon: <SettingsIcon color="inherit" />,
    }
  ];