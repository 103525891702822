import { formatAddressString } from "../common/utils";
import { AddressOption, AutocompleteItem, MyhouseUnit } from "../interfaces/frontend";
import { FeatureMetadata } from "../interfaces/models";
import ApiService from "./ApiService";

const DawaService = {
  getHouseData: async (data: FeatureMetadata) => {
    let addressInfo = await getDawaAddress(data.address + ", " + data.postcode + " " + data.city);
    if (addressInfo) {
      data.latitude = addressInfo.adgangsadresse.adgangspunkt.koordinater[1];
      data.longitude = addressInfo.adgangsadresse.adgangspunkt.koordinater[0];
      data.municipality = addressInfo.adgangsadresse.kommune.kode + "/" + addressInfo.adgangsadresse.esrejendomsnr;
      data.cadastral = addressInfo.adgangsadresse.matrikelnr + "/" + addressInfo.adgangsadresse.ejerlav.navn;
      data.picture = `${process.env.REACT_APP_IMAGE_SERVICE_URL}/${data.houseId}/single/640/315`;
    }
    return data;
  },

  getDawaCityByPostCode: async (postCode: number) => {
    let response = await fetch(process.env.REACT_APP_DAWA_API + "/postnumre?nr=" + encodeURIComponent(postCode));
    if (response.status === 200) {
      let payload = await response.json();
      if (payload.length) {
        return payload[0].navn;
      }
    }
    return null;
  },

  getDawaMunicipality: async (value: string) => {
    let response = await fetch(process.env.REACT_APP_DAWA_API + "/kommuner/autocomplete?q=" + encodeURIComponent(value));
    if (response.status === 200) {
      let payload = await response.json();
      if (payload.length) {
        return payload;
      }
    }
    return null;
  },

  getDawaMunicipalities: async (values: string | any[]) => {
    let results: any[]= [];
    for (let i = 0; i < values.length; i++) {
      let val = await DawaService.getDawaMunicipality(values[i]);
      if (val && val.length) {
        results.push(val[0]);
      }
    }
    return results;
  },
  
  getAutocompleteValues: async (
    inputValue: string,
    cursorPosition: number,
    signal: AbortSignal | null
  ): Promise<AddressOption[]> => {
    try {
      if (!inputValue || inputValue.length < 3) return [];
      const options = await ApiService.abortableGet<AutocompleteItem[]>(
        `${
          process.env.REACT_APP_MYHOUSE_API_URL
        }/api/autocomplete?text=${inputValue}&cursorPosition=${cursorPosition}&startFromBuildings=${false}`,
        signal
        //`/api/autocomplete?text=${inputValue}&cursorPosition=${inputValue.length}`
      );

      if (options && options.length) {
        return options.map((x) => {
          const option: AddressOption = {
            value: x.UnitId,
            label: x.Address.Street
              ? formatAddressString(
                  {
                    street: x.Address.Street,
                    houseNo: x.Address.HouseNo,
                    floor: x.Address.Floor,
                    doorNumber: x.Address.DoorNumber,
                    postalCode: x.Address.PostalCode,
                    city: x.Address.City,
                  },
                  false
                )
              : x.Text,
            cursorPosition: x.CursorPosition,
            houseData: {
              houseId: x.UnitId || "",
              postcode: x.Address.PostalCode,
              city: x.Address.City,
              area: 0,
              livingArea: 0,
              basementArea: 0,
              buildYear: 0,
              rooms: 0,
              groundArea: 0,
              unitArea: 0,
              address: formatAddressString(
                {
                  street: x.Address.Street,
                  houseNo: x.Address.HouseNo,
                  floor: x.Address.Floor,
                  doorNumber: x.Address.DoorNumber,
                  postalCode: x.Address.PostalCode,
                  city: x.Address.City,
                },
                true
              ),
              reportDate: new Date(),
              latitude: x.Address.Latitude,
              longitude: x.Address.Longtitude,
              caseNumber: 0,
              owner: "",
              ownerLastName: "",
              ownerEmail: "",
              ownerPhone: "",
              cadastral: "",
              energyLabel: "",
              municipality: "",
              picture: "",
              propertyType: "",
              unitType: "",
            },
          };
          return option;
        });
      }
    } catch (error: unknown) {
      console.log(error);
    }
    return [];
  },

  getHouseInfo: async (houseId: string) => {
    return await ApiService.get<MyhouseUnit>(`${process.env.REACT_APP_MYHOUSE_API_URL}/api/house/${houseId}`);
  },
};

export default DawaService;

const getDawaAddress = async (addressString: string) => {
  let response = await fetch(process.env.REACT_APP_DAWA_API + "/adresser?q=" + encodeURIComponent(addressString));
  if (response.status === 200) {
    let payload = await response.json();
    if (payload.length) {
      return payload[0];
    }
  }
  return null;
};
