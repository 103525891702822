import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { Common, User } from "../constants/actionTypes";
import { AppError } from "../interfaces/frontend";
import { AddressGroupedListItem, ReportListItem, TemplateModel } from "../interfaces/models";
import { AppState } from "../store/store";

export interface CommonState {
  pdfView: boolean;
  templateList: TemplateModel[];
  reportList: ReportListItem[];
  groupedReportList: AddressGroupedListItem<ReportListItem>[];
  reportCount: number;
  error?: AppError;
  showError: boolean;
  loading: boolean;
  hubConnection: signalR.HubConnection | undefined;
}

const initialState: CommonState = {
  pdfView: false,
  templateList: [],
  reportList: [],
  groupedReportList: [],
  reportCount: 0,
  error: undefined,
  showError: false,
  loading: false,
  hubConnection: undefined
};

const commonReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case Common.SWITCH_PDF_MODE:
      return { ...state, pdfView: !state.pdfView };
    case Common.GET_LIST:
      return { ...state, loading: true };
    case Common.GET_TEMPLATE_LIST_SUCCEEDED:
      return {
        ...state,
        templateList: !!action.payload ? [...action.payload] : [],
        loading: false,
      };
    case Common.GET_REPORT_LIST_SUCCEEDED:
      return {
        ...state,
        reportList: !!action.payload
          ? [
              ...(action.payload.results as ReportListItem[]).filter((x) => !x.name),
              ...(action.payload.results as ReportListItem[]).filter((x) => x.name),
            ]
          : ([] as ReportListItem[]),
        reportCount: action.payload.count,
        loading: false,
      };
    case Common.GET_GROUPED_REPORT_LIST_SUCCEEDED:
      return {
        ...state,
        groupedReportList: !!action.payload
          ? ([
              ...action.payload.results.filter((x: AddressGroupedListItem<ReportListItem>) => !x.name),
              ...action.payload.results.filter((x: AddressGroupedListItem<ReportListItem>) => x.name),
            ] as AddressGroupedListItem<ReportListItem>[])
          : ([] as AddressGroupedListItem<ReportListItem>[]),
        loading: false,
      };
    case Common.APPLY_FILTER_SUCCEEDED:
      return {
        ...state,
        reportList: [
          ...(action.payload.results as ReportListItem[]).filter((x) => !x.name),
          ...(action.payload.results as ReportListItem[]).filter((x) => x.name),
        ],
        reportCount: action.payload.count as number,
        loading: false,
      };
    case Common.GOT_ERROR:
      return {
        ...state,
        error: action.payload,
        showError: true,
        loading: false,
      };
    case Common.CLEAR_ERROR:
      return { ...state, error: null, showError: false };
    case User.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default commonReducer;

export const useCommonState = () => useSelector((state: AppState) => state.common);
