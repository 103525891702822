import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  useMediaQuery,
  useTheme,
  Theme,
  Grid,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import DataTable from "../../../shared/DataTable";
import { CraftsmanType } from "../../../interfaces/models";
import { CraftsmanTypes } from "../../../constants/appConstants";
import { useUserActions } from "../userActions";
import { useUser } from "../../../common/hooks";
import CreateCompanyDialog from "../../../shared/CreateCompanyDialog";

const useStyles = makeStyles((theme) => ({
  bottomButton: {
    minWidth: 90,
  },
}));

const AddCompanyDialog = (props: { handleClose: (value?: string) => void; open: boolean; type?: CraftsmanType }) => {
  const [selected, setSelected] = useState("");
  const [openCreateCompany, setOpenCreateCompany] = useState(false);
  const userActions = useUserActions();
  const user = useUser();

  const handleClose = (confirm?: boolean) => {
    if (confirm) {
      props.handleClose(selected);
      return;
    }
    props.handleClose();
  };

  const handleCloseCreate = (created?: any) => {
    setOpenCreateCompany(false);
  };

  const getCompanies = (
    query: string,
    filter: CraftsmanType,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => {
    return userActions.getCompanies(query, filter, skip, take, sortField, sortDescending, true);
  };
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Dialog fullScreen={fullScreen} maxWidth="lg" open={props.open} onClose={() => props.handleClose()}>
        <DialogTitle>{!!props.type ? "Tilføj " + CraftsmanTypes[props.type] : "Tilføj firma"}</DialogTitle>
        <StyledDialogContent>
          <DataTable
            data={user.companies}
            getData={getCompanies}
            columns={[
              {
                label: "Firma",
                key: "name",
              },
              {
                label: "Adresse",
                key: "address",
              },
              {
                label: "",
                key: "postCode",
              },
              {
                label: "",
                key: "city",
              },
            ]}
            textFilter
            dropdownFilterValues={[
              {
                id: 0,
                value: "Alle",
              },
            ].concat(
              Object.keys(CraftsmanTypes).map((x) => ({
                id: parseInt(x),
                value: CraftsmanTypes[parseInt(x) as CraftsmanType],
              }))
            )}
            dropdownFilterTitle="Kompetencer"
            count={user.companiesCount}
            itemsPerPage={10}
            onRowClick={(rowId) => setSelected(rowId)}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                className={classes.bottomButton}
                variant="contained"
                onClick={() => setOpenCreateCompany(true)}
                color="primary"
              >
                {"Opret"}
              </Button>
            </Grid>
            <Grid item container xs={7} justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  className={classes.bottomButton}
                  variant="outlined"
                  onClick={() => handleClose()}
                  color="primary"
                >
                  {"Annuller"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.bottomButton}
                  variant="contained"
                  onClick={() => handleClose(true)}
                  color="primary"
                >
                  {"Ok"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </StyledDialogActions>
      </Dialog>
      <CreateCompanyDialog open={openCreateCompany} handleClose={handleCloseCreate} />
    </>
  );
};

const StyledDialogContent = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("lg")]: {
      padding: 0,
    },
  },
}))(DialogContent);

const StyledDialogActions = withStyles({
  root: {
    justifyContent: "center",
  },
})(DialogActions);

export default AddCompanyDialog;
