export const Common = {
  SWITCH_PDF_MODE: "SWITCH_PDF_MODE",
  GET_HOUSE_DATA: "GET_HOUSE_DATA",
  GET_HOUSE_DATA_SUCCEEDED: "GET_HOUSE_DATA_SUCCEEDED",
  GET_LIST: "GET_LIST",
  GET_TEMPLATE_LIST_SUCCEEDED: "GET_TEMPLATE_LIST_SUCCEEDED",
  GET_TEMPLATE_LIST_FAILED: "GET_TEMPLATE_LIST_FAILED",
  GET_REPORT_LIST_SUCCEEDED: "GET_REPORT_LIST_SUCCEEDED",
  GET_OFFER_LIST_SUCCEEDED: "GET_OFFER_LIST_SUCCEEDED",
  GET_GROUPED_REPORT_LIST_SUCCEEDED: "GET_GROUPED_REPORT_LIST_SUCCEEDED",
  GET_GROUPED_REPORT_LIST_FAILED: "GET_GROUPED_REPORT_LIST_FAILED",
  APPLY_FILTER: "APPLY_FILTER",
  APPLY_FILTER_SUCCEEDED: "APPLY_FILTER_SUCCEEDED",
  APPLY_FILTER_FAILED: "APPLY_FILTER_FAILED",
  UPDATE_REPORT_LIST: "UPDATE_REPORT_LIST",
  GOT_ERROR: "GOT_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  START_SIGNALR: "START_SIGNALR",
  START_SIGNALR_SUCCEEDED: "START_SIGNALR_SUCCEEDED",
  STOP_SIGNALR: "STOP_SIGNALR",
  STOP_SIGNALR_SUCCEEDED: "STOP_SIGNALR_SUCCEEDED",
};

export const User = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCEEDED: "LOGIN_SUCCEEDED",
  LOGIN_FAILED: "LOGIN_FAILED",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
  AUTH_LOCAL: "AUTH_LOCAL",
  AUTH_LOCAL_NOT_FOUND: "AUTH_LOCAL_NOT_FOUND",
  LOGOUT: "LOGOUT",
  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCEEDED: "GET_PROFILE_SUCCEEDED",
  GET_PROFILE_FAILED: "GET_PROFILE_FAILED",
  CHECK_PROFILE: "CHECK_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCEEDED: "UPDATE_PROFILE_SUCCEEDED",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCEEDED: "CHANGE_PASSWORD_SUCCEEDED",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  GET_RATING: "GET_RATING",
  GET_RATING_SUCCEEDED: "GET_RATING_SUCCEEDED",
  GET_RATING_VALUES: "GET_RATING_VALUES",
  GET_RATING_VALUES_SUCCEEDED: "GET_RATING_VALUES_SUCCEEDED",
  LINK_REQUEST_TO_HOUSE: "LINK_REQUEST_TO_HOUSE",
  LINK_REQUEST_TO_HOUSE_SUCCEEDED: "LINK_REQUEST_TO_HOUSE_SUCCEEDED",
  GET_LINK_REQUESTS: "GET_LINK_REQUESTS",
  GET_LINK_REQUESTS_SUCCEEDED: "GET_LINK_REQUESTS_SUCCEEDED",
  GET_TEAM: "GET_TEAM",
  GET_TEAM_SUCCEEDED: "GET_TEAM_SUCCEEDED",
  MANAGE_TEAM: "MANAGE_TEAM",
  MANAGE_TEAM_SUCCEEDED: "MANAGE_TEAM_SUCCEEDED",
  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIES_SUCCEEDED: "GET_COMPANIES_SUCCEEDED",
  NOTIFICATION_RECEIVED: "NOTIFICATION_RECEIVED",
};

export const Task = {
  GET_TASK_LISTS: "GET_TASK_LISTS",
  GET_TASK_LISTS_SUCCEEDED: "GET_TASK_LISTS_SUCCEEDED",
  GET_TASK_LIST: "GET_TASK_LIST",
  GET_TASK_LIST_SUCCEEDED: "GET_TASK_LIST_SUCCEEDED",
  ADD_TASK: "ADD_TASK",
  REMOVE_TASK: "REMOVE_TASK",
  SAVE_TASK_LIST: "SAVE_TASK_LIST",
  SAVE_TASK_LIST_SUCCEEDED: "SAVE_TASK_LIST_SUCCEEDED",
  TASK_OPERATION_FAILED: "TASK_OPERATION_FAILED",
  ADD_PICTURE: "ADD_PICTURE",
  REMOVE_PICTURE: "REMOVE_PICTURE",
  CLEAR_HOUSE_DATA: "CLEAR_HOUSE_DATA",
  SET_HOUSE_DATA: "SET_HOUSE_DATA",
  CHANGE_CUSTOMER_INFO: "CHANGE_CUSTOMER_INFO",
  CHANGE_VALUE: "CHANGE_VALUE",
  CLEAR_TASK_LIST: "CLEAR_TASK_LIST",
  CHANGE_TASK_LIST_STATUS: "CHANGE_TASK_LIST_STATUS",
  CHANGE_TASK_LIST_STATUS_SUCCEEDED: "CHANGE_TASK_LIST_STATUS_SUSSEEDED",
  CHANGE_TASK_STATUS: "CHANGE_TASK_STATUS",
  CHANGE_TASK_STATUS_SUCCEEDED: "CHANGE_TASK_STATUS_SUSSEEDED",
  SHARE_TASK_LIST: "SHARE_TASK_LIST",
  SHARE_TASK_LIST_SUCCEEDED: "SHARE_TASK_LIST_SUCCEEDED",
  GET_TASK_STATS: "GET_TASK_STATS",
  GET_TASK_STATS_SUCCEEDED: "GET_TASK_STATS_SUCCEEDED",
  CALCULATE_TOTAL: "CALCULATE_TOTAL",
  SET_DISCOUNT: "SET_DISCOUNT",
  LOCAL_DATA_FOUND: "LOCAL_DATA_FOUND",
  LOAD_LOCAL_DATA: "LOAD_LOCAL_DATA",
  UDPATE_LOCAL_DATA: "UDPATE_LOCAL_DATA",
  UPDATE_CUSTOMER_INFO: "UPDATE_CUSTOMER_INFO",
  UPDATE_TASK_INFO: "UPDATE_TASK_INFO",
  UPDATE_TASKLIST_NOTE: "UPDATE_TASKLIST_NOTE",
  SAVE_SEARCH_PARAMS: "SAVE_SEARCH_PARAMS",
  GET_SOLUTIONS: "GET_SOLUTIONS",
  GET_SOLUTIONS_SUCCEEDED: "GET_SOLUTIONS_SUCCEEDED",
};

export const Admin = {
  //user
  GET_USER_LIST_SUCCEEDED: "GET_USER_LIST_SUCCEEDED",
  UPLOAD_USER: "UPLOAD_USER",
  UPLOAD_USER_SUCCEEDED: "UPLOAD_USER_SUCCEEDED",
  UPLOAD_USER_FAILED: "UPLOAD_USER_FAILED",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCEEDED: "DELETE_USER_SUCCEEDED",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCEEDED: "UPDATE_USER_SUCCEEDED",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  LOCK_USER: "LOCK_USER",
  LOCK_USER_SUCCEEDED: "LOCK_USER_SUCCEEDED",
  LOCK_USER_FAILED: "LOCK_USER_FAILED",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCEEDED: "RESET_PASSWORD_SUCCEEDED",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
};

export type AppAction = {
  type:
    | keyof typeof Common
    | keyof typeof User
    | keyof typeof Task
  payload: any;
};
