import React, { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { EmptyGuid } from "../../constants/appConstants";
import TaskListLayout from "./components/TaskListLayout";
import GeneralDialog from "../../shared/GeneralDialog";
import { useTaskActions, useTaskState } from "../../actions/tasklist/taskActions";
import AppToolbarV2 from "../../shared/AppToolbar";

const initialState = {
  openConfirmSaveDialog: false,
  showNotification: false,
  notificationMessage: "",
};

const reducer = (
  state: typeof initialState,
  action: { type: keyof typeof initialState; payload: boolean | string }
) => {
  return { ...state, [action.type]: action.payload };
};

export const TaskListPage = () => {
  const taskActions = useTaskActions();
  const taskState = useTaskState();
  const navigate = useNavigate();
  const { taskListId } = useParams();

  useEffect(() => {
    if (taskListId && taskListId !== EmptyGuid) {
      taskActions.getTaskList(taskListId);
    }

    return () => {};
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const goBack = () => {
    if (taskState.changed) {
      dispatch({ type: "openConfirmSaveDialog", payload: true });
      return;
    }
    navigate("/tasklist/");
  };

  const handleCloseConfirmSaveDialog = async (confirmed?: boolean) => {
    dispatch({ type: "openConfirmSaveDialog", payload: false });
    if (confirmed) {
      try {
        await taskActions.saveTaskList(false);
      } catch (error: any) {
        dispatch({
          type: "notificationMessage",
          payload: error,
        });
        dispatch({
          type: "showNotification",
          payload: true,
        });
      }
    }
    navigate("/tasklist/");
  };

  return (
    <>
      <AppToolbarV2 goBack={goBack} title={taskState.data?.address && ""} />
      <Grid container>
        {taskState.id !== EmptyGuid ? (
          <TaskListLayout />
        ) : (
          <Grid item container justifyContent="center" alignItems="center">
            <Typography variant="h5" noWrap>
              Opgavelisten er ikke fundet
            </Typography>
          </Grid>
        )}
      </Grid>
      <GeneralDialog
        open={state.openConfirmSaveDialog}
        dialogTitle={"Du har ændret opgavelisten. Ønsker du at gemme det?"}
        dialogText={""}
        handleClose={handleCloseConfirmSaveDialog}
        cancelButtonText={"Nej"}
        okButtonText={"Ja"}
      />
    </>
  );
};
