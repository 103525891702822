import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CompanyListItem, LoginCommand, RegisterUserCommand, UserRole } from "../../../interfaces/models";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import CreateCompanyDialog from "../../../shared/CreateCompanyDialog";
import CompanyAutocomplete from "../../../shared/CompanyAutocomplete";
import { FormattedTextField, PhoneField } from "../../../shared/FormattedFields";
import { userInfo } from "os";

type UserState = LoginCommand & RegisterUserCommand & { confirmedPassword?: string };

const initialState: UserState = {
  userName: "",
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  confirmedPassword: "",
  role: 0,
  companyId: "",
  phone: "",
  lockoutEnabled: false,
};

const UserDialog = (props: {
  user: UserState;
  setOpenDialog: (arg0: boolean) => void;
  clearData: () => void;
  uploadUserHandler: (arg0: any) => void;
  updateUserHandler: (arg0: UserState) => void;
  open: boolean;
  isEditMode: any;
}) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({ email: false, password: false, role: false, company: false, phone: false });

  const [showCreateCompanyDialog, setShowCreateCompanyDialog] = useState(false);

  useEffect(() => {
    if (props.user) {
      setData(props.user);
    }
  }, [props.user]);

  const changeDataValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setData({ ...data, [event.target.id]: event.target.value });

  const changeRole = (event: SelectChangeEvent<UserRole>, child: React.ReactNode) => {
    setData({ ...data, role: event.target.value as UserRole });
  };

  const handleClose = () => {
    props.setOpenDialog(false);
    setData(initialState);
    props.clearData();
  };

  const uploadUser = (user: UserState) => {
    checkValidation(user)
      .then((user) => {
        const request = { ...user };
        delete request.confirmedPassword;
        request.userName = request.email;
        request.role = UserRole.Craftsman;
        props.uploadUserHandler(request);
        props.setOpenDialog(false);
        setData(initialState);
      })
      .catch();
  };

  const updateUser = (user: UserState) => {
    props.updateUserHandler(user);
    props.setOpenDialog(false);
    setData(initialState);
    props.clearData();
  };

  const checkValidation = (user: UserState) =>
    new Promise<UserState>((resolve, reject) => {
      const re = /\S+@\S+\.\S+/;
      const errorState = {
        ...errors,
        password: user.password !== user.confirmedPassword,
        email: !re.test(user.email || ""),
        role: user.role === UserRole.None,
        company: !user.companyId,
        phone: !data.phone,
      };
      setErrors(errorState);
      if (Object.values(errorState).indexOf(true) === -1) {
        resolve(user);
      } else {
        reject();
      }
    });

  const addToCompany = (company: CompanyListItem) => {
    setData({ ...data, companyId: company.id });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.isEditMode ? "Edit" : `Create new user`}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{"User " + data.email}</Typography>
          <TextField
            required
            error={errors.email}
            autoComplete="off"
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={data.email}
            onChange={changeDataValue}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            id="firstName"
            label="First name"
            type="text"
            fullWidth
            value={data.firstName}
            onChange={changeDataValue}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            id="lastName"
            label="Last name"
            type="text"
            fullWidth
            value={data.lastName}
            onChange={changeDataValue}
          />
          <FormattedTextField
            format="+45 ## ## ## ##"
            fullWidth
            autoComplete="off"
            variant="outlined"
            margin="dense"
            label="Mobil"
            type="tel"
            value={data.phone}
            onChange={changeDataValue}
          />
          {!props.isEditMode ? (
            <>
              <TextField
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                value={data.password}
                onChange={changeDataValue}
              />
              <TextField
                error={errors.password}
                helperText={errors.password ? "Passwords do not match" : null}
                margin="dense"
                id="confirmedPassword"
                label="Confirm Password"
                type="password"
                fullWidth
                value={data.confirmedPassword}
                onChange={changeDataValue}
              />
            </>
          ) : null}
          {/* <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select value={data.role ?? UserRole.None} onChange={changeRole} error={errors.role} label="Role">
                  <MenuItem value={UserRole.Administrator}>Admin</MenuItem>
                  <MenuItem value={UserRole.Craftsman}>Craftsman</MenuItem>
                </Select>
              </FormControl> */}
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CompanyAutocomplete selectedId={data.companyId} selectedAction={addToCompany} />
              </Grid>

              <Grid item xs={4}>
                <Button variant="contained" onClick={() => setShowCreateCompanyDialog(true)}>
                  Create a new company
                </Button>
              </Grid>
              {errors.company && (
                <Grid item xs={12}>
                  <Typography style={{ color: "red" }}>Register user with a company</Typography>
                </Grid>
              )}
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuller
          </Button>
          {props.isEditMode ? (
            <Button onClick={() => updateUser(data)} color="primary">
              Opdater
            </Button>
          ) : (
            <Button onClick={() => uploadUser(data)} color="primary">
              Opret
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <CreateCompanyDialog open={showCreateCompanyDialog} handleClose={() => setShowCreateCompanyDialog(false)} />
    </div>
  );
};

export default UserDialog;
