import React  from "react";
import { Chip } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { CraftsmanTaskStatus } from "../../../interfaces/models";
import { getCraftsmanStatusText } from "../../../constants/appConstants";

interface TaskStatusProps {
  data: CraftsmanTaskStatus;
}

const CraftsmenTaskStatus = (props: TaskStatusProps) => {
  return (
    <StyledChip
      label={"Status: " + getCraftsmanStatusText(props.data)}
      variant="outlined"
      color={getCraftsmanStatusColor(props.data)}
    />
  );
};

export default CraftsmenTaskStatus;

const getCraftsmanStatusColor = (status: CraftsmanTaskStatus) => {
  switch (status) {
    case CraftsmanTaskStatus.New:
      return "default";
    case CraftsmanTaskStatus.InProgress:
      return "primary";
    case CraftsmanTaskStatus.Done:
      return "secondary";
    case CraftsmanTaskStatus.Cancelled:
      return "default";
    case CraftsmanTaskStatus.Shared:
      return "primary";
    default:
      return "default";
  }
};

const StyledChip = withStyles((theme) => ({
  root: {
    height: "auto",
  },
  label: {
    padding: "4px 8px",
    fontSize: 12,
  },
  colorPrimary: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
  },
  colorSecondary: {
    color: "lightgreen",
    borderColor: "lightgreen",
  },
}))(Chip);
