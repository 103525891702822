import { Admin, Common } from "../constants/actionTypes";
import { handleError } from "../common/commonActions";
import UserService from "../services/UserService";
import {
  AdminResetPasswordCommand,
  CreateSolutionCommand,
  CreateVendorCommand,
  UpdateSolutionCommand,
  UpdateUserCommand,
  UpdateVendorCommand,
  User,
  UserRole,
} from "../interfaces/models";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/store";
import { AppError } from "../interfaces/frontend";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../common/hooks";
import ApiService from "../services/ApiService";

const getUserList =
  (
    userRole: UserRole,
    filter: string | undefined,
    skip: number | undefined,
    take: number | undefined,
    sortField: string,
    sortDescending: boolean
  ) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Common.GET_LIST });
    try {
      const result = await UserService.getUserList(userRole, filter, skip, take, sortField, sortDescending);
      dispatch({ type: Admin.GET_USER_LIST_SUCCEEDED, payload: result });
      return result;
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch);
    }
  };

const uploadUser = (user: User) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Admin.UPLOAD_USER });
  try {
    const response = await UserService.uploadUser(user);
    dispatch({ type: Admin.UPLOAD_USER_SUCCEEDED, payload: response });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, { type: Admin.UPLOAD_USER_FAILED, payload: error, error: true });
  }
};

const deleteUser = (userId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Admin.DELETE_USER });
  try {
    await UserService.deleteUser(userId);
    dispatch({ type: Admin.DELETE_USER_SUCCEEDED, payload: userId });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const updateUser = (user: UpdateUserCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Admin.UPDATE_USER });
  try {
    await UserService.updateUser(user);
    dispatch({ type: Admin.UPDATE_USER_SUCCEEDED, payload: user });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, { type: Admin.UPDATE_USER_FAILED, payload: error, error: true });
  }
};

const lockUser = (userId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Admin.LOCK_USER });
  try {
    await UserService.lockUser(userId);
    dispatch({ type: Admin.LOCK_USER_SUCCEEDED, payload: userId });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, { type: Admin.LOCK_USER_FAILED, payload: error, error: true });
  }
};

const resetPassword =
  (user: AdminResetPasswordCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Admin.RESET_PASSWORD });
    try {
      await UserService.resetPassword(user);
      dispatch({ type: Admin.RESET_PASSWORD_SUCCEEDED });
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Admin.RESET_PASSWORD_FAILED,
        payload: error,
        error: true,
      });
    }
  };


const useAdminState = () => useSelector((state: AppState) => state.admin);

const useAdminActions = () => {
  const dispatch = useAppDispatch();

  return {
    getUserList: (
      userRole: UserRole,
      filter: any,
      skip: number,
      take: number,
      sortField: string,
      sortDescending: boolean
    ) => dispatch(getUserList(userRole, filter, skip, take, sortField, sortDescending)),
    uploadUser: (user: User) => dispatch(uploadUser(user)),
    deleteUser: (userId: string) => dispatch(deleteUser(userId)),
    updateUser: (user: UpdateUserCommand) => dispatch(updateUser(user)),
    lockUser: (userId: string) => dispatch(lockUser(userId)),
    resetPassword: (user: AdminResetPasswordCommand) => dispatch(resetPassword(user))

  };
};

export const useAdmin = (): [
  ReturnType<typeof useAdminState>,
  ReturnType<typeof useAdminActions>
] => {
  const state = useAdminState();
  const actions = useAdminActions();
  return [state, actions];
};
