import { AnyAction } from "redux";
import { Admin, User } from "../constants/actionTypes";

export interface AdminState {
  data: any[];
  count: number;
  loading: boolean;
}

const initialState: AdminState = {
  data: [],
  count: 0,
  loading: false,
};

const adminReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case Admin.GET_USER_LIST_SUCCEEDED:
      return {
        ...state,
        data: action.payload ? [...action.payload.results] : [],
        count: action.payload.count,
      };
    case Admin.UPLOAD_USER:
      return { ...state, loading: true };
    case Admin.UPLOAD_USER_SUCCEEDED:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
      };
    case Admin.UPLOAD_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case Admin.DELETE_USER:
      return { ...state, loading: true };
    case Admin.DELETE_USER_SUCCEEDED:
      return { ...state, data: state.data.filter((user) => user.id !== action.payload), loading: false };
    case Admin.UPDATE_USER:
      return { ...state, loading: true };
    case Admin.UPDATE_USER_SUCCEEDED:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.id) item = action.payload;
          return item;
        }),
        loading: false,
      };
    case Admin.UPDATE_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case Admin.LOCK_USER:
      return { ...state, loading: true };
    case Admin.LOCK_USER_SUCCEEDED:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload) item.locked = !item.locked;
          return item;
        }),
        loading: false,
      };
    case Admin.LOCK_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case Admin.RESET_PASSWORD:
      return { ...state, loading: true };
    case Admin.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        loading: false,
      };
    case Admin.RESET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };
    case User.LOGOUT:
    case User.CLEAR_USER_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default adminReducer;
