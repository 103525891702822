import React from "react";
import { Button, Grid, useTheme } from "@mui/material";

const PortalLoginButtons = () => {
  const theme = useTheme();
  return (
    <>
      <Grid
        item
        container
        justifyContent="flex-end"
        spacing={3}
        style={{ margin: 0, width: "100%", flexWrap: "nowrap" }}
      >
        <Button
          sx={{
            height: 45,
            borderRadius: 0,
            padding: "0 20px",
            boxSizing: "border-box",
            backgroundColor: theme.palette.secondary.main,
            flexWrap: "nowrap",
            fontSize: 10,
            fontFamily: "'Montserrat', sans-serif",
            "@media (min-width: 600px)": {
              width: 168,
              fontSize: 12,
            },

            background: "#f7f7f6",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: "#f7f7f6",
            },
          }}
          href={process.env.REACT_APP_MYHOUSE_URL as string}
          target="_blank"
        >
          Boligejer login
        </Button>
      </Grid>
    </>
  );
};

export default PortalLoginButtons;
