import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Theme,
  useMediaQuery,
  useTheme,
  Autocomplete,
  AutocompleteInputChangeReason,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useReducer } from "react";
import { CraftsmanTypes } from "../constants/appConstants";
import { Company, CreateCompanyCommand, UpdateCompanyCommand } from "../interfaces/models";
import DawaService from "../services/AddressService";
import { NIL, v4 as uuid } from "uuid";
import { useUserActions } from "../pages/User/userActions";
import { Controller, useForm } from "react-hook-form";
import { FormattedTextField } from "./FormattedFields";
import { ReadOnlyTextField } from "../pages/User/components/StyledComponents";

type CreateCompanyDialogProps = {
  open: boolean;
  handleClose: (created?: any) => void;
  company?: Company;
};

interface CompanyFormData extends Omit<CreateCompanyCommand, "competencies" | "municipalCodes"> {}

interface CreateCompanyState extends Pick<CreateCompanyCommand, "competencies" | "municipalCodes" | "isCustom"> {
  municipalityOptions: [];
  selectedMunicipalities: [];
  open: boolean;
  loading: boolean;
}

const reducer = (state: CreateCompanyState, action: { type: keyof CreateCompanyState | "init"; payload: any }) => {
  if (action.type === "init") {
    return { ...initialState };
  }
  return { ...state, [action.type]: action.payload };
};

const initialData: CompanyFormData = {
  companyId: NIL,
  postCode: 0,
  travelRange: 0,
  pricePerHour: 0,
  address: "",
  city: "",
  cvr: "",
  email: "",
  employees: [],
  name: "",
  phone: "",
  webSite: "",
  isCustom: false,
  creatorId: "",
};

const initialState: CreateCompanyState = {
  municipalCodes: [],
  municipalityOptions: [],
  selectedMunicipalities: [],
  open: false,
  loading: false,
  isCustom: false,
  competencies: [],
};

const CreateCompanyDialog = (props: CreateCompanyDialogProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<CompanyFormData>({ defaultValues: initialData });

  const actions = useUserActions();

  const autoCompleteChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (value) {
      let values = await DawaService.getDawaMunicipality(value);
      if (values) {
        dispatch({ type: "municipalityOptions", payload: values });
      }
    }
  };

  const changeMunicipalities = (event: any, value: any) => {
    if (value) {
      let items: number = value.map((x: { kommune: { kode: string } }) => parseInt(x.kommune.kode));
      dispatch({ type: "selectedMunicipalities", payload: value });
      dispatch({ type: "municipalCodes", payload: items });
    }
  };

  const createCompany = async (data: CompanyFormData) => {
    if (state.competencies.length === 0) return;
    if (data.companyId !== NIL) {
      const cmd: UpdateCompanyCommand = { ...state, ...data };
      try {
        await actions.updateCompany(cmd);
        props.handleClose(cmd);
      } catch (err) {
        console.log(err);
      }
    } else {
      const cmd: CreateCompanyCommand = { ...state, ...data, companyId: uuid() };
      try {
        await actions.createCompany(cmd);
        props.handleClose(cmd);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const changeCompetency = (event: any) => {
    dispatch({
      type: "competencies",
      payload: event.target.checked
        ? [...state.competencies!, parseInt(event.target.id)]
        : state.competencies!.filter((x) => x !== parseInt(event.target.id)),
    });
  };

  useEffect(() => {

    const getAsync = async () => {
    if (props.open && props.company) {
      reset({
        companyId: props.company.id,
        postCode: props.company.postCode,
        address: props.company.address,
        cvr: props.company.cvr,
        email: props.company.email,
        employees: props.company.employees,
        name: props.company.name,
        phone: props.company.phone,
        webSite: props.company.webSite,
        isCustom: props.company.isCustom,
      });

      try {
        const selectedMunicipalitiesPromises = props.company.municipalCodes.map(async (x) => {
          let values = await DawaService.getDawaMunicipality(x + "");
          if(values.length === 0) return { kommune: { kode: x.toString(), navn: "Ukendt" } };
          return { kommune: { kode: x.toString(), navn: values[0].kommune.navn } };
        });
        
        const selectedMunicipalities = await Promise.all(selectedMunicipalitiesPromises);

        dispatch({ type: "competencies", payload: props.company.competencies });
        dispatch({ type: "selectedMunicipalities", payload: selectedMunicipalities });
        dispatch({ type: "municipalCodes", payload: props.company.municipalCodes });
      } catch (err) {
        console.log(err);
      }
    }
  }
  getAsync();

    return () => {
      dispatch({ type: "init", payload: undefined });
      reset({ ...initialData });
    };
  }, [props.open]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const watchPostCode = watch("postCode");

  useEffect(() => {
    const getAsync = async () => {
      let city = await DawaService.getDawaCityByPostCode(watchPostCode);
      if (city) {
        setValue("city", city);
        clearErrors("postCode");
      } else {
        setError("postCode", { type: "manual", message: "Ugyldigt postnummer" });
        setValue("city", "");
      }
    };
    if (watchPostCode && /^[0-9]{4}$/.test(watchPostCode.toString())) {
      getAsync();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPostCode]);

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={() => props.handleClose()}>
      <form onSubmit={handleSubmit(createCompany)}>
        <DialogTitle>Opret ny firma {errors.root?.message}</DialogTitle>
        <StyledDialogContent>
          <TextField
            fullWidth
            autoComplete="off"
            margin="dense"
            id="name"
            label="Firma"
            error={!!errors.name}
            {...register("name", { required: true })}
          />
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Controller
                name="cvr"
                control={control}
                rules={{ required: true, pattern: /^[0-9]{8}$/ }}
                render={({ field }) => (
                  <FormattedTextField
                    format="########"
                    fullWidth
                    autoComplete="off"
                    margin="dense"
                    id="cvr"
                    label="CVR"
                    error={!!errors.cvr}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <Button variant="outlined" color="primary" target="_blank" href="https://datacvr.virk.dk/data/">
                Find CVR
              </Button>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            autoComplete="off"
            margin="dense"
            id="address"
            label="Adresse"
            type="text"
            error={!!errors.address}
            {...register("address", { required: true })}
          />
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name="postCode"
                control={control}
                render={({ field }) => (
                  <FormattedTextField
                    format="####"
                    autoComplete="off"
                    margin="dense"
                    id="postCode"
                    label="Postnr."
                    error={!!errors.postCode}
                    helperText={errors.postCode?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={9}>
              <ReadOnlyTextField
                autoComplete="off"
                margin="dense"
                fullWidth
                id="city"
                label="By"
                type="text"
                {...register("city")}
                disabled
              />
            </Grid>
          </Grid>
          <Controller
            name="phone"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormattedTextField
                format="+45 ## ## ## ##"
                fullWidth
                autoComplete="off"
                margin="dense"
                label="Tlf"
                type="tel"
                error={!!errors.phone}
                {...field}
              />
            )}
          />
          <TextField
            fullWidth
            autoComplete="off"
            margin="dense"
            id="email"
            label="Email"
            type="email"
            error={!!errors.email}
            {...register("email", { required: true })}
          />
          {/* <DistanceField
          fullWidth
          autoComplete="off"
          margin="dense"
          id="travelRange"
          label="Arbejdsradius "
          type="text"
          value={state.travelRange || ""}
          onChange={changeValue}
        />
        <CurrencyField
          fullWidth
          autoComplete="off"
          margin="dense"
          id="pricePerHour"
          label="Gennemsnitlig timepris ekskl. moms"
          type="text"
          value={state.pricePerHour || ""}
          onChange={changeValue}
        /> */}
          <Autocomplete
            multiple
            open={state.open}
            value={state.selectedMunicipalities}
            onChange={changeMunicipalities}
            defaultValue={state.selectedMunicipalities}
            onInputChange={autoCompleteChange}
            onOpen={() => {
              dispatch({ type: "open", payload: true });
            }}
            onClose={() => {
              dispatch({ type: "open", payload: false });
            }}
            noOptionsText="Ingen muligheder"
            getOptionLabel={(option: any) => parseInt(option.kommune.kode) + " " + option.kommune.navn}
            options={state.municipalityOptions}
            loading={state.loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Kommuner"
                margin="dense"
                fullWidth
                autoComplete="off"
                type="text"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  autoComplete: "off",
                }}
              />
            )}
          />

          <Grid item xs={12}>
            <Typography>Kompetencer</Typography>
          </Grid>
          <Grid container spacing={1}>
            {Object.entries(CraftsmanTypes).map(([id, value]) => (
              <Grid key={id} item sm={3} xs={6}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      id={id + ""}
                      checked={state.competencies!.indexOf(parseInt(id)) > -1}
                      onChange={changeCompetency}
                    />
                  }
                  label={value}
                />
              </Grid>
            ))}
          </Grid>
          {!state.competencies.length && (
            <Grid item xs={12}>
              <Typography style={{ color: "red" }}>Skal du vælge mindst en kompetence</Typography>
            </Grid>
          )}
        </StyledDialogContent>

        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => props.handleClose()}>
            Annuller
          </Button>

          <Button variant="contained" color="primary" type={"submit"}>
            Opret
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCompanyDialog;

const StyledDialogContent = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("lg")]: {
      padding: 12,
    },
  },
}))(DialogContent);

const StyledFormControlLabel = withStyles((theme: Theme) => ({
  label: {
    fontSize: 14,
  },
}))(FormControlLabel);
