import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import UserActionsMenu from "./UserActionsMenu";
import UserDialog from "./UserDialog";
import PasswordDialog from "./PasswordDialog";
import GeneralDialog from "../../../shared/GeneralDialog";
import DataTable from "../../../shared/DataTable";
import { format, getDate, getMonth, getYear, isExists, isValid } from "date-fns";
import daLocale from "date-fns/locale/da";
import { Add } from "@mui/icons-material";
import { AdminResetPasswordCommand, User, UserRole } from "../../../interfaces/models";
import { useAdmin } from "../../adminActions";

const Users = () => {
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogPwd, setOpenDialogPwd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [admin, actions] = useAdmin();

  const getUsers = (
    query: string,
    role: UserRole,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => {
    return actions.getUserList(role, query, skip, take, sortField, sortDescending);
  };

  const updateUserHandler = (user: User) => {
    actions.updateUser({ ...user });
  };

  const uploadUserHandler = (user: User) => {
    actions.uploadUser(user);
  };

  const updateFromDialog = (user: User | undefined) => {
    setCurrentUser(user);
    setOpenDialog(true);
  };

  const resetFromDialog = (user: User | undefined) => {
    setCurrentUser(user);
    setOpenDialogPwd(true);
  };

  const deleteFromDialog = (user: User | undefined) => {
    setCurrentUser(user);
    setOpenDelete(true);
  };

  const resetPassword = (user: AdminResetPasswordCommand) => {
    actions.resetPassword(user);
  };

  const deleteUser = async (accepted?: boolean) => {
    setOpenDelete(false);
    if (accepted) {
      if (currentUser) {
        await actions.deleteUser(currentUser.id);
      }
    }
  };

  const lockUser = async (user: User | undefined) => {
    if (user) {
      await actions.lockUser(user?.id);
    }
  };

  return (
    <>
      <DataTable
        columns={[
          { label: "UserName", key: "userName" },
          { label: "Email", key: "email" },
          { label: "Name", key: "firstName" },
          { label: "", key: "lastName" },
          { label: "Last IP", key: "lastLoginIp" },
          {
            label: "Last activity",
            key: "modified",
            sortKey: "lastSeen",
            transform: (val) => {
              const date = new Date(val);
              return !val || !isValid (date) || !isExists(getYear(date), getMonth(date), getDate(date))
                ? "never"
                : format(date, "dd. MMM yyyy HH.mm", { locale: daLocale });
            },
          },
          {
            label: "Created",
            key: "created",
            transform: (val) => format(new Date(val), "dd. MMM yyyy HH.mm", { locale: daLocale }),
          },
          {
            label: "Status",
            key: "locked",
            sortKey: "lastSeen",
            transform: (val) => (val && "Disabled") || "Active",
          },
        ]}
        // dropdownFilterValues={[
        //   // { id: UserRole.Inspector, value: "Inspectors" },
        //   { id: UserRole.Craftsman, value: "Craftsmen" },
        //   // { id: UserRole.Customer, value: "Customers" },
        //   { id: UserRole.Administrator, value: "Admins" },
        // ]}
        // dropdownFilterTitle="Role"
        count={admin.count}
        data={admin.data}
        getData={getUsers}
        initialFilter={UserRole.Craftsman as unknown as string}
        cellActionComponent={
          <UserActionsMenu
            lockUser={lockUser}
            deleteUser={deleteFromDialog}
            updateUser={updateFromDialog}
            resetPassword={resetFromDialog}
          />
        }
        textFilter
      />
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} style={{ marginTop: 20 }}>
            <Add />
            Add user
          </Button>
        </Grid>
      </Grid>
      <UserDialog
        updateUserHandler={(user) => updateUserHandler({ ...user } as unknown as User)}
        uploadUserHandler={(user) => uploadUserHandler(user)}
        isEditMode={!!currentUser}
        user={currentUser as any}
        open={openDialog}
        setOpenDialog={(open) => setOpenDialog(open)}
        clearData={() => setCurrentUser(undefined)}
      />
      <PasswordDialog
        user={currentUser}
        open={openDialogPwd}
        setOpenDialog={(open) => setOpenDialogPwd(open)}
        clearData={() => setCurrentUser(undefined)}
        resetPassword={(user) => resetPassword(user)}
      />
      <GeneralDialog open={openDelete} dialogTitle={"Ønske du at slette denne bruger?"} handleClose={deleteUser} />
    </>
  );
};

export default Users;
