import React from "react";
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import { UserRole } from "../interfaces/models";
import { Badges } from "../common/hooks";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import { Dictionary } from "lodash";

export type MenuBlockType = {
  id: number;
  title: string;
  icon: React.ReactNode;
  text: string;
  path: string;
  badgeName?: Badges;
};

const MenuBlocks: Dictionary<MenuBlockType[]> = {
  [UserRole.Craftsman]: [
    {
      id: 1,
      title: "Start ny opgave",
      icon: <AssignmentIcon color="primary" />,
      text: "Her starter du en ny opgave",
      path: "/tasklist/create/choose",
    },
    {
      id: 2,
      title: "Igangværende opgaver",
      icon: <BuildIcon color="primary" />,
      text: "Her holder du styr på igangværende opgaver",
      path: "/tasklist",
      badgeName: Badges.Task,
    },
    {
      id: 6,
      title: "Stamdata",
      icon: <SettingsIcon color="primary" />,
      text: "Her kan du redigere dine stamdata, såsom cvr, tlf. nummer og time priser",
      path: "/profile",
    },
    {
      id: 7,
      title: "Mine faste kunder",
      icon: <PeopleIcon color="primary" />,
      text: "Kunder der har accepteret dig som deres faste håndværker",
      path: "/customers",
    },
  ],
};

export default MenuBlocks;
