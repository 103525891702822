import React from "react";
import { Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, styled } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const drawerWidth = 240;

const SidebarContent = (props: any) => {
  const location = useLocation();
  return (
    <>
      <List component="nav">
        <StyledLink to="users">
          <ListItemButton selected={location.pathname === "/admin/users"}>
            <ListItemText primary="Users" />
          </ListItemButton>
        </StyledLink>
        <StyledLink to="companies">
          <ListItemButton selected={location.pathname === "/admin/companies"}>
            <ListItemText primary="Companies" />
          </ListItemButton>
        </StyledLink>
      </List>
    </>
  );
};

const Sidebar = (props: {
  title: string;
  templateNamesList?: any;
  localTemplate?: any;
}) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      anchor="left"
    >
      <Toolbar />
      <SidebarContent
        templateNamesList={props.templateNamesList}
        localTemplate={props.localTemplate}
      />
    </Drawer>
  );
};

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
`;

export default Sidebar;
