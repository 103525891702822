import React, { useReducer, useRef } from "react";
import {
  Button,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import format from "date-fns/format";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { FeatureMetadata, LinkRequestState } from "../../interfaces/models";
import AppToolbarV2 from "../../shared/AppToolbar";
import DataTable from "../../shared/DataTable";
import Footer from "../../shared/Footer";
import {
  useCraftsmanActions,
  useCraftsmanState,
} from "../../actions/craftsman/craftsmanActions";
import AddressAutocomplete, {
  AutocompleteRef,
} from "../../shared/AddressAutocomplete";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minHeight: "calc(100vh - 64px)",
      overflowY: "auto",
      WebkitOverflowScrolling: "touch",
      maxWidth: "100%",
      padding: 0,
      margin: 0,
    },
    content: {
      flex: "1 0 auto",
    },
  })
);
type RegularCustomersPageState = {
  house?: FeatureMetadata;
  sending: boolean;
  filters: {
    query: string;
    filter: any;
    skip: number;
    take: number;
    sortField: string;
    sortDescending: boolean;
  };
};

const initialState: RegularCustomersPageState = {
  house: undefined,
  sending: false,
  filters: {
    query: "",
    filter: "",
    skip: 0,
    take: 10,
    sortField: "",
    sortDescending: true,
  },
};

const reducer = (
  state: typeof initialState,
  action: { type: keyof RegularCustomersPageState; payload: any }
) => {
  return { ...state, [action.type]: action.payload };
};

export const RegularCustomersPage = () => {
  const craftsmanState = useCraftsmanState();
  const actions = useCraftsmanActions();
  const classes = useStyles();
  const craftsmanActions = useCraftsmanActions();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const autocompleteRef = useRef<AutocompleteRef>(undefined!);

  const [state, dispatch] = useReducer(reducer, initialState);

  const getRequests = (
    query: string,
    filter: any,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => {
    dispatch({
      type: "filters",
      payload: {
        query,
        filter,
        skip,
        take,
        sortField,
        sortDescending,
      },
    });
    return actions.getCustomerRequests(
      query,
      filter,
      skip,
      take,
      sortField,
      sortDescending
    );
  };

  const link = () => {
    if (state.house?.houseId) {
      dispatch({ type: "sending", payload: true });
      craftsmanActions
        .link(
          state.house.houseId,
          `${state.house.address}, ${state.house.postcode} ${state.house.city}`
        )
        .then(() => {
          dispatch({ type: "house", payload: undefined });
          if (autocompleteRef.current) {
            autocompleteRef.current.clear();
          }
          actions.getCustomerRequests(
            state.filters.query,
            state.filters.filter,
            state.filters.skip,
            state.filters.take,
            state.filters.sortField,
            state.filters.sortDescending
          );
        })
        .catch((error) => {})
        .finally(() => {
          dispatch({ type: "sending", payload: false });
        });
    }
  };

  const changeAutocompleteValue = (value: any) => {
    if (value.value) {
      dispatch({ type: "house", payload: value.houseData });
    }
  };
  return (
    <>
      <Grid
        direction="column"
        justifyContent="flex-start"
        spacing={3}
        alignItems="center"
        container
        className={classes.container}
      >
        <Container disableGutters className={classes.content}>
          <AppToolbarV2 />
          <Grid
            px={2}
            item
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            my={2}
          >
            <Grid item xs={8} md={7}>
              <AddressAutocomplete
                selectedAction={changeAutocompleteValue}
              />
            </Grid>
            <Grid item xs={4} md={5}>
              <Button
                onClick={link}
                color="primary"
                variant="contained"
                disabled={state.sending}
              >
                {xs ? "Send" : "Send en anmodning"}
              </Button>
            </Grid>
          </Grid>
          <DataTable
            data={craftsmanState.requests}
            getData={getRequests}
            columns={[
              { label: "Adresse", key: "address" },
              {
                label: "Oprettet",
                key: "created",
                transform: (val) => format(new Date(val), "dd.MM.yyyy"),
              },
              {
                label: "Status",
                key: "state",
                transform: (val) => getStatusText(val),
              },
            ]}
            textFilter
            dropdownFilterValues={[
              {
                id: LinkRequestState.Any,
                value: "Alle",
              },
              {
                id: LinkRequestState.Pending,
                value: getStatusText(LinkRequestState.Pending),
              },
              {
                id: LinkRequestState.Confirmed,
                value: getStatusText(LinkRequestState.Confirmed),
              },
              {
                id: LinkRequestState.Cancelled,
                value: getStatusText(LinkRequestState.Cancelled),
              },
            ]}
            dropdownFilterTitle="Status"
            count={craftsmanState.count}
            itemsPerPage={20}
          />
        </Container>
        {/* <Footer /> */}
      </Grid>
    </>
  );
};

const getStatusText = (status: LinkRequestState) => {
  switch (status) {
    case LinkRequestState.Pending:
      return "Afventer";
    case LinkRequestState.Confirmed:
      return "Accepteret";
    case LinkRequestState.Cancelled:
      return "Afvist";
  }
};
