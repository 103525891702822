import React from "react";
import {
  Grid,
  InputLabel,
  Typography,
  IconButton,
  Card,
  CardContent,
  Divider,
  Theme,
  useTheme,
  useMediaQuery,
  CardMedia,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import Picture from "../../../shared/Picture";
import { format } from "date-fns";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HomeIcon from "@mui/icons-material/Home";
import CraftsmenTaskStatus from "./TaskStatusLabel";
import { CraftsmanTask, CraftsmanType } from "../../../interfaces/models";
import { CraftsmanTypes } from "../../../constants/appConstants";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: 80,
    border: "1px solid #EEE",
    backgroundColor: "white",
    borderRadius: 3,
    padding: 5,
    boxSizing: "border-box",
    marginTop: 5,
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  control: {
    minWidth: 110,
  },
  pictureList: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  additionalInfo: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  additionalContol: {
    minWidth: 200,
  },
  changed: {
    fontWeight: "bold",
  },
  disabledColor: {
    "& input": {
      color: "black",
    },
  },
  scrolled: {
    scrollMargin: "50px 0 0 0",
    width: "100%",
  },
  date: {
    background: "rgba(30, 183, 17, .1)",
    color: theme.palette.primary.main,
    padding: "4px 8px",
    borderRadius: 20,
  },

  photoWrapper: (props: TaskListInfoProps) => ({
    alignSelf: "stretch",
    justifyContent: "center",
    height: 120,
    width: 120,
    padding: 0,
    boxShadow: props.pictures && props.pictures.length > 1 ? "5px -5px 0 0 #c2c3cb" : "none",
    borderRadius: 3,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  }),
  img: {
    height: "100%",
    background: "rgba(0, 0, 0, 0.03)",
    border: "1px solid #c2c3cb",
    color: "#c2c3cb",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 140,
      border: "none",
    },
  },
  photoOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    position: "relative",
    height: 20,
    bottom: 25,
    paddingRight: 5,
    [theme.breakpoints.down("sm")]: {
      top: 20,
      marginTop: -20,
    },
  },
  media: {
    height: 140,
  },
}));

interface TaskListInfoProps extends CraftsmanTask {
  allowUpdate: boolean;
  companyName: string;
  handleOpen: (id: string) => void;
  draft: boolean;
  id: string;
}

const Task = (props: TaskListInfoProps) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const renderValue = (val: CraftsmanType) => CraftsmanTypes[val];
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item container>
      <Card variant="outlined" sx={{ width: "100%" }}>
        {/* Photo mobile */}
        {isXs && (
          <>
            {!!props.pictures?.length ? (
              <>
                {props.pictures.length > 1 && (
                  <Grid container justifyContent="flex-end" className={classes.photoOverlay}>
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      + {props.pictures.length - 1} billeder
                    </Typography>
                  </Grid>
                )}
                <CardMedia
                  className={classes.media}
                  image={props.pictures[0].url ? props.pictures[0].url : props.pictures[0].data}
                />
              </>
            ) : (
              <CardMedia className={classes.media}>
                <Grid className={classes.img} container justifyContent="center" alignContent="center">
                  <HomeIcon fontSize="large" color="inherit" />
                </Grid>
              </CardMedia>
            )}
          </>
        )}
        {/* Photo mobile end*/}

        <CardContent>
          <Grid container direction="column" spacing={3}>
            <Grid item container justifyContent="space-between" direction={isXs ? "column-reverse" : "row"}>
              <Grid item xs={12} sm={8} container direction="column" spacing={2}>
                <Grid item container>
                  <Typography variant="h4">Opgave: {props.header || ""}</Typography>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item>
                    <CraftsmenTaskStatus data={props.status} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.date} variant="body2">
                      Deadline: {format(props.deadline ? new Date(props.deadline) : new Date(), "dd.MM.yy")}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Divider sx={{ width: "100%" }} variant="fullWidth" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction={isXs ? "column-reverse" : "row"}
                xs={12}
                sm={4}
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={1}
                sx={{ flexWrap: "nowrap" }}
              >
                <StyledIconButton
                  disabled={!props.allowUpdate}
                  onClick={() => props.handleOpen(props.id)}
                  color="primary"
                >
                  <EditOutlinedIcon />
                </StyledIconButton>
                {!isXs && (
                  <Grid className={classes.photoWrapper}>
                    {!!props.pictures?.length ? (
                      <>
                        <Picture
                          key={props.pictures[0].id}
                          id={props.pictures[0].id}
                          source={props.pictures[0].url ? props.pictures[0].url : props.pictures[0].data}
                          width={100}
                          height={100}
                          onClick={() => {}}
                        />
                        {props.pictures.length > 1 && (
                          <Grid container justifyContent="flex-end" className={classes.photoOverlay}>
                            <Typography variant="body2" sx={{ color: "#fff" }}>
                              + {props.pictures.length - 1} billeder
                            </Typography>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Grid className={classes.img} container justifyContent="center" alignContent="center">
                        <HomeIcon fontSize="large" color="inherit" />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!props.draft && (
              <Grid item container alignContent="space-between" justifyContent="flex-start" spacing={1}>
                <Grid item container xs={12} sm={6}>
                  <Grid item container direction="column" xs={6}>
                    <InputLabel>Håndværkere</InputLabel>
                    <Typography variant="body1">
                      {props.craftsmen?.length ? renderValue(props.craftsmen[0]) : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                  <Grid item container direction="column" xs={6}>
                    <InputLabel>Virksomhed</InputLabel>
                    <Typography variant="body1">{props.companyName}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item container direction="column" xs={6} sm={3}>
                    <Grid item>
                      {" "}
                      <InputLabel>Timer</InputLabel>
                    </Grid>

                    <Grid item>
                      {" "}
                      <Typography variant="body1">
                        {props.estimatedTime.toLocaleString("da-dk") || <>&nbsp;</>}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={6} sm={9}>
                    <Grid item>
                      <InputLabel>Anslået total pris</InputLabel>
                    </Grid>
                    <Grid item container direction={isXs ? "column" : "row"} spacing={1}>
                      <Grid item>
                        <Grid item container direction="column">
                          <Typography variant="body1">
                            {(
                              props.estimatedPrice +
                              (props.solutions?.reduce((acc, curr) => acc + curr.unitPrice, 0) || 0) +
                              props.estimatedMaterialPrice
                            ).toLocaleString("da-dk", {
                              currency: "DKK",
                              style: "currency",
                            }) || <>&nbsp;</>}
                          </Typography>
                          <Typography sx={{ fontSize: "9px" }}>ekskl. moms</Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid item container direction="column">
                          <Typography variant="body1">
                            {(
                              props.estimatedPrice +
                              props.estimatedPrice * 0.25 +
                              (props.solutions?.reduce((acc, curr) => acc + curr.unitPrice, 0) || 0) +
                              (props.solutions?.reduce((acc, curr) => acc + curr.unitPrice, 0) || 0) * 0.25 +
                              (props.estimatedMaterialPrice + props.estimatedMaterialPrice * 0.25)
                            ).toLocaleString("da-dk", {
                              currency: "DKK",
                              style: "currency",
                            }) || <>&nbsp;</>}
                          </Typography>
                          <Typography sx={{ fontSize: "9px" }}>inkl. moms</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!props.draft && (
            <Grid item container>
              <Divider sx={{ width: "100%", marginTop: "5px" }} variant="fullWidth" />
            </Grid>
          )}
          <Grid item xs={12} sx={{ marginTop: isXs || !props.draft ? "5px" : "-15px" }}>
            <InputLabel>Note</InputLabel>
            <Typography variant="body1">{props.craftsmanNote || "Ingen"}</Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Task;

const StyledIconButton = withStyles((theme) => ({
  root: {
    marginRight: 5,
    background: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 30,
      marginTop: -36,
    },
    "&:hover": {
      background: "rgb(35, 135, 11)",
    },
  },
}))(IconButton);
