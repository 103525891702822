import React, { useEffect, useReducer } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { NumberField } from "../../../shared/FormattedFields";

export type DiscountDialogResult = {
  apply: boolean;
  value: string;
  percent: boolean;
};

const reducer = (state: { value: string; mode: "money" | "percent"}, action: { type: keyof typeof state, payload: any}) => {
  return {...state, [action.type]: action.payload};
}

const DiscountDialog = (props: {
  open: boolean;
  init: number;
  maxValue: number;
  handleClose: (dialogresult: DiscountDialogResult) => void;
}) => {
  const [state, dispatch] = useReducer(reducer, { value: "", mode: "money" });

  const handleClose = (apply?: boolean) => {
    props.handleClose({
      apply: apply ?? false,
      value: state.value ?? 0,
      percent: state.mode === "percent" ? true : false,
    });
  };

  const setRadioValue = (value: string) => {
    dispatch({type: "value", payload: ""});
    dispatch({type: "mode", payload: value });
  };

  const setPercentValue = (event: { currentTarget: { value: string } }) => {
    const val = event.currentTarget.value;
    if (!props.maxValue) {
      return 0;
    }

    if (state.mode === "percent" && parseFloat(val) >= 100) {
      dispatch({type: "value", payload: (99.99).toFixed(2)});
      return;
    }
    if (state.mode === "money" && parseFloat(val) >= props.maxValue) {
      dispatch({type: "value", payload: (props.maxValue - 0.01).toFixed(2)})
      return;
    }

    dispatch({type: "value", payload: val? parseFloat(val).toFixed(2) :""})
  };

  useEffect(() => {
    
    let val = props.init ? props.init.toFixed(2): "";
    if(val && state.mode === "percent") {
        val = (props.init / props.maxValue * 100).toFixed(2);
    }
      dispatch({type: "value", payload: val})  
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.init]);

  return (
    <Dialog fullWidth open={props.open} onClose={() => handleClose()}>
      <DialogTitle>Indtast værdi</DialogTitle>
      <DialogContent>
        <NumberField value={state.value} onChange={(event: { currentTarget: { value: string } }) => setPercentValue(event)} />
        <RadioGroup row value={state.mode} onChange={(event, value) => setRadioValue(value)}>
          <FormControlLabel value={"money"} control={<Radio />} label="Kroner" />
          <FormControlLabel value={"percent"} control={<Radio />} label="Procent" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Annuller
        </Button>
        <Button disabled={!state.value} onClick={() => handleClose(true)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountDialog;
