import { TextField, styled } from "@mui/material";

export const StyledForm = styled("form")(({ theme }) => ({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }));

export const ReadOnlyTextField = styled(TextField)(() => ({
  ".MuiInputBase-input": {
    textAlign: "center",
  },
  ".MuiInputBase-root.Mui-disabled": {
    opacity: 1,
    "input.Mui-disabled": {
      color: "#000",
      WebkitTextFillColor: "#000",
    },
  },
}));  
