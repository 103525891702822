import React, { useEffect, useReducer } from "react";
import { Button, MenuItem, Menu, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getStatusText, EmptyGuid } from "../../../constants/appConstants";
import { TaskListStatus } from "../../../interfaces/models";
import { useTaskState } from "../../../actions/tasklist/taskActions";

const useStyles = makeStyles((theme: Theme) => ({
  statusButton: (props: TaskListStatusProps) => ({
    background: props.variant === "default" ? theme.palette.primary.main : theme.palette.primary.contrastText,
    borderRadius: props.variant === "default" ? 20 : 4,
    "&:hover": {
      background: props.variant === "default" ? "rgb(35, 135, 11)" : theme.palette.primary.contrastText,
    },
    width: props.variant === "default" ? "auto" : "100%",
    color: props.variant === "default" ? theme.palette.primary.contrastText : "inherit",
    justifyContent: "space-between",
  }),
}));

type TaskListStatusProps = {
  status: TaskListStatus;
  variant: "default" | "defined";
  handleMenuItemClick: (itemId?: TaskListStatus) => void;
};

type TaskListStatusState = {
  anchorEl?: HTMLElement;
  statusText: string;
};

const reducer = (state: TaskListStatusState, action: { type: keyof TaskListStatusState; payload: any }) => ({
  ...state,
  [action.type]: action.payload,
});

const TaskStatus = (props: TaskListStatusProps) => {
  const classes = useStyles(props);
  const taskState = useTaskState();
  const [state, dispatch] = useReducer(reducer, { anchorEl: undefined, statusText: "" });

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: "anchorEl", payload: event.currentTarget });
  };

  const handleMenuItemClick = (itemId?: TaskListStatus) => {
    dispatch({ type: "anchorEl", payload: undefined });
    props.handleMenuItemClick(itemId);
  };

  useEffect(() => {
    dispatch({ type: "statusText", payload: getStatusText(props.status) });
    return () => {
      dispatch({ type: "statusText", payload: "" });
    };
  }, [props.status]);

  return (
    <>
      {taskState.id !== EmptyGuid && (
        <>
          <Button
            onClick={openMenu}
            variant="contained"
            className={classes.statusButton}
            disabled={!getDropdownValues(props.status).length}
          >
            {state.statusText}
            <ArrowDropDownIcon color="inherit" />
          </Button>
          <Menu
            anchorEl={state.anchorEl}
            keepMounted
            open={Boolean(state.anchorEl)}
            onClose={() => handleMenuItemClick()}
          >
            {getDropdownValues(props.status).map((item) => (
              <MenuItem onClick={() => handleMenuItemClick(item.id)} key={item.id} value={item.id}>
                {item.value}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
};

export default TaskStatus;

const getDropdownValues = (status: TaskListStatus) => {
  switch (status) {
    case TaskListStatus.New:
      return [
        {
          id: TaskListStatus.ReadyToSend,
          value: getStatusText(TaskListStatus.ReadyToSend),
        },
      ];
    case TaskListStatus.ReadyToSend:
      return [
        {
          id: TaskListStatus.New,
          value: getStatusText(TaskListStatus.New),
        },
      ];
    case TaskListStatus.Shared:
      return [
        {
          id: TaskListStatus.ReadyToSend,
          value: "Nyt " + getStatusText(TaskListStatus.ReadyToSend),
        },
      ];
    case TaskListStatus.Received:
      return [
        {
          id: TaskListStatus.ReadyToSend,
          value: getStatusText(TaskListStatus.ReadyToSend),
        },
        {
          id: TaskListStatus.InProgress,
          value: getStatusText(TaskListStatus.InProgress),
        },
        {
          id: TaskListStatus.Cancelled,
          value: getStatusText(TaskListStatus.Cancelled),
        },
      ];
    case TaskListStatus.Finished:
      return [
        {
          id: TaskListStatus.InProgress,
          value: getStatusText(TaskListStatus.InProgress),
        },
      ];
    case TaskListStatus.Cancelled:
      return [];
    default:
      return [
        {
          id: TaskListStatus.InProgress,
          value: getStatusText(TaskListStatus.InProgress),
        },
        {
          id: TaskListStatus.Finished,
          value: getStatusText(TaskListStatus.Finished),
        },
        {
          id: TaskListStatus.Cancelled,
          value: getStatusText(TaskListStatus.Cancelled),
        },
      ];
  }
};
