import React, { useEffect, useReducer } from "react";
import { useTheme, useMediaQuery, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppToolbarV2 from "../../shared/AppToolbar";
import TaskListLayout from "./components/TaskListLayout";
import GeneralDialog from "../../shared/GeneralDialog";
import NotificationBar from "../../shared/Notification";
import { useTaskActions, useTaskState } from "../../actions/tasklist/taskActions";
import EditTaskInfoDialog from "./dialogs/EditTaskInfoDialog";
import { FeatureMetadata } from "../../interfaces/models";

type CreateTaskListPageState = {
  openNewDialog: boolean;
  openConfirmSaveDialog: boolean;
  notificationShown: boolean;
  error: string;
  openLocalDataDialog: boolean;
  openEditDialog: boolean;
  localDataLoaded: boolean;
};

const initialState: CreateTaskListPageState = {
  openNewDialog: false,
  openConfirmSaveDialog: false,
  notificationShown: false,
  error: "",
  openLocalDataDialog: false,
  openEditDialog: false,
  localDataLoaded: false,
};

const reducer = (
  state: typeof initialState,
  action: {
    type: keyof CreateTaskListPageState | "showNotification";
    payload: any;
  }
) => {
  if (action.type === "showNotification") {
    return { ...state, ...action.payload };
  }
  return { ...state, [action.type]: action.payload };
};

export const CreateTaskListPage = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const taskActions = useTaskActions();
  const taskState = useTaskState();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    taskActions.checkLocalState().then(() => {
      if (taskState.localDataFound && !state.localDataLoaded) {
        dispatch({ type: "openLocalDataDialog", payload: true });
      } else {
        dispatch({ type: "openEditDialog", payload: true });
      }
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.localDataFound]);

  const handleCloseNewTaskListDialog = async (value?: boolean) => {
    dispatch({ type: "openNewDialog", payload: false });
  };

  const goBack = () => {
    if (taskState.changed) {
      dispatch({ type: "openConfirmSaveDialog", payload: true });
      return;
    }
    window.history.back();
  };

  const handleNotificationClose = () => {
    dispatch({ type: "notificationShown", payload: false });
  };

  const handleCloseLocalDataDialog = async (confirm?: boolean) => {
    dispatch({ type: "openEditDialog", payload: true });
    if (confirm) {
      await taskActions.loadLocalState();
      dispatch({ type: "localDataLoaded", payload: true });
    } else {
      await taskActions.clearTaskLocalState();
    }
    dispatch({ type: "openLocalDataDialog", payload: false });
  };

  const handleCloseConfirmSaveDialog = async (confirmed?: boolean) => {
    dispatch({ type: "openConfirmSaveDialog", payload: false });
    if (confirmed) {
      try {
        await taskActions.saveTaskList(false);
        window.history.back();
        return;
      } catch (error: any) {
        dispatch({
          type: "error",
          payload: error,
        });
        dispatch({
          type: "notificationShown",
          payload: true,
        });
      }
    }
    window.history.back();
  };

  const handleCloseEditTaskDialog = (accepted: boolean | undefined, customerInfo?: FeatureMetadata) => {
    dispatch({ type: "openLocalDataDialog", payload: false });
    if (accepted && customerInfo) {
      dispatch({ type: "openEditDialog", payload: false });
      taskActions.updateCustomerInfo(customerInfo);
      if (
        !customerInfo.postcode ||
        !customerInfo.owner ||
        !customerInfo.ownerLastName ||
        !customerInfo.ownerEmail ||
        !customerInfo.ownerPhone
      ) {
        dispatch({
          type: "error",
          payload: "Tilføj mangler info",
        });
        dispatch({
          type: "notificationShown",
          payload: true,
        });
        dispatch({ type: "openEditDialog", payload: true });
      }
    } else {
      navigate("/tasklist", { replace: true });
    }
  };

  return (
    <Container style={{ marginBottom: isSm ? 140 : 0 }}>
      <AppToolbarV2 goBack={goBack} title="Opgavebeskrivelse" />
      {taskState.data?.address && <TaskListLayout />}
      <GeneralDialog
        open={state.openNewDialog}
        dialogTitle={"Ønsker du at starte på en ny opgaveliste?"}
        dialogText={"Husk at gemme eventuelle ændringer inden."}
        handleClose={handleCloseNewTaskListDialog}
      />
      <GeneralDialog
        open={state.openConfirmSaveDialog}
        dialogTitle={"Du har ændret opgavelisten. Ønsker du at gemme det?"}
        dialogText={""}
        handleClose={handleCloseConfirmSaveDialog}
        cancelButtonText={"Nej"}
        okButtonText={"Ja"}
      />
      <GeneralDialog
        open={state.openLocalDataDialog}
        dialogTitle={"Du har ikke-gemte data."}
        dialogText={"Ønsker du at bruge ikke-gemte data?"}
        handleClose={handleCloseLocalDataDialog}
        cancelButtonText={"Nej"}
        okButtonText={"Ja"}
      />
      <EditTaskInfoDialog open={state.openEditDialog} handleClose={handleCloseEditTaskDialog} data={taskState.data} />
      <NotificationBar
        open={state.notificationShown}
        variant={"error"}
        handleClose={handleNotificationClose}
        message={state.error}
      />
    </Container>
  );
};
