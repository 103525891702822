import React from 'react'
import AppToolBarV2 from '../../shared/AppToolbar'
import UserProfile from './components/UserProfile'

const CraftsmanProfilePage = () => {
  return (<>
    <AppToolBarV2 fullWidth/>
    <UserProfile />
    
    </>
  )
}

export default CraftsmanProfilePage