import { CraftsmanTaskStatus, CraftsmanType, TaskListStatus } from "../interfaces/models";

export const drawerWidth = window.screen.width * 0.33;
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const EmailRegExp = /^[A-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export enum LocalStorageItems {
  User = "user",
  Report = "report",
  Task = "task",
  Template = "template",
}

export const CraftsmanTypes: {[idx: number]: string;} = {
  
  [CraftsmanType.None]: "Ingen",
  [CraftsmanType.Electrician]: "Elektriker",
  [CraftsmanType.Glazier]: "Glarmester",
  [CraftsmanType.Painter]: "Maler",
  [CraftsmanType.Builder]: "Murer",
  [CraftsmanType.Carpenter]: "Tømrer",
  [CraftsmanType.Woodworker]: "Snedker",
  [CraftsmanType.Heating]: "VVS",
  [CraftsmanType.Blacksmith]: "Smed",
  [CraftsmanType.Sewerage]: "Kloakmester",
  [CraftsmanType.LandscapeGardener]: "Anlægsgartner",
  [CraftsmanType.Demolition]: "Nedrivning",
  [CraftsmanType.Plumber]: "Blikkenslager",
  [CraftsmanType.GroundAndBeton]: "Jord og Beton",
  [CraftsmanType.Architect]: "Arkitekt",
  [CraftsmanType.Engineer]: "Ingeniør",
  [CraftsmanType.Scaffolding]: "Stillads",
  [CraftsmanType.ConstructionConsultant]: "Byggesagkyndig",
  [CraftsmanType.Other]: "Andet",
};

export const TimeEstimate = [
  { id: 1, value: "Nu" },
  { id: 2, value: "1 måned" },
  { id: 3, value: "3 måneder" },
  { id: 4, value: "6 måneder" },
  { id: 5, value: "1 år" },
  { id: 6, value: "2 år" },
  { id: 7, value: "3 år" },
];

export const getStatusText = (status: TaskListStatus, short?: boolean) => {
  switch (status) {
    case TaskListStatus.None:
      return "Alle";
    case TaskListStatus.New:
      return "Kladde";
    case TaskListStatus.Received:
      if (short) return "Accepteret";
      return "Accepteret af kunden";
    case TaskListStatus.InProgress:
      return "Igangsat";
    case TaskListStatus.Shared:
      if (short) return "Sendt";
      return "Sendt til kunden afventer accept";
    case TaskListStatus.Finished:
      return "Færdig";
    case TaskListStatus.Cancelled:
      return "Afvist";
    case TaskListStatus.ReadyToSend:
      return "Tilbud";
  }
};

export const getCraftsmanStatusText = (status: CraftsmanTaskStatus) => {
  switch (status) {
    case CraftsmanTaskStatus.New:
      return "Ny";
    case CraftsmanTaskStatus.InProgress:
      return "I gang";
    case CraftsmanTaskStatus.Done:
      return "Færdig";
    case CraftsmanTaskStatus.Cancelled:
      return "Afvist";
    case CraftsmanTaskStatus.Shared:
      return "Delt";
    default:
      return "Ny";
  }
};
