import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
  LinearProgress,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

import ElevationScroll from "./ElevationScroll";
import GeneralDialog from "./GeneralDialog";
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowBack } from "@mui/icons-material";
import { useUser } from "../common/hooks";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserActions } from "../pages/User/userActions";
import MenuBlocks from "./MenuBlocks";

const useStyles = makeStyles((theme) => ({
  userName: {
    whiteSpace: "nowrap",
  },
  header: {
    padding: "0 15px 5px",
    fontSize: 12,
    "@media (min-width: 600px)": {
      flexDirection: "row",
      fontSize: 14,
      padding: 10,
    },
  },
  mobileColumn: {
    flex: 1,
    alignItems: "baseline",
    "@media (min-width: 600px)": {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  menuBar: {
    flexWrap: "nowrap",
    flex: 5,
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },

  appBar: {
    position: "fixed",
    zIndex: 999,
  },
  customTitleContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  toolBar: {
    flexDirection: "row",
  },
  menuButton: {
    color: "inherit",
    padding: "12px 20px",
  },
  logo: {
    padding: "7px 15px 2px",
    fontSize: 10,
    letterSpacing: 5,
    cursor: "pointer",
    fontFamily: "'Montserrat', sans-serif",
    "@media (min-width: 600px)": {
      letterSpacing: 8,
      padding: "14px 0 12px 40px",
      fontSize: 12,
    },
  },
}));

type AppToolBarProps = {
  handleDrawerToggle?: (() => void) | false;
  goBack?: (() => void) | false;
  children?: React.ReactNode;
  title?: string;
  buttons?: React.ReactNode;
  light?: boolean;
  logo?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
};

const AppToolBarV2 = (props: AppToolBarProps) => {
  const classes = useStyles();
  const { light, logo, loading } = props;
  const [openLogout, setOpenLogoutDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const [title, setTitle] = useState(props.title);
  const user = useUser();
  const userActions = useUserActions();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role) {
      const title = MenuBlocks[user.role]?.find((x) => x.path === location.pathname)?.title;
      if (title) {
        setTitle(title);
      }
    }
    return () => {};
  }, []);

  const goToProfile = () => {
    setAnchorEl(null);
    navigate("/" + location.pathname.split("/")[1] + "/profile");
  };

  const openLogoutDialog = () => {
    setAnchorEl(null);
    setOpenLogoutDialog(true);
  };

  const logout = async (accepted: boolean | undefined) => {
    setOpenLogoutDialog(false);
    if (accepted) {
      await userActions.logout();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const goBack = () => {
    window.history.back();
  };

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <ElevationScroll>
        <StyledAppBar
          style={{ left: !props.fullWidth && !isMd ? 240 : 0, width: !props.fullWidth && !isMd ? "calc(100% - 240px)" : "100%" }}
          color={light ? "default" : "secondary"}
          position="fixed"
          className={classes.appBar}
        >
          <StyledToolbar disableGutters>
            <Grid
              className={classes.toolBar}
              direction="column"
              justifyContent="space-between"
              container
              alignItems="flex-start"
            >
              <Grid direction="row" justifyContent="flex-start" item container alignItems="center" xs={4} sm={6}>
                {!!props.handleDrawerToggle && (
                  <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle} size="large">
                    <MenuIcon />
                  </IconButton>
                )}
                {!logo && !!props.goBack && (
                  <IconButton onClick={props.goBack} size="large">
                    <ArrowBack style={{ color: "white" }} />
                  </IconButton>
                )}
                {logo && (
                  <Typography
                    onClick={() => navigate(process.env.REACT_APP_MYHOUSE_URL || "/")}
                    className={classes.logo}
                    variant="body2"
                  >
                    myhouse
                  </Typography>
                )}
                <div className={classes.customTitleContent}>{props.children}</div>
                {!props.children && (
                  <>
                    {(logo || (!isXs && !logo)) && (
                      <Typography className={classes.header} variant="body2">
                        {!!title ? title : "Håndværkere"}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                container
                item
                xs={8}
                sm={6}
                alignItems="center"
                justifyContent="flex-end"
                className={classes.menuBar}
              >
                {props.buttons && <>{props.buttons}</>}
                {user.authorized && isMd && (
                  <Button onClick={openLogoutDialog}>
                    <ExitToAppTwoToneIcon style={{ color: "white" }} />{" "}
                  </Button>
                )}
              </Grid>
            </Grid>
          </StyledToolbar>
          {loading && <LinearProgress color="secondary" />}
        </StyledAppBar>
      </ElevationScroll>
      <GeneralDialog
        open={openLogout}
        dialogTitle={"Ønsker du at logge af?"}
        dialogText={"Husk at gemme eventuelle ændringer inden."}
        handleClose={logout}
      />
      {user.authorized && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList autoFocusItem={Boolean(anchorEl)} id="menu-list-grow">
                    {!location.pathname.includes("/profile") && <MenuItem onClick={goToProfile}>Profil</MenuItem>}
                    <MenuItem onClick={openLogoutDialog}>Log af</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default AppToolBarV2;

const StyledAppBar = withStyles({
  colorDefault: {
    background: "#fafafa",
  },
})(AppBar);

const StyledToolbar = withStyles({
  regular: {
    minHeight: 0,
  },
})(Toolbar);
