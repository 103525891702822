import {
  AddressGroupedListItem,
  CraftsmanTask,
  CraftsmanTaskStatus,
  CreateTaskListCommand,
  PictureRef,
  SearchResult,
  ActorRole,
  TaskListListItem,
  TaskListStatus,
  UpdateTaskListCommand,
  FeatureMetadata,
  UpdateTaskListMetadataCommand,
  Solution,
} from "../../interfaces/models";
import { AppState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { TaskState } from "./taskReducer";
import { getLocalData, removeLocalData, updateLocalData } from "../../common/utils";
import { AppError } from "../../interfaces/frontend";
import { Common, Task } from "../../constants/actionTypes";
import ApiService from "../../services/ApiService";
import { EmailRegExp, EmptyGuid, LocalStorageItems } from "../../constants/appConstants";
import { handleError } from "../../common/commonActions";
import DawaService from "../../services/AddressService";
import AddressService from "../../services/AddressService";

export const useTaskState = () => useSelector((state: AppState) => state.task);

export const useTaskActions = () => {
  const dispatch: ThunkDispatch<AppState, any, AnyAction> = useDispatch();
  return {
    getGroupedTaskLists: (active: boolean, skip = 0, take = 0) => dispatch(getGroupedTaskLists(active, skip, take)),
    getTaskList: (id: string) => dispatch(getTaskList(id)),
    getTaskLists: (
      query: string,
      status: TaskListStatus,
      skip: number,
      take: number,
      sortField: string,
      sortDescending: boolean
    ) => dispatch(getTaskLists(query, status, skip, take, sortField, sortDescending)),
    removeTask: (id: string) => dispatch(removeTask(id)),
    saveTaskList: (share: boolean) => dispatch(saveTaskList(share)),
    addPicture: (image: any) => dispatch(addPicture(image)),
    removePicture: (id: string) => dispatch(removePicture(id)),
    changeValue: (id: string, key: any, value: any) => dispatch(changeValue(id, key, value)),
    setHouseData: (data: any, updateCurrent?: boolean) => dispatch(setHouseData(data, updateCurrent)),
    changeCustomerInfo: (key: any, value: any) => dispatch(changeCustomerInfo(key, value)),
    shareTaskList: (taskListId: string) => dispatch(shareTaskList(taskListId)),
    changeTaskListStatus: (taskListId: string, status: TaskListStatus) =>
      dispatch(changeTaskListStatus(taskListId, status)),
    changeTaskStatus: (taskId: string, status: CraftsmanTaskStatus, role: ActorRole) =>
      dispatch(changeTaskStatus(taskId, status, role)),
    setDiscount: (value: number) => dispatch(setDiscount(value)),
    checkLocalState: () => dispatch(checkLocalState()),
    loadLocalState: () => dispatch(loadLocalState()),
    clearTaskLocalState: () => dispatch(clearTaskLocalState()),
    updateCustomerInfo: (customerInfo: FeatureMetadata) => dispatch(updateCustomerInfo(customerInfo)),
    updateTaskInfo: (taskInfo: CraftsmanTask) => dispatch(updateTaskInfo(taskInfo)),
    updateTaskNote: (noteText: string) => dispatch(updateTaskNote(noteText)),
    saveTaskInfo: (taskInfo: CraftsmanTask) => dispatch(saveTaskInfo(taskInfo)),
    getSolutions: (
      query: string,
      filter: any,
      skip: number,
      take: number,
      sortField: string,
      sortDescending: boolean
    ) => dispatch(getSolutions(query, filter, skip, take, sortField, sortDescending)),
    getHouseInfo: (houseId: string) => dispatch(getHouseInfo(houseId)),
  };
};

export const getGroupedTaskLists =
  (active: boolean, skip = 0, take = 0) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Task.GET_TASK_LISTS });
    const result: SearchResult<AddressGroupedListItem<TaskListListItem>> = await ApiService.get(
      `/api/task/grouped?active=${active}&skip=${skip}&take=${take}`
    );
    dispatch({ type: Task.GET_TASK_LISTS_SUCCEEDED, payload: result });
  };

export const getTaskLists =
  (query: string, status: TaskListStatus, skip = 0, take = 0, sortField: string, sortDescending: boolean) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Task.GET_TASK_LISTS });
    try {
      const result: SearchResult<AddressGroupedListItem<TaskListListItem>> = await ApiService.get(
        `/api/task?query=${query}&status=${status}&skip=${skip}&take=${take}&orderby=${sortField ?? ""}&desc=${
          sortDescending ?? true
        }`
      );
      dispatch({ type: Task.GET_TASK_LISTS_SUCCEEDED, payload: result });
      dispatch({ type: Task.SAVE_SEARCH_PARAMS, payload: { query, status, skip, take, sortField, sortDescending } });
      return result;
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch);
    }
  };

export const getTaskList = (id: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Task.GET_TASK_LIST });
  const result = await ApiService.get(`/api/task/${id}`);
  dispatch({ type: Task.GET_TASK_LIST_SUCCEEDED, payload: result });
  dispatch({
    type: Task.CALCULATE_TOTAL,
  });
};

export const getTaskStats =
  (propertyName: string, isDescending: boolean, skip: number, take: number) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Task.GET_TASK_STATS });
    const result = await ApiService.get(
      `/api/task/stats?propertyName=${propertyName}&isDescending=${isDescending}&skip=${skip}&take=${take}`
    );
    dispatch({ type: Task.GET_TASK_STATS_SUCCEEDED, payload: result });
  };

export const removeTask =
  (id: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({ type: Task.REMOVE_TASK, payload: id });
    dispatch({
      type: Task.SET_DISCOUNT,
      payload: 0,
    });
    dispatch({
      type: Task.CALCULATE_TOTAL,
    });
    await saveLocalState(dispatch, getState);
  };

const validateTaskList = (taskState: TaskState, share: boolean): [boolean, string] => {
  if (taskState.data?.houseId === EmptyGuid) {
    return [false, "Tilføj adresse"];
  }
  if (!taskState.data?.owner) {
    return [false, "Tilføj ejerens navn"];
  }
  if (!taskState.data?.ownerLastName) {
    return [false, "Tilføj ejerens efternavn"];
  }
  if (!taskState.data?.ownerPhone) {
    return [false, "Tilføj ejerens telefon"];
  }
  let check = EmailRegExp.test(taskState.data?.ownerEmail || "");
  if (!check) {
    return [false, "Tilføj ejerens email"];
  }

  if (!taskState.tasks.length) {
    return [false, "Tilføj opgaver"];
  }
  if (share) {
    for (let i = 0; i < taskState.tasks.length; i++) {
      if (!taskState.tasks[i].header) {
        return [false, "Tilføj header i opgave " + taskState.tasks[i].id];
      }
      if (!taskState.tasks[i].craftsmen || !taskState.tasks[i].craftsmen?.length) {
        return [false, "Tilføj håndværker i opgave '" + taskState.tasks[i].header + "'"];
      }
      if (!taskState.tasks[i].craftsmanCompanyId) {
        return [false, "Tilføj virksomhed i opgave '" + taskState.tasks[i].header + "'"];
      }
    }
  }
  return [true, ""];
};

export const saveTaskList =
  (share: boolean) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState): Promise<any> => {
    let taskState = getState().task;

    let result: any = null;

    const [validationResult, message] = validateTaskList(taskState, share);
    if (!validationResult) {
      return Promise.reject(message);
    }

    try {
      dispatch({ type: Task.SAVE_TASK_LIST });

      if (!taskState.id || taskState.id === EmptyGuid) {
        let taskData: CreateTaskListCommand = {
          data: taskState.data,
          tasks: taskState.tasks,
          offerId: EmptyGuid,
          discount: taskState.discount,
          share,
        };
        result = await ApiService.post(`/api/task/`, taskData);
      } else {
        let taskData: UpdateTaskListCommand = {
          taskListId: taskState.id,
          tasks: taskState.tasks,
          share,
          discount: taskState.discount,
          taskNote: taskState.taskNote,
        };
        result = await ApiService.put(`/api/task/`, taskData);
      }

      let pics = taskState.tasks.reduce(
        (x: PictureRef[], y, i) => x.concat(y.pictures?.length ? y.pictures.filter((p) => p.data) : []),
        []
      );

      if (pics.length) {
        for (let i = 0; i < pics.length; i++) {
          await ApiService.post(`/api/task/picture`, { ...pics[i], taskId: pics[i].itemId, taskListId: result.id });
        }
        //in order to update pictures with created links this shitcode is necessary
        result = await ApiService.get(`/api/task/${result.id}`);
      }

      dispatch({ type: Task.SAVE_TASK_LIST_SUCCEEDED, payload: result });
      dispatch({
        type: Task.CALCULATE_TOTAL,
      });

      await removeLocalData(LocalStorageItems.Task);

      return result;
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Task.TASK_OPERATION_FAILED,
        payload: error,
      });
    }
  };

export const addPicture =
  (image: any) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({
      type: Task.ADD_PICTURE,
      payload: image,
    });
    await saveLocalState(dispatch, getState);
  };

export const removePicture =
  (id: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({
      type: Task.REMOVE_PICTURE,
      payload: id,
    });
    await saveLocalState(dispatch, getState);
  };

export const changeValue =
  (id: string, key: keyof CraftsmanTask, value: any) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Task.CHANGE_VALUE,
      payload: { id, key, value },
    });

    dispatch({
      type: Task.CALCULATE_TOTAL,
    });
  };

export const setHouseData =
  (data: any, updateCurrent?: boolean) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({
      type: Common.GET_HOUSE_DATA,
    });
    const newData = await DawaService.getHouseData(data);
    dispatch({
      type: Common.GET_HOUSE_DATA_SUCCEEDED,
    });
    if (!updateCurrent) {
      dispatch({ type: Task.CLEAR_HOUSE_DATA });
    }
    dispatch({
      type: Task.SET_HOUSE_DATA,
      payload: newData,
    });
    return newData;
  };

export const getHouseInfo = (houseId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({
    type: Common.GET_HOUSE_DATA,
  });

  try {
    const houseData = await AddressService.getHouseInfo(houseId);
    dispatch({
      type: Common.GET_HOUSE_DATA_SUCCEEDED,
    });
    return houseData;
  } catch (error: unknown) {
    //do not throw; the service can be not available
  }
};

export const changeCustomerInfo =
  (key: string, value: string) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Task.CHANGE_CUSTOMER_INFO,
      payload: { key, value },
    });
  };

export const updateCustomerInfo =
  (customerInfo: FeatureMetadata) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({
      type: Task.UPDATE_CUSTOMER_INFO,
      payload: customerInfo,
    });
    await saveLocalState(dispatch, getState);
    try {
      const taskList = getState().task;
      if (taskList.id === EmptyGuid) return;

      const data: UpdateTaskListMetadataCommand = {
        taskListId: taskList.id,
        data: customerInfo,
      };
      let result = await ApiService.post("/api/task/metadata", data);
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Task.TASK_OPERATION_FAILED,
        payload: error,
      });
    }
  };

export const updateTaskInfo = (taskInfo: CraftsmanTask) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({
    type: Task.UPDATE_TASK_INFO,
    payload: taskInfo,
  });
  dispatch({
    type: Task.CALCULATE_TOTAL,
  });
};

export const updateTaskNote =
  (noteText: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({
      type: Task.UPDATE_TASKLIST_NOTE,
      payload: noteText,
    });
    dispatch({
      type: Task.CALCULATE_TOTAL,
    });
    await saveLocalState(dispatch, getState);
  };

export const shareTaskList =
  (taskListId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const taskState = getState().task;
    const [validationResult, message] = validateTaskList(taskState, true);
    if (!validationResult) {
      return Promise.reject(message);
    }

    dispatch({
      type: Task.SHARE_TASK_LIST,
    });

    try {
      await ApiService.put(`/api/task/share`, { taskListId });

      dispatch({
        type: Task.SHARE_TASK_LIST_SUCCEEDED,
        payload: {
          taskListId,
        },
      });
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Task.TASK_OPERATION_FAILED,
        payload: error,
      });
    }
  };

export const changeTaskStatus =
  (taskId: string, status: CraftsmanTaskStatus, role: ActorRole) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    //if there is a new tasklist without an Id
    const state = getState();
    if (state.task.id === EmptyGuid) return Promise.resolve();

    dispatch({
      type: Task.CHANGE_TASK_STATUS,
    });

    try {
      const result = await ApiService.post(`/api/task/status`, { taskId, status, role });

      dispatch({
        type: Task.CHANGE_TASK_STATUS_SUCCEEDED,
        payload: result,
      });
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Task.TASK_OPERATION_FAILED,
        payload: error,
      });
    }
  };

export const changeTaskListStatus =
  (taskListId: string, status: TaskListStatus) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    //if there is a new tasklist without an Id
    const state: AppState = getState();

    const [validationResult, message] = validateTaskList(state.task, true);
    if (status !== TaskListStatus.ReadyToSend && status !== TaskListStatus.New && !validationResult) {
      return Promise.reject(message);
    }

    if (state.task.id === EmptyGuid) return Promise.resolve();

    dispatch({
      type: Task.CHANGE_TASK_LIST_STATUS,
    });

    try {
      const response = await ApiService.post(`/api/task/tasklist/status`, {
        taskListId,
        status,
        changedBy: state.user.id,
      });

      dispatch({
        type: Task.CHANGE_TASK_LIST_STATUS_SUCCEEDED,
        payload: response,
      });

      await removeLocalData(LocalStorageItems.Task);

      return status;
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Task.TASK_OPERATION_FAILED,
        payload: error,
      });
    }
  };

export const saveTaskInfo =
  (taskInfo: CraftsmanTask) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({
      type: Task.ADD_TASK,
      payload: taskInfo,
    });
    dispatch({
      type: Task.SET_DISCOUNT,
      payload: 0,
    });
    dispatch({
      type: Task.CALCULATE_TOTAL,
    });

    await saveLocalState(dispatch, getState);
  };

const setDiscount = (value: number) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({
    type: Task.SET_DISCOUNT,
    payload: value,
  });
};

const checkLocalState = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  if (await getLocalData<TaskState>(LocalStorageItems.Task)) {
    dispatch({ type: Task.LOCAL_DATA_FOUND });
  }
};

const loadLocalState = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  const localData = await getLocalData<TaskState>(LocalStorageItems.Task);
  if (localData) {
    dispatch({ type: Task.LOAD_LOCAL_DATA, payload: localData });
  }
};

const saveLocalState = async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const state = getState().task;
  dispatch({ type: Task.UDPATE_LOCAL_DATA });
  await updateLocalData(LocalStorageItems.Task, state);
};

const clearTaskLocalState = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({
    type: Task.UDPATE_LOCAL_DATA,
  });
  await removeLocalData(LocalStorageItems.Task);
  dispatch({
    type: Task.CLEAR_TASK_LIST,
  });
};

const getSolutions =
  (query: string, filter: any, skip: number, take: number, sortField: string, sortDescending: boolean) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Task.GET_SOLUTIONS });
    try {
      const result = await ApiService.get<SearchResult<Solution>>(
        `/api/task/solutions?query=${query}&filter=${filter}&skip=${skip}&take=${take}&orderby=${
          sortField ?? ""
        }&desc=${sortDescending ?? false}`
      );
      dispatch({ type: Task.GET_SOLUTIONS_SUCCEEDED, payload: result || { results: [], count: 0 } });
      return result;
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch);
    }
  };
