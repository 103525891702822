import * as signalR from "@microsoft/signalr";
import { AnyAction, Middleware, MiddlewareAPI } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Common, User } from "../constants/actionTypes";
import { EmptyGuid } from "../constants/appConstants";
import { getTaskList, getTaskLists } from "../actions/tasklist/taskActions";
import { AppState } from "./store";

const initSignalR = (
  connection: signalR.HubConnection,
  store: MiddlewareAPI<ThunkDispatch<AppState, any, AnyAction>, AppState>
) => {
  connection
    .start()
    .then(() => {
      if (connection.connectionId) {
        store.dispatch({
          type: Common.START_SIGNALR_SUCCEEDED,
          payload: connection,
        });
        connection.on("NewMessages", (data: any) => {
          store.dispatch({
            type: User.NOTIFICATION_RECEIVED,
            payload: data,
          });
        });

        connection.on("updateTaskList", (data: string) => {
          const activeTaskList = store.getState().task;

          if (activeTaskList.id !== EmptyGuid) {
            store.dispatch(getTaskList(data));
            return;
          }
          const search = store.getState().task.search;
          store.dispatch(
            getTaskLists(
              search.query,
              search.status,
              search.skip,
              search.take,
              search.sortField,
              search.sortDescending
            )
          );
        });
      }
    })
    .catch((err) => console.error(err));
};

export const signalrMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action) => {
    const user = store.getState().user;
    const common = store.getState().common;
    switch (action.type) {
      case Common.START_SIGNALR:
        if (user) {
          setTimeout(() => {
            const transport =
              signalR.HttpTransportType.WebSockets |
              signalR.HttpTransportType.ServerSentEvents |
              signalR.HttpTransportType.LongPolling;
            const options: signalR.IHttpConnectionOptions = {
              transport,
              logger: signalR.LogLevel.Debug,
              accessTokenFactory: () => user.accessToken || "",
            };
            const hubConnection = new signalR.HubConnectionBuilder()
              .withUrl(process.env.REACT_APP_API_URL + "/messages", options)
              .withAutomaticReconnect()
              .configureLogging(signalR.LogLevel.Information)
              .build();

            hubConnection.onclose(() =>
              setTimeout(() => {
                if (user) initSignalR(hubConnection, store);
              })
            );

            initSignalR(hubConnection, store);
          }, parseInt(process.env.REACT_APP_SIGNALR_START_DELAY || "0"));
        }
        break;
      case Common.STOP_SIGNALR:
        if (common.hubConnection) {
          common.hubConnection
            .stop()
            .then(() =>
              store.dispatch({ type: Common.STOP_SIGNALR_SUCCEEDED })
            );
        }
        break;
      default:
        break;
    }

    return next(action);
  };
