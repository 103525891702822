import React, { useEffect, useReducer, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  Backdrop,
  BackdropProps,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StateAction } from "../../../interfaces/frontend";

const useStyles = makeStyles(() => ({  
  disabledColor: {
    "& input": {
      color: "black",
    },
  }, 
}));

interface TaskNoteState {
  taskNote: string;
}

export const initialState: TaskNoteState = {
  taskNote: "",
};

interface NoteDialogProps {
  taskNote?: string;
  open: boolean;
  handleClose: (accepted?: boolean, taskNote?: string) => void;
}

const reducer = (state: TaskNoteState, action: StateAction<TaskNoteState>) => {
  return { ...state, [action.type]: action.payload };
};

const NoteDialog = (props: NoteDialogProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const noteRef = useRef<HTMLTextAreaElement>(undefined!);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (props.taskNote) {
      dispatch({ type: "taskNote", payload: props.taskNote });
    }
    return () => {
      dispatch({ type: "taskNote", payload: initialState.taskNote });
    };
  }, [props.open, props.taskNote]);

  const handleClose = (accepted?: boolean) => {
    if (accepted) {
      props.handleClose(accepted, state.taskNote);
    } else {
      props.handleClose();
    }
    dispatch({ type: "taskNote", payload: initialState.taskNote });
  };

  const changeValue = (event: any) => {
    let value = event.target.value;
    dispatch({ type: "taskNote", payload: value });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open || false}
        onClose={() => props.handleClose()}
        BackdropComponent={BackdropIOSWorkaround}
      >
        <DialogContent>
          <Grid item container spacing={1}>
            <Grid item container xs={12}>
              <TextField
                defaultValue={props.taskNote}
                label="Note"
                value={state.taskNote || ""}
                onChange={(event) => changeValue(event)}
                margin="dense"
                multiline
                fullWidth
                variant="outlined"
                className={classes.disabledColor}
                inputRef={noteRef}
              />
            </Grid>

            <Grid container item justifyContent="center" spacing={1}>
              <Grid item>
                <Button onClick={() => props.handleClose()} color="primary" variant="contained">
                  Slet
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleClose(true)}
                  style={{ marginBottom: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={!state.taskNote || state.taskNote === props.taskNote}
                >
                  Gem
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NoteDialog;

const BackdropIOSWorkaround = (props: BackdropProps) => {
  const onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  return <Backdrop {...props} onTouchMove={onTouchMove} />;
};
