import React, { useContext, useEffect } from "react";
import { RegistrationContext, StepsComponentProps } from "../../RegistrationPage";
import { Grid, TextField, Autocomplete, CircularProgress, Typography, useTheme, useMediaQuery } from "@mui/material";
import DawaService from "../../../../services/AddressService";
import { StyledForm } from "../StyledComponents";
import NavigationButtons from "./NavigationButtons";

const Municipalities = (props: StepsComponentProps) => {
  const context = useContext(RegistrationContext);
  const [loading, setLoading] = React.useState(false);
  const [municipalCodes, setMunicipalCodes] = React.useState<number[]>([]);
  const [municipalities, setMunicipalities] = React.useState<any[]>([]);
  const [options, setOptions] = React.useState<any[]>([]);
  const [fee, setFee] = React.useState<number>(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const getAsync = async () => {
      if (context.municipalCodes?.length) {
        const values = await DawaService.getDawaMunicipalities(context.municipalCodes);
        setMunicipalities(values);
        setMunicipalCodes(context.municipalCodes);
      }
    };
    getAsync();
    return () => {};
  }, []);

  useEffect(() => {
    setFee((municipalCodes?.length || 0) * 800);
    return () => {};
  }
  , [municipalCodes?.length]);


  const nextStep = async () => {
    try {
      setLoading(true);
      context.municipalCodes = municipalCodes;
      context.municipalities = municipalities
        .map((x: { kommune: { navn: string } }) => x.kommune.navn)
        .sort((a, b) => (a > b ? 1 : -1))
        .join(", ");
      props.nextStep();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const autoCompleteChange = async (event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (value) {
      let values = await DawaService.getDawaMunicipality(value);
      if (values) {
        setOptions(values);
      }
    }
  };

  const changeMunicipalities = (event: any, value: any) => {
    if (value) {
      let items: number[] = value.map((x: { kommune: { kode: string } }) => parseInt(x.kommune.kode));
      setMunicipalities(value);
      setMunicipalCodes(items);
      setFee(items.length * 500);
    }
  };

  return (
    <StyledForm onSubmit={nextStep}>
      <Grid container item xs={12} sx={{ flex: 1, justifyContent: "center", alignItems: mobile ? "flex-start": "center", px: 1, boxSizing: "border-box" }}>
        <Grid item container xs={12}>
          <Grid item container justifyContent={"flex-end"}>
            <Grid item>
              <Typography>
                Månedligt gebyr ex moms.:{" "}
                {fee.toLocaleString("da-dk", {
                  currency: "DKK",
                  style: "currency",
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={municipalities}
              onChange={changeMunicipalities}
              defaultValue={municipalCodes}
              onInputChange={autoCompleteChange}
              noOptionsText="Ingen muligheder"
              getOptionLabel={(option) => parseInt(option.kommune.kode) + " " + option.kommune.navn}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kommuner"
                  margin="dense"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  type="text"
                  placeholder="Indtast navn på kommune"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <NavigationButtons prevStep={props.prevStep} loading={false} isLastStep={props.isLastStep} />
    </StyledForm>
  );
};

export default Municipalities;
