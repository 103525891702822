import React from "react";
import { Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

interface NavigationButtonsProps {
  prevStep?: () => void;
  isLastStep?: boolean;
  loading: boolean;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({ prevStep, isLastStep, loading }) => (
  <Grid item container justifyContent={"space-between"} sx={{ my: 2 }}>
    <Grid item px={1}>
      {!!prevStep && (
        <Button variant="contained" color="primary" onClick={prevStep}>
          Tilbage
        </Button>
      )}
    </Grid>
    <Grid item  px={1}>
      <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
        {isLastStep ? "Opret" : "Næste "}
      </LoadingButton>
    </Grid>
  </Grid>
);

export default NavigationButtons;
