import React, { forwardRef, useEffect } from "react";
import MainContainer from "../components/MainContainer";
import { Alert, Grid, Snackbar, TextField, TextFieldProps, Typography, styled } from "@mui/material";

import NavigationButtons from "./components/register/NavigationButtons";
import TermsAndConditions from "./components/register/TermsOfUse";
import { StyledForm } from "./components/StyledComponents";
import ExistingCompanyLookup from "./components/register/ExistingCompanyLookup";
import Profile from "./components/register/Profile";
import Municipalities from "./components/register/Municipalities";
import Competencies from "./components/register/Competencies";
import Team from "./components/register/Team";
import Summary from "./components/register/Summary";
import { Company, Craftsman } from "../../interfaces/models";
import { NIL } from "uuid";
import SuccessfulRegistration from "./components/register/SuccessfulRegistration";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";

export interface StepsComponentProps {
  prevStep: () => void | undefined;
  nextStep: (callback?: () => Promise<any>) => Promise<void> | undefined;
  isLastStep?: boolean;
}

enum Steps {
  Cvr,
  Profile,
  Municipalities,
  Competencies,
  Summary,
  Finish,
}

export interface RegistrationData extends Craftsman {
  team: string[];
  municipalities: string;
}

export const initialRegistrationData: RegistrationData = {
  team: [],
  municipalities: "",
  organization: "",
  cvr: "",
  email: "",
  phone: "",
  address: "",
  postCode: 0,
  city: "",
  municipalCodes: [],
  competencies: [],
  id: NIL,
  created: new Date(),
  modified: new Date(),
  mobile: "",
  firstName: "",
  lastName: "",
  travelRange: 0,
  rating: 0,
  greenTag: false,
};

export const RegistrationContext = React.createContext<RegistrationData>(initialRegistrationData);

const RegistrationPage = () => {
  const navigate = useNavigate();

  const [step, setStep] = React.useState(Steps.Cvr);
  const [error, setError] = React.useState("");

  const prevStep = () => {
    if (step === Steps.Cvr) {
      navigate("/login");
    }
    setStep(step - 1);
  };

  const nextStep = async () => {
    if (step === Steps.Summary) return;
    setStep(step + 1);
  };

  const finish = async (callback?: () => Promise<any>) => {
    try {
      callback && (await callback());
      setStep(Steps.Finish);
    } catch (e: any) {
      setError(e.message);
    }
  };

  return (
    <>
      <RegistrationContext.Provider value={initialRegistrationData}>
        <MainContainer title="Håndværkere">
          <Grid item container alignItems="flex-start" justifyContent="center">
            <Grid item container direction="column" alignContent="center" alignItems="center" sx={{ height: "100%" }}>
              <Grid item>
                <Typography paragraph variant="h6">
                  {"Registrering - " + StepNames[step] || ""}
                </Typography>
              </Grid>
              <Grid item container sx={{ flex: 1 }}>
                {step === Steps.Cvr && <ExistingCompanyLookup prevStep={prevStep} nextStep={nextStep} />}
                {step === Steps.Profile && <Profile nextStep={nextStep} prevStep={prevStep} />}
                {step === Steps.Municipalities && <Municipalities nextStep={nextStep} prevStep={prevStep} />}
                {step === Steps.Competencies && <Competencies nextStep={nextStep} prevStep={prevStep} />}
                {step === Steps.Summary && <Summary prevStep={prevStep} nextStep={finish} isLastStep />}
                {step === Steps.Finish && <SuccessfulRegistration />}
              </Grid>
            </Grid>
          </Grid>
        </MainContainer>
      </RegistrationContext.Provider>
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={!!error} onClose={() => setError("")}>
        <Alert onClose={() => setError("")} severity="error" variant="filled" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegistrationPage;

const StepNames = {
  [Steps.Cvr]: "Find din virksomhed",
  [Steps.Profile]: "Profil",
  [Steps.Municipalities]: "Kommuner",
  [Steps.Competencies]: "Kompetencer",
  [Steps.Summary]: "Oversigt",
  [Steps.Finish]: "Færdig",
};
