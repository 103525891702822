import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import GeneralDialog from "../../shared/GeneralDialog";
import { useTaskActions } from "../../actions/tasklist/taskActions";

const useStyles = makeStyles(() => ({
  container: {
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    padding: 30,
    flex: 1,
  },
}));

export const TaskListSelectorPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const taskActions = useTaskActions();

  const handleDialog = async (value?: boolean) => {
    if (value) {
      await taskActions.clearTaskLocalState();
      navigate("/tasklist/create", { replace: true });
    } else {
      navigate("/tasklist", { replace: true });
    }
  };

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item container xs={12} md={11} lg={9} xl={8} direction="column">
        <GeneralDialog
          open={true}
          dialogTitle={"Ønsker du at starte på en ny opgaveliste?"}
          okButtonText="Ja"
          handleClose={handleDialog}
        />
      </Grid>
    </Grid>
  );
};
