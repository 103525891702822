import React, { forwardRef, useState } from "react";
import { NumericFormat, NumericFormatProps, PatternFormat, PatternFormatProps } from "react-number-format";
import { TextField, TextFieldProps } from "@mui/material";

type ValueFormatProps = JSX.IntrinsicAttributes & {
  [x: string]: any;
  value?: any;
  id?: any;
  name?: any;
  inputRef?: any;
  onChange?: any;
  onBlur?: any;
};

function ValueFormat(props: ValueFormatProps) {
  const { inputRef, onChange, onBlur, ...other } = props;

  const [eventValue, setValue] = useState(props.value || "");
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        setValue(values.floatValue);
        onChange({
          target: {
            value: values.floatValue,
            id: props.id,
            name: props.name,
          },
          currentTarget: {
            value: values.floatValue,
            id: props.id,
            name: props.name,
          },
        });
      }}
      onFocus={() => {
        if (!eventValue) {
          setValue("");
        }
      }}
      onBlur={() =>
        onBlur({
          target: {
            value: eventValue,
            id: props.id,
            name: props.name,
          },
        })
      }
      allowNegative={false}
    />
  );
}

const NumberValueFormat = forwardRef<HTMLElement, ValueFormatProps>(function PhoneFormat(props, ref) {
  return <ValueFormat {...props} inputRef={ref} />;
});

const CurrencyFormat = forwardRef<HTMLElement, ValueFormatProps>(function CurrencyFormat(props, ref) {
  return (
    <ValueFormat
      {...props}
      inputRef={ref}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      // fixedDecimalScale
      //allowedDecimalSeparators={[","]}
      suffix=" Kr"
    />
  );
});

const PhoneFormat = forwardRef<HTMLElement, ValueFormatProps>(function PhoneFormat(props, ref) {
  return <PatternFormat {...props} format="+## ## ## ## ##" />;
});

const DistanceFormat = forwardRef<HTMLElement, ValueFormatProps>(function DistanceFormat(props, ref) {
  return <PatternFormat {...props} defaultValue={0} format="### Km" />;
});

const PostCodeFormat = forwardRef<HTMLElement, ValueFormatProps>(function PostCodeFormat(props: ValueFormatProps, ref) {
  return <PatternFormat {...props} format="####" />;
});

const HoursFormat = forwardRef<HTMLElement, ValueFormatProps>(function HoursFormat(props: ValueFormatProps, ref) {
  return (
    <ValueFormat
      {...props}
      inputRef={ref}
      thousandSeparator="."
      decimalSeparator=","
      isAllowed={(values: { value: string; floatValue: number }) => values.value === "" || values.floatValue < 100000}
    />
  );
});

//this section is to integrate NumberFormat with MaterialUI

export const NumberField = (props: JSX.IntrinsicAttributes & TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: props.value !== "" }}
      InputProps={{
        inputComponent: NumberValueFormat,
      }}
    />
  );
};

export const CurrencyField = (props: JSX.IntrinsicAttributes & TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: props.value !== "" }}
      InputProps={{
        inputComponent: CurrencyFormat,
      }}
    />
  );
};

export const PhoneField = (props: JSX.IntrinsicAttributes & TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: props.value !== "" }}
      InputProps={{
        inputComponent: PhoneFormat,
      }}
    />
  );
};

export const DistanceField = (props: JSX.IntrinsicAttributes & TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: props.value !== "" }}
      InputProps={{
        inputComponent: DistanceFormat,
      }}
    />
  );
};

export const PostCodeField = (props: JSX.IntrinsicAttributes & TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: props.value !== "" }}
      InputProps={{
        inputComponent: PostCodeFormat,
      }}
    />
  );
};

export const DaysField = (props: JSX.IntrinsicAttributes & TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: props.value !== "" }}
      InputProps={{
        inputComponent: HoursFormat,
      }}
    />
  );
};


export const FormattedTextField = forwardRef<HTMLElement, PatternFormatProps & JSX.IntrinsicAttributes & TextFieldProps>(function Formatted(props, ref) {
  return (
    <PatternFormat
      {...props}
      format={props.format}
      customInput={TextField}
      {...ref}
    />
  );
});

export const NumericTextField = forwardRef<HTMLElement, NumericFormatProps & JSX.IntrinsicAttributes & TextFieldProps>(function Formatted(props, ref) {
  return (
    <NumericFormat
      {...props}
      allowNegative={false}
      customInput={TextField}
      {...ref}
    />
  );
});
