import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import { useUserActions } from "./userActions";
import MainContainer from "../components/MainContainer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUser } from "../../common/hooks";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

type LoginFormData = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginFormData>({ defaultValues: { email: "", password: "" } });

  const [visibility, setVisibility] = useState(false);
  const toggleVisibility = () => setVisibility(!visibility);
  const [loading, setLoading] = useState(false);

  const user = useUser();
  const actions = useUserActions();
  const navigate = useNavigate();

  const login = async (data: LoginFormData) => {
    try {
      setLoading(true);
      await actions.login(data.email, data.password);
      reset({ email: "", password: "" });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainContainer title="Håndværkere">
      <Grid item container alignItems="flex-start" justifyContent="center">
        <Grid item container xs={11} sm={8} md={6} lg={5} direction="column" alignContent="center" alignItems="center">
          <Grid item>
            <Typography paragraph variant="h6">
              {"Log ind"}
            </Typography>
          </Grid>
          <Grid item container>
            <Card elevation={5} sx={{ width: "100%" }}>
              <CardContent>
                <form onSubmit={handleSubmit(login)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        id="email-input"
                        label="Indtast din email her"
                        type="email"
                        margin="dense"
                        autoComplete="email"
                        {...register("email", { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        id="password-input"
                        label="Kodeord"
                        type={visibility ? "text" : "password"}
                        autoComplete="current-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={toggleVisibility} edge="end" style={{ padding: 5 }} size="large">
                                {visibility ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...register("password", { required: true })}
                      />
                    </Grid>
                    <Grid item container justifyContent={"center"} sx={{ pt: 2 }}>
                      <Grid item xs={6} container>
                        <LoadingButton variant="contained" color="primary" type="submit" fullWidth loading={loading}>
                          Log ind
                        </LoadingButton>
                      </Grid>
                      {user.error && (
                        <Grid item xs={12} container justifyContent={"center"}>
                          <Grid item>
                            <Typography variant="body1" color="error">
                              Login mislykkedes
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </form>
                <Grid item container justifyContent={"center"} sx={{ pt: 2 }} spacing={2}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} container justifyContent={"center"}>
                    <Grid item>
                      <Typography variant="body1" alignSelf={"center"}>
                        Har du ikke oprettet dig endnu?
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="outlined" color="primary" onClick={() => navigate("/register")} fullWidth>
                      Register
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default LoginPage;
