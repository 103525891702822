import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { TermsContent } from "./TermsOfUse";

const TermsOfUseDialog = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  return (
    <Dialog fullScreen open={open}>
      <DialogContent>
        <TermsContent />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Luk
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsOfUseDialog;
