import React, { useEffect, useReducer } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  Theme,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { CraftsmanTypes } from "../../../constants/appConstants";
import { CompanyListItem, CraftsmanType, UpdateCompanyCommand } from "../../../interfaces/models";
import { useUserActions } from "../userActions";

type UpdateCompanyDialogProps = {
  open: boolean;
  handleClose: (created?: any) => void;
  company: CompanyListItem | undefined;
};

interface UpdateCompanyDialogState {
  competencies: CraftsmanType[];
}

const reducer = (
  state: UpdateCompanyDialogState,
  action: { type: keyof UpdateCompanyDialogState; payload: CraftsmanType[] }
) => {
  return { ...state, [action.type]: action.payload };
};

const initialState: UpdateCompanyDialogState = {
  competencies: [],
};

const UpdateCompanyDialog = (props: UpdateCompanyDialogProps) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (props.company) {
      dispatch({ type: "competencies", payload: props.company.competencies! });
    }
    return () => {};
  }, [props.company]);

  const actions = useUserActions();

  const changeCompetency = (event: any) => {
    dispatch({
      type: "competencies",
      payload: event.target.checked
        ? [...state.competencies!, parseInt(event.target.id)]
        : state.competencies!.filter((x) => x !== parseInt(event.target.id)),
    });
  };

  const updateCompany = () => {
    if (state.competencies.length && props.company) {
      const cmd: UpdateCompanyCommand = {
        ...props.company,
        companyId: props.company.id,
        travelRange: 0,
        pricePerHour: 0,
        ...state,
        cvr: "",
        webSite: "",
        email: "",
        phone: "",
        municipalCodes: [],
        employees: []
      };
      actions.updateCompany(cmd).then(() => props.handleClose());
    }
  };

  return (
    <Dialog fullScreen={fullScreen} maxWidth="md" open={props.open} onClose={() => props.handleClose()}>
      <DialogTitle>{props.company?.name}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Typography>Kompetencer</Typography>
        </Grid>
        <Grid container>
          {Object.entries(CraftsmanTypes).map(([id, value]) => (
            <Grid key={id} item sm={3} xs={6}>
              <StyledFormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    id={id + ""}
                    checked={state.competencies.indexOf(parseInt(id)) > -1}
                    onChange={changeCompetency}
                  />
                }
                label={value}
              />
            </Grid>
          ))}
        </Grid>
        {!state.competencies.length && (
          <Grid item xs={12}>
            <Typography style={{ color: "red" }}>Skal du vælge mindst en kompetence</Typography>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
              <Button variant="outlined" color="primary" onClick={() => props.handleClose()}>
          Annuller
        </Button>

              <Button variant="contained" color="primary" onClick={updateCompany}>
          Gem
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCompanyDialog;

const StyledFormControlLabel = withStyles((theme: Theme) => ({
  label: {
   fontSize: 14
 },
}))(FormControlLabel);