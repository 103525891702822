import React, { useEffect, useReducer, useRef } from "react";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
  ImageList,
  FormControlLabel,
  Button,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
  Backdrop,
  BackdropProps,
  List,
  ListItem,
  ListItemSecondaryAction,
} from "@mui/material";
import PictureListItem from "../../../shared/PictureListItem";
import { CraftsmanTypes, EmptyGuid } from "../../../constants/appConstants";
import { CurrencyField, DaysField } from "../../../shared/FormattedFields";
import ImageModal from "../../../shared/modals/ImageModal";
import { makeStyles } from "@mui/styles";
import {
  CraftsmanTask,
  CraftsmanTaskStatus,
  CraftsmanType,
  PictureRef,
  ActorRole,
  Solution,
} from "../../../interfaces/models";
import ChangePictureButton from "../../../shared/ChangePictureButton";
import { useUser } from "../../../common/hooks";
import { StateAction } from "../../../interfaces/frontend";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GeneralDialog from "../../../shared/GeneralDialog";
import { useTaskActions } from "../../../actions/tasklist/taskActions";
import CraftsmenTaskStatus from "../components/TaskStatusLabel";
import { NIL, v4 as uuid } from "uuid";
import SolutionsDialog from "./SolutionsDialog";

const useStyles = makeStyles(() => ({
  control: {
    minWidth: 110,
  },
  pictureList: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  additionalInfo: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  additionalContol: {
    minWidth: 200,
  },
  changed: {
    fontWeight: "bold",
  },
  disabledColor: {
    "& input": {
      color: "black",
    },
  },
  scrolled: {
    scrollMargin: "50px 0 0 0",
    width: "100%",
  },
  imageList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
}));

interface TaskState extends CraftsmanTask {
  picShown: boolean;
  changed: boolean;
  activePic: string;
  showGetPicture: boolean;
  focusOnInput: Boolean;
  disableChange: boolean;
  setOpenConfirmDialog: boolean;
  setOpenRemoveImageDialog: boolean;
  openSolutionsDialog: boolean;
}

interface StateActionType extends TaskState {
  init: undefined;
}

export const initialState: TaskState = {
  estimatedMaterialPrice: 0,
  skipRepair: false,
  status: CraftsmanTaskStatus.New,
  created: new Date(),
  modified: new Date(),
  id: EmptyGuid,
  code: 0,
  estimatedTime: 0,
  estimatedPrice: 0,
  craftsmen: [],
  picShown: false,
  changed: false,
  activePic: "",
  pricePerHour: 0,
  changedBy: EmptyGuid,
  deadline: new Date(),
  craftsmanCompanyId: NIL,
  showGetPicture: false,
  focusOnInput: false,
  disableChange: false,
  statusChanged: new Date(),
  actorRole: ActorRole.Craftsman,
  setOpenConfirmDialog: false,
  setOpenRemoveImageDialog: false,
  header: "",
  craftsmanNote: "",
  pictures: [],
  solutions: [],
  openSolutionsDialog: false,
  descriptions: []
};

interface TaskDialogProps {
  data?: CraftsmanTask;
  showFinishCheckbox: boolean;
  draft: boolean;
  showGetPicture: boolean;
  open: boolean;
  handleClose: (accepted?: boolean, taskInfo?: CraftsmanTask) => void;
}

const reducer = (state: TaskState, action: StateAction<StateActionType>) => {
  switch (action.type) {
    case "init":
      return {
        ...action.payload,
      };
    default:
      return { ...state, [action.type]: action.payload, changed: true };
  }
};

const TaskDialog = (props: TaskDialogProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const taskActions = useTaskActions();
  const user = useUser();
  const noteRef = useRef<HTMLTextAreaElement>(undefined!);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClose = (accepted?: boolean) => {
    if (accepted) {
      const taskId = state.id !== EmptyGuid ? state.id : uuid();
      const pics = state.pictures.map((x: PictureRef) => ({ ...x, itemId: taskId }));

      props.handleClose(accepted, {
        pictures: pics,
        craftsmen: state.craftsmen,
        craftsmanNote: state.craftsmanNote,
        header: state.header,
        id: taskId,
        actorRole: state.actorRole,
        changedBy: state.changedBy,
        code: state.code,
        craftsmanCompanyId: state.craftsmanCompanyId,
        created: state.created,
        estimatedTime: state.estimatedTime,
        estimatedPrice: state.estimatedPrice,
        estimatedMaterialPrice: state.estimatedMaterialPrice,
        pricePerHour: state.pricePerHour,
        skipRepair: state.skipRepair,
        status: state.status,
        modified: state.modified,
        deadline: state.deadline,
        statusChanged: state.statusChanged,
        solutions: state.solutions,
        descriptions: state.descriptions,
      });
    } else {
      props.handleClose();
    }
    dispatch({ type: "init", payload: initialState });
  };

  //Switch off showing a camera first when creating a new task according to Heidi's request
  // useEffect(() => {
  //   dispatch({ type: "showGetPicture", payload: props.showGetPicture });
  //   return () => {};
  // }, [props.showGetPicture]);

  const changeValue = (event: any, key: keyof CraftsmanTask) => {
    let value = event.target.value;
    dispatch({ type: key, payload: value });
  };

  useEffect(() => {
    if (props.data) {
      const newState: TaskState = { ...state, ...props.data };
      if (newState && !newState.pricePerHour) {
        newState.pricePerHour = user.pricePerHour;
      }
      dispatch({ type: "init", payload: newState });
      return () => {
        dispatch({ type: "init", payload: initialState });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    const price = state.estimatedTime * state.pricePerHour;
    dispatch({ type: "estimatedPrice", payload: price });
  }, [state.estimatedTime, state.pricePerHour]);

  const getDisabledStatus = () => {
    switch (state.status) {
      case CraftsmanTaskStatus.New:
        return false;
      case CraftsmanTaskStatus.InProgress:
      case CraftsmanTaskStatus.Done:
      case CraftsmanTaskStatus.Shared:
        return true;
      default:
        return true;
    }
  };
  const openConfirmDialog = () => {
    dispatch({ type: "setOpenConfirmDialog", payload: true });
  };
  const removeImage = () => {
    dispatch({ type: "setOpenRemoveImageDialog", payload: true });
  };

  
  const showPic = (pic: PictureRef) => {
    dispatch({ type: "picShown", payload: true });
    dispatch({ type: "activePic", payload: pic });
  };

  const closePic = () => {
    dispatch({ type: "picShown", payload: false });
  };

  const removePic = () => {
    const pictures = state.pictures.filter((p: PictureRef) => p.id !== state.activePic.id);
    dispatch({ type: "picShown", payload: false });
    dispatch({ type: "pictures", payload: pictures });
  };

  const changeDeadline = (value: Date | null) => {
    dispatch({ type: "deadline", payload: value });
  };

  const handleCloseConfirmDialog = async (accepted: boolean | undefined) => {
    dispatch({ type: "setOpenConfirmDialog", payload: false });
    if (accepted) {
      await taskActions.removeTask(state.id);
    }
    handleClose();
  };
  const handleRemoveImageDialog = async (accepted: boolean | undefined) => {
    dispatch({ type: "setOpenRemoveImageDialog", payload: false });
    if (accepted) {
      removePic();
    }
    closePic();
  };

  const changeStatus = () => {
    // Can be extended relying on user.Role
    const actorRole = ActorRole.Craftsman;

    if (props.data && props.data.status !== CraftsmanTaskStatus.Done)
      taskActions.changeTaskStatus(props.data.id, CraftsmanTaskStatus.Done, actorRole);
    if (props.data && props.data.status === CraftsmanTaskStatus.Done) {
      taskActions.changeTaskStatus(props.data.id, CraftsmanTaskStatus.InProgress, actorRole);
    }
  };

  const changeCraftsmen = (event: SelectChangeEvent<CraftsmanType>) => {
    const val = [event.target.value];
    dispatch({ type: "craftsmen", payload: val });
  };

  const getPicture = (picture: PictureRef) => {
    const pictures = state.pictures?.length ? [...state.pictures] : [];
    pictures.push(picture);

    dispatch({
      type: "pictures",
      payload: pictures,
    });
    if (noteRef.current) {
      noteRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        noteRef.current.focus();
        noteRef.current.click();
        noteRef.current.dispatchEvent(
          new KeyboardEvent("keypress", {
            key: "Enter",
          })
        );
      }, 100);
    }
    dispatch({ type: "focusOnInput", payload: true });
  };

  const openSolutions = () => {
    dispatch({ type: "openSolutionsDialog", payload: true });
  };

  const addSolution = (solution: Solution) => {
    if(state.solutions.some((s: Solution) => s.id === solution.id)) return;
    const solutions = [...state.solutions];
    solutions.push(solution);
    dispatch({ type: "solutions", payload: solutions });
  };

  const removeSolution = (id: string) => {
    const solutions = state.solutions.filter((s: Solution) => s.id !== id);
    dispatch({ type: "solutions", payload: solutions });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open || false}
        onClose={() => props.handleClose()}
        BackdropComponent={BackdropIOSWorkaround}
      >
        <DialogContent>
          <Grid item container spacing={1}>
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item>
                <Typography gutterBottom variant="h4">
                  Opgave
                </Typography>
                <CraftsmenTaskStatus data={state.status} />
              </Grid>
              <Grid item xs={5} container direction="column" alignItems="flex-end">
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Deadline"
                      disabled={getDisabledStatus()}
                      className={classes.disabledColor}
                      value={new Date(state.deadline) || new Date()}
                      onChange={(value) => changeDeadline(value)}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            {/* {!props.draft && ( */}
            <Grid item xs={12}>
              <TextField
                error={!state.header}
                label="Overskrift"
                value={state.header || ""}
                onChange={(event) => changeValue(event, "header")}
                margin="dense"
                fullWidth
                disabled={getDisabledStatus()}
                className={classes.disabledColor}
              />
            </Grid>
            {/* )} */}
            <Grid container item className={classes.pictureList}>
              <Grid item container justifyContent="flex-end">
                <ImageList className={classes.imageList}>
                  {!!state.pictures?.length &&
                    state.pictures.map((pic: PictureRef) => (
                      <PictureListItem
                        key={pic.id}
                        id={pic.id}
                        source={pic.url || pic.data}
                        width={100}
                        height={100}
                        onClick={() => showPic(pic)}
                      />
                    ))}
                  {!getDisabledStatus() && (
                    <ChangePictureButton
                      simulateClick={state.showGetPicture}
                      objectId={EmptyGuid}
                      getPicture={getPicture}
                      itemId={state.id}
                    />
                  )}
                </ImageList>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Note"
                value={state.craftsmanNote || ""}
                onChange={(event) => changeValue(event, "craftsmanNote")}
                margin="dense"
                multiline
                fullWidth
                variant="outlined"
                disabled={getDisabledStatus()}
                className={classes.disabledColor}
                inputRef={noteRef}
              />
            </Grid>
            <Grid container item className={classes.additionalInfo} spacing={1}>
              {!props.draft && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Håndværkere</InputLabel>
                      <Select
                        label="Håndværkere"
                        error={!(state.craftsmen && state.craftsmen.length)}
                        //multiple
                        disabled={getDisabledStatus()}
                        className={classes.additionalContol}
                        value={state.craftsmen && state.craftsmen.length ? state.craftsmen[0] : ""}
                        onChange={(event) => changeCraftsmen(event)}
                        autoWidth
                        renderValue={(val: CraftsmanType) => CraftsmanTypes[val]}
                        inputProps={{
                          // id: "craftsmen",
                          className: classes.disabledColor,
                        }}
                      >
                        {!!user.team.members?.length &&
                          user.team.members
                            .flatMap((m) => (m.competencies ? m.competencies : []))
                            .filter((val, i, arr) => arr.indexOf(val) === i)
                            .sort((a, b) => (CraftsmanTypes[a] > CraftsmanTypes[b] ? 1 : -1))
                            .map((item) => (
                              <MenuItem key={item} value={item}>
                                {CraftsmanTypes[item]}
                              </MenuItem>
                            ))}
                        <MenuItem key={CraftsmanType.Other} value={CraftsmanType.Other}>
                          {CraftsmanTypes[CraftsmanType.Other]}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Virksomhed</InputLabel>
                      <Select
                        label="Virksomhed"
                        error={!state.craftsmanCompanyId}
                        disabled={getDisabledStatus() || !state.craftsmen?.length}
                        className={`${classes.additionalContol} ${classes.disabledColor}`}
                        value={state.craftsmanCompanyId}
                        onChange={(event) => changeValue(event, "craftsmanCompanyId")}
                        autoWidth
                        // inputProps={{
                        //   id: "company",
                        // }}
                      >
                        {user.team.members?.filter((x) =>
                          x.competencies?.some((i) =>
                            i === (state.craftsmen && state.craftsmen.length) ? state.craftsmen[0] : ""
                          )
                        ).length ? (
                          user.team.members
                            ?.filter((x) => x.competencies?.some((i) => i === state.craftsmen[0]))
                            .map((x) => (
                              <MenuItem key={x.id} value={x.id}>
                                {x.name}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem value={EmptyGuid}>Andet</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DaysField
                      //error={!state.estimatedTime}
                      label="Anslåede timer"
                      value={state.estimatedTime || ""}
                      onChange={(event) => changeValue(event, "estimatedTime")}
                      fullWidth
                      disabled={getDisabledStatus()}
                      className={classes.disabledColor}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      label="Anslået arbejdsløn ekskl. moms"
                      value={state.estimatedPrice || ""}
                      disabled
                      fullWidth
                      className={classes.disabledColor}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      style={{ color: "black" }}
                      label="Gennemsnitlig timepris ekskl. moms"
                      value={state.pricePerHour}
                      onChange={(event) => changeValue(event, "pricePerHour")}
                      disabled={getDisabledStatus()}
                      className={classes.disabledColor}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      label="Anslået arbejdsløn inkl. moms"
                      value={parseFloat(state.estimatedPrice) + parseFloat(state.estimatedPrice) * 0.25}
                      disabled
                      className={classes.disabledColor}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      //error={!(state.estimatedMaterialPrice * 1)}
                      label="Anslået materialepris ekskl. moms"
                      value={state.estimatedMaterialPrice || ""}
                      onChange={(event) => changeValue(event, "estimatedMaterialPrice")}
                      fullWidth
                      disabled={getDisabledStatus()}
                      className={classes.disabledColor}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      label="Anslået materialepris inkl. moms"
                      value={parseFloat(state.estimatedMaterialPrice) + parseFloat(state.estimatedMaterialPrice) * 0.25}
                      disabled
                      className={classes.disabledColor}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button onClick={openSolutions} color="primary" variant="contained">
                      Solutions
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {state.solutions.map((solution: Solution) => (
                        <ListItem key={solution.id} sx={{ mt: 1}}>
                          <Typography variant="body2">
                            {solution.productName} {solution.productCode} {solution.vendor?.name} {solution.unitPrice}
                          </Typography>
                          <ListItemSecondaryAction>
                            <Button onClick={() => removeSolution(solution.id)} color="primary" variant="contained">
                              Slet
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      label="Anslået total pris inkl. moms"
                      value={
                        parseFloat(state.estimatedPrice) +
                        parseFloat(state.estimatedMaterialPrice) +
                        parseFloat(state.solutions.reduce((a: number,c: Solution) => a + c.unitPrice, 0)) +
                        (parseFloat(state.estimatedPrice) + parseFloat(state.estimatedMaterialPrice) +  parseFloat(state.solutions.reduce((a: number,c: Solution) => a + c.unitPrice, 0))) * 0.25
                      }
                      disabled
                      className={classes.disabledColor}
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {props.showFinishCheckbox && (
                <Grid container direction="column" item xs={12} justifyContent="center" alignItems="flex-end">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.status === CraftsmanTaskStatus.Done || false}
                          name="hidden"
                          onChange={changeStatus}
                        />
                      }
                      label="Færdig"
                    />
                  </Grid>
                  {/* {new Date(props.data?.statusChanged).getFullYear() > 1900 && (
                <Grid item>
                  <Typography variant="body2" style={{ marginRight: 16 }}>
                    Ændret til "{getCraftsmanStatusText(props.data?.status)}" af {getActorRoleName(props.data?.actorRole)} den{" "}
                    {format(new Date(props.data?.statusChanged), "dd MMM yyyy 'kl.' HH:mm", { locale: daLocale })}
                  </Typography>
                </Grid>
              )} */}
                </Grid>
              )}
            </Grid>
            <Grid container item justifyContent="center" spacing={1}>
              <Grid item>
                <Button onClick={openConfirmDialog} color="primary" variant="contained" disabled={getDisabledStatus()}>
                  Slet
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleClose(true)}
                  style={{ marginBottom: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={!state.header}
                >
                  Gem
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ImageModal
        shown={state.picShown}
        remove={
          state.status === CraftsmanTaskStatus.Done || props.data?.status === CraftsmanTaskStatus.Cancelled
            ? undefined
            : removeImage
        }
        handleClose={closePic}
        source={state.activePic ? state.activePic.url || state.activePic.data : ""}
      />

      <GeneralDialog
        open={state.setOpenRemoveImageDialog || false}
        dialogTitle={"Vil du fjerne dette billede?"}
        handleClose={handleRemoveImageDialog}
      />
      <GeneralDialog
        open={state.setOpenConfirmDialog || false}
        dialogTitle={"Vil du slette denne opgave?"}
        handleClose={handleCloseConfirmDialog}
      />
      <SolutionsDialog
        open={state.openSolutionsDialog}
        onClose={() => dispatch({ type: "openSolutionsDialog", payload: false })}
        addSolution={(solution: Solution) => addSolution(solution)}
      />
    </>
  );
};

export default TaskDialog;

const BackdropIOSWorkaround = (props: BackdropProps) => {
  const onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  return <Backdrop {...props} onTouchMove={onTouchMove} />;
};
