import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorDialog from "./shared/ErrorDialog";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import { Box, CssBaseline, createTheme } from "@mui/material";

import "./App.scss";
import LoginPage from "./pages/User/LoginPage";
import { useUserActions } from "./pages/User/userActions";
import { UserRole } from "./interfaces/models";
import RatingPage from "./pages/Rating/RatingPage";
import { TaskListSelectorPage } from "./pages/Tasklist/TaskListSelectorPage";
import { RegularCustomersPage } from "./pages/Customers/RegularCustomersPage";
import { CreateTaskListPage } from "./pages/Tasklist/CreateTaskListPage";
import { TaskListPage } from "./pages/Tasklist/TaskListPage";
import { useUser } from "./common/hooks";
import SideMenu from "./pages/components/SideMenu";
import { useCommonState } from "./common/commonReducer";
import { DeafultRedirect, ProtectedRoutes } from "./helpers/RouterExtensions";
import { TaskListTablePage } from "./pages/Tasklist/TaskListTablePage";
import CraftsmanProfilePage from "./pages/User/CraftsmanProfilePage";
import { useCommonActions } from "./common/commonActions";
import RegistrationPage from "./pages/User/RegistrationPage";
import TermsAndConditions from "./pages/User/components/register/TermsOfUse";
import AdminPanel from "./admin/Admin";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const user = useUser();
  const userActions = useUserActions();
  const common = useCommonState();
  const commonActions = useCommonActions();

  useEffect(() => {
    if (user.localUser && !user.authorized) {
      userActions.authenticateLocal();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.authorized, user.role]);

  return (
    <Box className="App" style={{ WebkitOverflowScrolling: "touch", overflowY: "hidden" }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AppThemeV2}>
          <CssBaseline />
          {user.authorized ? (
            <>
              {user.role === UserRole.Craftsman && (
                <SideMenu>
                  <Routes>
                    <Route element={<ProtectedRoutes redirectTo={"/login"} />}>
                      <Route path="/tasklist/:taskListId" element={<TaskListPage />} />
                      <Route path="/tasklist/" element={<TaskListTablePage />} />
                      <Route path="/tasklist/create/choose" element={<TaskListSelectorPage />} />
                      <Route path="/tasklist/create" element={<CreateTaskListPage />} />
                      <Route path="/rating" element={<RatingPage />} />
                      <Route path="/customers" element={<RegularCustomersPage />} />
                      <Route path="/profile" element={<CraftsmanProfilePage />} />
                      <Route path="*" element={<DeafultRedirect default="/tasklist" />} />
                    </Route>
                  </Routes>
                </SideMenu>
              )}
              {[ UserRole.Administrator, UserRole.Root].includes(user.role) && (
                <Routes>
                  <Route element={<ProtectedRoutes redirectTo={"/"} />}>
                    <Route path="/admin/*" element={<AdminPanel />} />
                    <Route path="*" element={<DeafultRedirect default="/admin" />} />
                  </Route>
                </Routes>
              )}
            </>
          ) : (
            <Routes>
              <Route path="/privacy" element={<TermsAndConditions />} />
              <Route path="/register" element={<RegistrationPage />} />
              <Route path="*" element={<LoginPage />} />
            </Routes>
          )}
          <ErrorDialog open={common.showError} error={common.error} handleClose={commonActions.clearError} />
        </ThemeProvider>
      </StyledEngineProvider>
    </Box>
  );
};

export default App;

let themeV2 = createTheme({
  palette: {
    primary: {
      main: "#32C111",
      contrastText: "#fff",
    },
    divider: "#E4E4E7",
    secondary: {
      main: "#1F2041",
      contrastText: "#fff",
    },
    error: {
      main: "#FF7C39",
      light: "#FFD588",
    },
    info: {
      main: "#8000FF",
      light: "#00C0FF",
    },
  },
  typography: {
    fontFamily: "'Quicksand', sans-serif",
    h1: {
      fontSize: 33,
    },
    h2: {
      fontSize: 28,
      fontWeight: 300,
    },
    h3: {
      fontSize: 24,
      fontWeight: 800,
    },
    h4: {
      fontSize: 19,
      fontWeight: 800,
    },
    h5: {
      fontSize: 17,
      fontWeight: 500,
      color: "rgba(31, 32, 65, 0.75)",
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
      // color: "rgba(31, 32, 65, 0.75)"
    },
    body2: {
      fontSize: 12,
      fontWeight: 800,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
    },
  },
});

const AppThemeV2 = createTheme(themeV2, {
  components: {
    MuiTypography: {
      colorTextSecondary: {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButton: {
      props: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 20,
          fontSize: 14,
          minWidth: 80,
          textTransform: "none",
          ":disabled": {
            backgroundColor: "rgb(230 230 230)",
          },
        },
        contained: {
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        outlined: {
          fontWeight: 600,
        },
      },
      label: {
        textTransform: "none",
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 800,
          textTransform: "uppercase",
          color: "rgba(31, 32, 65, 1)",
        },
      },
    },
  },
});
