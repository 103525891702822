import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";

const SuccessfulRegistration = () => {
  const navigate = useNavigate();
  return (
    <Grid
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        item
        container
        xs={12}
        sx={{ flex: 1, px: 1, boxSizing: "border-box", alignItems: "center", justifyContent: "center" }}
      >
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Grid item>
              <CheckCircle sx={{ fontSize: 60, color: "green" }} />
            </Grid>
            <Grid item>
              <Typography variant="h4">Du er nu registreret</Typography>
              <Typography variant="body1">Vi vil nu sende dig dit login og password</Typography>
            </Grid>
          </Grid>
          <Grid item m={2}>
            <Button variant="contained" color="primary" onClick={() => navigate("/login", { replace: true })}>
              Gå til login
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SuccessfulRegistration;
