import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Divider,
  Box,
  Menu,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../common/hooks";
import { useUserActions } from "../../pages/User/userActions";
import ElevationScroll from "../../shared/ElevationScroll";
import GeneralDialog from "../../shared/GeneralDialog";

type AppToolBarProps = {
  handleDrawerToggle?: (() => void) | false;
  goBack?: (() => void) | false;
  children?: React.ReactNode;
  title?: string;
  buttons?: React.ReactNode;
  light?: boolean;
  logo?: boolean;
  loading?: boolean;
};

const ToolBar = (props: AppToolBarProps) => {
  //const classes = useStyles();
  const { light, logo, loading } = props;
  const [openLogout, setOpenLogoutDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const user = useUser();
  const userActions = useUserActions();
  const location = useLocation();
  const navigate = useNavigate();

  const goToProfile = () => {
    setAnchorEl(null);
    navigate("/" + location.pathname.split("/")[1] + "/profile");
  };

  const openLogoutDialog = () => {
    setAnchorEl(null);
    setOpenLogoutDialog(true);
  };

  const logout = async (accepted?: boolean) => {
    setOpenLogoutDialog(false);
    if (accepted) {
      await userActions.logout();
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div style={{ marginTop: 8 }}></div>

      <ElevationScroll>
        <StyledAppBar
          style={{ left: 0, width: "100%" }}
          color={light ? "default" : "secondary"}
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
        >
          <StyledToolbar disableGutters>
            <Grid direction="row" justifyContent="flex-start" item container alignItems="center" xs={4} sm={6}>
              {!!props.handleDrawerToggle && (
                <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle} size="large">
                  <MenuIcon />
                </IconButton>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {props.children}
              </Box>
              {!props.children && (
                <Box
                  sx={{
                    padding: "0 15px 5px",
                    fontSize: 12,
                    "@media (min-width: 600px)": {
                      flexDirection: "row",
                      fontSize: 14,
                      padding: 10,
                    },
                  }}
                >
                  <Typography variant="body2">{title ? title : "Service eftersyn"}</Typography>
                </Box>
              )}
            </Grid>
            <Grid container item xs={8} sm={6} alignItems="center" justifyContent="flex-end">
              {props.buttons && <>{props.buttons}</>}
              {user.authorized && (
                <>
                  <Button onClick={openMenu}>
                    {isXs ? (
                      <PersonOutlineIcon />
                    ) : (
                      <Typography variant="body2" color="inherit">
                        {user ? user.firstName + " " + user.lastName : "Menu"}
                      </Typography>
                    )}
                    <ArrowDropDownIcon color="inherit" />
                  </Button>

                  <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleMenuClose}>
                    <MenuItem onClick={openLogoutDialog}>Log af</MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </StyledToolbar>
          {loading && <LinearProgress color="secondary" />}
        </StyledAppBar>
      </ElevationScroll>
      <GeneralDialog
        open={openLogout}
        dialogTitle={"Ønsker du at logge af?"}
        dialogText={"Husk at gemme eventuelle ændringer inden."}
        handleClose={logout}
      />
    </>
  );
};

export default ToolBar;

const StyledAppBar = withStyles({
  //   colorDefault: {
  //     background: "#fafafa",
  //   },
})(AppBar);

const StyledToolbar = withStyles({
  regular: {
    minHeight: 0,
  },
})(Toolbar);
