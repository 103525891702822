import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { format } from "date-fns";
import { getStatusText } from "../../constants/appConstants";
import { TaskListStatus } from "../../interfaces/models";
import DataTable from "../../shared/DataTable";

import Footer from "../../shared/Footer";
import { useTaskActions, useTaskState } from "../../actions/tasklist/taskActions";
import AppToolbarV2 from "../../shared/AppToolbar";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minHeight: "calc(100vh - 64px)",
      WebkitOverflowScrolling: "touch",
      maxWidth: "100%",
      padding: 0,
      margin: 0,
    },
    content: {
      flex: "1 0 auto",
    },
  })
);

export const TaskListTablePage = () => {
  const taskActions = useTaskActions();
  const taskState = useTaskState();
  const theme = useTheme();
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const getTaskLists = (
    query: string,
    filter: TaskListStatus,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => {
    return taskActions.getTaskLists(
      query,
      filter,
      skip,
      take,
      sortField,
      sortDescending
    );
  };

  const getTaskList = (id: string) => {
    //taskActions.getTaskList(id);
    navigate(`/tasklist/${id}`);
  };

  return (
    <>
      <AppToolbarV2 fullWidth={false}/>
      <Grid container>
        <Grid
          direction="column"
          justifyContent="flex-start"
          spacing={3}
          alignItems="center"
          container
          className={classes.container}
        >
          <Grid container className={classes.content}>
            <Container disableGutters>
              <DataTable
                data={taskState.taskLists.map((x) => {
                  if (x.status === TaskListStatus.Shared) {
                    return { ...x, highligted: true };
                  }
                  return x;
                })}
                getData={getTaskLists}
                columns={[
                  { label: "Adresse", key: "address" },
                  {
                    label: "Kunde",
                    key: "name",
                    sortKey: "owner",
                    hidden: isXs,
                  },
                  {
                    label: "Oprettet",
                    key: "created",
                    transform: (val) => format(new Date(val), "dd.MM.yyyy"),
                  },
                  {
                    label: "Status",
                    key: "status",
                    transform: (val) => getStatusText(val, isXs),
                  },
                  {
                    label: "Aktivitet",
                    key: "modified",
                    transform: (val) => {
                      const date = new Date(val);

                      if (date.getFullYear() > 2000)
                        return format(new Date(val), "dd.MM.yyyy");
                    },
                  },
                ]}
                textFilter
                dropdownFilterValues={[
                  {
                    id: TaskListStatus.None,
                    value: getStatusText(TaskListStatus.None),
                  },
                  {
                    id: TaskListStatus.New,
                    value: getStatusText(TaskListStatus.New),
                  },
                  {
                    id: TaskListStatus.Shared,
                    value: getStatusText(TaskListStatus.Shared),
                  },
                  {
                    id: TaskListStatus.Received,
                    value: getStatusText(TaskListStatus.Received),
                  },
                  {
                    id: TaskListStatus.InProgress,
                    value: getStatusText(TaskListStatus.InProgress),
                  },
                  {
                    id: TaskListStatus.Finished,
                    value: getStatusText(TaskListStatus.Finished),
                  },
                  {
                    id: TaskListStatus.Cancelled,
                    value: getStatusText(TaskListStatus.Cancelled),
                  },
                ]}
                dropdownFilterTitle="Status"
                count={taskState.count}
                itemsPerPage={20}
                onRowClick={getTaskList}
              />
            </Container>
          </Grid>
          {/* <Footer /> */}
        </Grid>
      </Grid>
    </>
  );
};
