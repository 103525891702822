import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const TermsContent = () => {
  const rawHtml = `<div class="row">
</div>
<style>
  .row {
    display: table;
    padding: 10px;
    width: 100%;
  }
  @media (max-width: 768px) {
    .cell, .cell30, .cell70 {
      width: 100%;
      display: block;
    }
  }
  .cell {
    width: 8%;
    display: table-cell;
    height: 75px;
  }
</style>
<div id="iv3m">
  <h1 style="text-align:justify; margin-top:16px; margin-bottom:4px">
    <span style="font-size:14pt"><span style="line-height:120%"><span style="font-family:Arial,sans-serif"><span style="font-weight:bold">Brugervilk&aring;r for h&aring;ndv&aelig;rkere</span></span></span></span>
  </h1>
  <p style="text-align:justify">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><b>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Generelt</span></span></span>
      </b></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Nedenfor har vi beskrevet de retningslinjer vi forventer du f&oslash;lger n&aring;r Du p&aring;tager Dig opgaver du f&aring;r gennem myhouse som h&aring;ndv&aelig;rker. I det efterf&oslash;lgende afsnit har vi beskrevet vores forpligtigelser i relation til at opbevare dine data. </span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><b>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Retningslinjer for samarbejdet</span></span></span>
      </b><br />
      <u>
        <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Valg af omr&aring;de</span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Du v&aelig;lger det geografiske omr&aring;de du &oslash;nsker at d&aelig;kke. Den f&oslash;rste kommune indg&aring;r i basispakken. Efterf&oslash;lgende kommuner koster yderlig kr. 800 per m&aring;ned ex moms. Du modtager en opkr&aelig;vning hver m&aring;ned med mindre andet aftales.</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Valg af fag om&aring;der</span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Du krydser de fagomr&aring;der af du har kompetencer indenfor og derfor &oslash;nsker at kunne give tilbud p&aring;. </span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Det du f&aring;r af os</span></span></span>
      </u><br />
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Vores bygge inspekt&oslash;rer udfylder elektronisk en rapport som vi kalder den gr&oslash;nne vedligeholdelsesplan den arkiveres digitalt under Min servicebog i myhouse.dk. Boligejeren tager dern&aelig;st stilling, ofte sammen med banken hvad de &oslash;nsker at udf&oslash;re af opgaver. Disse opgaver sendes direkte til h&aring;ndv&aelig;rkeren efter f&oslash;lgende regler:</span></span></span></span></span></span>
    <br />
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Prioritet 1 - H&aring;ndv&aelig;rkere der allerede er tilknyttet ejendommen og som har fagekspertisen</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Prioritet 2 &ndash; Lokale h&aring;ndv&aelig;rkere, der har det gr&oslash;nne k&oslash;rekort og som har fagekspertisen</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Prioritet 3 &ndash; Lokale h&aring;ndv&aelig;rkere, som har fagekspertisen</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Prioritet 4 &ndash; H&aring;ndv&aelig;rkere som har fagekspertisen</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Vores krav til dig </span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">&Oslash;nsker du at komme i betragtning skal du inden for samme 24 timer kontakte kunden for enten at aftale et m&oslash;de p&aring; ejendommen eller aftale at du vil byde direkte uden bes&oslash;g og hvorn&aring;r kunden kan forvente at modtage et tilbud fra dig senest.</span></span></span></span></span></span></span>
    <br />
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Du skal senest tre dage efter bes&oslash;g p&aring; ejendommen sende kunden et tilbud som d&aelig;kker alle aftalte reparationer.</span></span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Derudover har vi f&oslash;lgende krav:</span></span></span></span></span></span></span>
    <br />
    &nbsp;
  </p>
  <ol>
    <li style="text-align:left">
      <span style="font-size:10.5pt"><span style="color:black"><span style="tab-stops:list 36.0pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Du eller din virksomhed er Dansk registreret </span></span></span></span></span></span></span></span>
    </li>
    <li style="text-align:left">
      <span style="font-size:10.5pt"><span style="color:black"><span style="tab-stops:list 36.0pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Du eller en af dine medarbejdere taler flydende dansk</span></span></span></span></span></span></span></span>
    </li>
    <li style="text-align:left">
      <span style="font-size:10.5pt"><span style="color:black"><span style="tab-stops:list 36.0pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Tilbuddet skal skrives p&aring; dansk og klart beskrive hvad der er aftalt omkring udf&oslash;relse, opstart, varighed og pristilbud inklusive moms</span></span></span></span></span></span></span></span>
    </li>
    <li style="text-align:left">
      <span style="font-size:10.5pt"><span style="color:black"><span style="tab-stops:list 36.0pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Arbejdet skal udf&oslash;res i henhold til g&aelig;ldende lovgivning og h&aring;ndv&aelig;rksm&aelig;ssigt korrekt. </span></span></span></span></span></span></span></span>
    </li>
    <li style="text-align:left">
      <span style="font-size:10.5pt"><span style="color:black"><span style="tab-stops:list 36.0pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Som standard benyttes AB standard kontrakt</span></span></span></span></span></span></span></span>
    </li>
  </ol>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Fremtidige link til boligejeren</span></span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">N&aring;r arbejdet er udf&oslash;rt, f&aelig;rdigmelder du arbejdet i myhouse. Vi sender s&aring; boligejeren en kort tilfredsheds unders&oslash;gelse og sp&oslash;rger kunden om han &oslash;nsker at du bliver fast tilknyttet som boligens faste h&aring;ndv&aelig;rker, dette er ud over den faste kontakt ogs&aring; en stor fordel n&aring;r boligejeren fra flytter da du s&aring; automatisk overtager den nye boligejer.</span></span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Opsigelse</span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Denne samarbejdsaftale kan gensidigt opsiges med et varsel p&aring; en m&aring;ned til periodens udl&oslash;b ellers vil den automatisk blive forl&aelig;nget. </span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Misligholdelse</span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Hvis du misligholder aftalen, kan vi uden varsel oph&aelig;ve den.</span></span></span></span></span></span>
    <br />
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Eksempler p&aring; misligholdelse er:</span></span></span></span></span></span>
    <br />
    &nbsp;
  </p>
  <ul>
    <li align="left" style="margin-left:8px; text-align:left">
      <span style="font-size:10.5pt"><span style="tab-stops:list 36.0pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Gentagne gange ikke at overholde de tidsterminer skitseret ovenfor</span></span></span></span></span></span></span>
    </li>
    <li align="left" style="margin-left:8px; text-align:left">
      <span style="font-size:10.5pt"><span style="tab-stops:list 36.0pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Gentagne gange at have udf&oslash;rt d&aring;rligt h&aring;ndv&aelig;rksm&aelig;ssigt arbejde</span></span></span></span></span></span></span>
    </li>
  </ul>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif">&nbsp;</span></span></span>
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Ansvar</span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Ansvaret for udf&oslash;relsen af arbejdsopgaverne samt efterf&oslash;lgende betaling ligger udelukkende hos h&aring;ndv&aelig;rkeren og erstatning kan derfor aldrig g&oslash;res g&aelig;ldende overfor myhouse, hvis du af den ene eller den anden grund skulle lide et tab.</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><u>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Ikrafttr&aelig;delses tidspunkt</span></span></span>
      </u></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Godkend aftale ved at acceptere ovenst&aring;ende vilk&aring;r</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Kontaktoplysninger for myhouse ApS</span></span></span></strong></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Fredensborg Kongevej 56<br />
      2980 Kokkedal CVR nr. 35056823<br />
      E-mail:&nbsp;<a href="mailto:info@myhouse.dk" style="color:#467886; text-decoration:underline"><span style="color:#54602c">info@myhouse.dk</span></a><br />
      Telefon 70271927<br />
      Mandag - fredag kl. 9-16</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">DATAANSVAR<br />
      <br />
      Definitioner </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Den digitale platform myhouse.dk.<a href="https://myhouse.dk/" style="color:#467886; text-decoration:underline" target="_blank"><span style="color:#54602c">www.myhouse.dk</span></a>&nbsp;er den digitale platform vi anvender for at kunne tilbyde dine services som h&aring;ndv&aelig;rker. <strong><span style="font-family:&quot;Calibri Light&quot;,sans-serif">myhouse ApS</span></strong>, er den virksomhed den ejer den digitale platform&nbsp;<a href="https://myhouse.dk/" style="color:#467886; text-decoration:underline" target="_blank"><span style="color:#54602c">www.myhouse.dk</span></a>&nbsp;og har som prim&aelig;rt form&aring;l at st&oslash;tte boligejere.&nbsp;<strong><span style="font-family:&quot;Calibri Light&quot;,sans-serif">CDM A/S</span></strong>, er et softwarehus der siden 1984 har udviklet software og serviceydelser til underst&oslash;ttelse af processer inden for marketing, kundeservice og udbud. CDM A/S ejer myhouse ApS.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Privatlivspolitik for myhouse.dk </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Persondatapolitik er g&aelig;ldende for samtlige personoplysninger, som du giver til myhouse.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Dataansvar </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Vi tager din databeskyttelse alvorligt. Vi behandler persondata og har derfor vedtaget denne persondatapolitik, der fort&aelig;ller dig, hvordan vi behandler dine persondata.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">I henhold til lov om behandling af personoplysninger skal en virksomhed informere sine brugere om, hvordan virksomheden behandler brugerens personoplysninger. Nedenfor kan du l&aelig;se, hvordan myhouse behandler oplysninger om dig.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Den overordnede retslige ramme for vores behandling af personoplysninger er EU&#39;s persondataforordning 2016/679 af 27.04.2016 samt databeskyttelsesloven L 502 Lov om supplerede bestemmelser til forordning om beskyttelse af fysiske personer i forbindelse med behandling af personoplysninger og om fri udveksling af s&aring;danne oplysninger.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Personoplysninger </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">myhouse behandler personoplysninger, der er indsamlet direkte fra dig. I nogle tilf&aelig;lde indsamler vi personoplysninger om dig fra andre. myhouse behandler ikke personoplysninger, som brugere selv l&aelig;gger op p&aring; sociale medier, som fx kommentarer og opslag om myhouse, og myhouse profiler p&aring; Facebook, Twitter, Instagram, YouTube-kanal. myhouse tager ikke ansvar for oplysninger og indl&aelig;g, som andre publicerer. Vi anvender persondata om dig for at g&oslash;re vores service bedre og sikre en h&oslash;j kvalitet. De persondata, vi anvender, omfatter navnlig: Almindelige persondata i form af kontaktinformationer (e-mail, navn, telefonnummer) og oplysninger om din bolig samt oplysninger, der indg&aring;r i henvendelser til os, klager, feedback mv.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Vi beskytter dine persondata </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Vi passer p&aring; dine data for at undg&aring;, at de utilsigtet g&aring;r tabt eller bliver &aelig;ndret, mod uautoriseret offentligg&oslash;relse, og mod at uvedkommende f&aring;r adgang eller kendskab til dem. Vi anvender data behandlere i og udenfor EU som alle skal opbevare og behandle personoplysninger p&aring; vegne af myhouse i henhold til denne datapolitik og den g&aelig;ldende lovgivning i EU. I tilf&aelig;lde af et sikkerhedsbrud, der resulterer i en h&oslash;j risiko for dig for diskrimination, ID-tyveri, &oslash;konomisk tab, tab af omd&oslash;mme eller anden v&aelig;sentlig ulempe, vil vi underrette dig om sikkerhedsbruddet s&aring; hurtigt som muligt.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Du har ret til at f&aring; un&oslash;jagtige persondata rettet eller slettet. </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Du har ret til at f&aring; indsigt i de oplysninger, som vi behandler om dig. Hvis du mener, at de er un&oslash;jagtige, har du ret til at f&aring; dem rettet eller slettet. Du skal henvende dig til os og oplyse os om, hvori un&oslash;jagtighederne best&aring;r, og vil vi instruere dig i hvordan de kan rettes. I nogle tilf&aelig;lde vil vi have en forpligtelse til at slette dine persondata. Det g&aelig;lder fx, hvis du tr&aelig;kker dit samtykke tilbage.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Hvis du mener, at dine persondata ikke l&aelig;ngere er n&oslash;dvendige i forhold til det form&aring;l, som vi indhentede dem til, kan du selv g&aring; ind under din brugerprofil og f&aring; dem slettet. Du kan ogs&aring; kontakte os, hvis du mener, at dine persondata bliver behandlet i strid med lovgivningen eller andre retlige forpligtelser.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">N&aring;r du henvender dig med en anmodning om at f&aring; rettet eller slettet dine persondata, unders&oslash;ger vi, om betingelserne er opfyldt, og gennemf&oslash;rer i s&aring; fald &aelig;ndringer eller sletning s&aring; hurtigt som muligt.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Opbevaring af data </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Vi gemmer dine data s&aring; l&aelig;nge, vi vurderer, at data er tilstr&aelig;kkelige valide til at vi kan r&aring;dgive dig om, hvordan du kan reducere dit klimaaftryk.</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><b>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Samarbejder </span></span></span>
      </b><br />
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Myhouse ApS samarbejder med organisationer der er specialister indenfor specifikke omr&aring;der s&aring;som Energistyrelsen, FDM, Madkulturen,&nbsp;<a href="https://byggesagkyndig.nu/" style="color:#467886; text-decoration:underline" target="_blank"><span style="color:#54602c">Byggesagkyndig.nu</span></a>, Kommunerne m.fl. med det form&aring;l at kunne tilbyde vores slutbrugere dvs. boligejerne l&oslash;sninger, der kan styre boligens i sundere retning samt reducere deres CO2e&nbsp;aftryk samtidigt med at de sparer penge.<br />
        <br />
        Kr&aelig;ver en l&oslash;sning at vi skal dele data med en af vores samarbejdspartnere, vil vi i det p&aring;g&aelig;ldende tilf&aelig;lde bede om samtykke. Dette samtykke kan administreres under brugerprofilen, hvor man ogs&aring; kan slette sig selv.</span></span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Alle sp&oslash;rgsm&aring;l vedr&oslash;rende n&aelig;rv&aelig;rende politik, behandlingen af dine personoplysninger, mistanke om manglende overholdelse skal rettes til myhouse A/S, der er databehandler.<br />
      Send gerne din mail overskriften &rdquo;Persondata&rdquo; til&nbsp;<a href="mailto:info@myhouse.dk" style="color:#467886; text-decoration:underline"><span style="color:#54602c">info@myhouse.dk</span></a></span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Alle danske adresser er at finde i myhouse sammen med anden relevant information vi henter fra de offentlige databaser. myhouse p&aring;tager sig intet ansvar for rigtigheden af de oplysninger, hvor det er anf&oslash;rt at data kommer fra andre kilder og ej heller i forhold til oplysninger, som fremkommer via links.<br />
      <br />
      <strong><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Ansvar </span></strong><br />
      myhouse er et oplysningssite for brugere, der best&aring;r af et antal offentlige tilg&aelig;ngelige kilder. De kilder er vi ikke ansvarlige for. Det vil sige, hvis du finder fejl i offentligt tilg&aelig;ngeligt data, kan myhouse ikke stilles til ansvar for dette. Her skal der rettes henvendelse direkte til kilden. Har du sp&oslash;rgsm&aring;l til dette, kan du altid henvende dig til myhouse og forh&oslash;re dig om hvem du skal kontakte.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Hvis myhouse betragter fejlen som presserende, tager myhouse direkte kontakt til kilden efter din henvendelse.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Ud over oplysninger fra offentlige registre og nyhedsmedier, har du som bruger mulighed for selv at uploade dokumenter og billeder. Man kan som bruger uploade nyt profilbillede af brugeren.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">myhouse har ikke ansvar for rigtigheden af s&aring;danne dokumenter samt billeder, og fraskriver sig ansvar for tab eller beskadigelse af dine data, herunder uploadet materiale.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Fejl i registrerede oplysninger </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Hvis myhouse bliver opm&aelig;rksom p&aring;, at der er fejl i de oplysninger p&aring; dig, som vi har registreret eller at oplysningerne er vildledende, vil vi om muligt, rette oplysningerne. Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den m&aring;de, vi behandler dine oplysninger p&aring;.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Nyhedsbrev og push-meddelelser </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Du kan s&aelig;rskilt tilmelde dig vores kampagner samt push notifikationer ved brug af&nbsp;<a href="http://www.myhouse.dk/" style="color:#467886; text-decoration:underline" target="_blank"><span style="color:#54602c">www.myhouse.dk</span></a>&nbsp;under profil. Du vil altid kunne framelde dig denne service. Du vil ikke modtage nyhedsbreve eller andre uopfordrede kommercielle henvendelser fra vores partnere.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Cookies</span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">P&aring; vores hjemmeside&nbsp;<a href="http://www.myhouse.dk/" style="color:#467886; text-decoration:underline" target="_blank"><span style="color:#54602c">myhouse.dk</span></a>&nbsp;g&oslash;res der brug af &rdquo;cookies&rdquo; til at forbedre din brugeroplevelse af vores hjemmesider, til at indsamle webstatistik og til at styre login. L&aelig;s mere i vores cookiepolitik herunder:</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><strong><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Hvad er cookies </span></span></span></strong><br />
      <span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Cookies anvendes af stort set alle websites. I nogle tilf&aelig;lde er cookies den eneste m&aring;de at f&aring; et website til at fungere efter hensigten.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">En cookie er en lille datafil, der indsamler digitale fodspor om brugerne, n&aring;r de f&aelig;rdes online. Cookies bliver gemt p&aring; brugerens computer, tablet eller smartphone, n&aring;r denne bes&oslash;ger din hjemmeside. Cookies kan s&aring; indsamle information om, hvilke sider brugeren bes&oslash;ger, hvilke artikler brugeren l&aelig;ser, og hvad brugeren k&oslash;ber. Der findes ogs&aring; andre lignende teknologier, som p&aring; tilsvarende vis indsamler oplysninger om brugerne.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Hjemmesider kan bruge cookies og lignende teknologier til mange forskellige form&aring;l. Det kan fx v&aelig;re at sikre, at hjemmesidernes funktioner virker samt at optimere design og kvalitet af en hjemmeside, at give m&aring;lrettet og relevant indhold p&aring; en hjemmeside eller at m&aring;lrette annoncer i kommercielt &oslash;jemed.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">F&oslash;rste gang du lander p&aring; denne hjemmeside, ser du et banner med information om cookies til statistik. Hvis du klikker p&aring; &quot;Afvis&quot;, s&aelig;ttes der ingen cookies til statistik. Vi bruger dog en cookie for at huske dit nej tak til statistik. Hvis du klikker &quot;Accepter&quot;, s&aelig;ttes der cookies til at samle statistik, og banneret forsvinder. Vi bruger statistikken til at forbedre brugervenligheden. Oplysningerne i statistikken er anonyme og bliver ikke koblet til den enkelte bruger.</span></span></span></span></span></span>
  </p>
  <p>
    <span style="font-size:12pt"><span style="background:white"><span style="font-family:&quot;Times New Roman&quot;,serif"><span lang="DA" style="font-size:11.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">S&aring;dan undg&aring;r du cookies: Du kan altid afvise cookies p&aring; din computer ved at &aelig;ndre indstillingerne i din browser. Hvor du finder indstillingerne, afh&aelig;nger af, hvilken browser du anvender. Du skal dog v&aelig;re opm&aelig;rksom p&aring;, at hvis du g&oslash;r det, er der mange funktioner og services p&aring; internettet, du ikke kan bruge. Alle browsere tillader, at du sletter dine cookies samlet eller enkeltvis. Hvordan du g&oslash;r, det afh&aelig;nger af, hvilken browser du anvender. Husk, at bruger du flere browsere, skal du slette cookies i dem alle.</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Med venlig hilsen</span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><b>
      <span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">Myhouse teamet</span></span></span>
      </b></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="DA" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><a href="mailto:Info@myhouse.dk" style="color:#467886; text-decoration:underline">Info@myhouse.dk</a></span></span></span></span></span></span>
  </p>
  <p align="left" style="text-align:left">
    <span style="font-size:10.5pt"><span style="line-height:120%"><span style="font-family:Tahoma,sans-serif"><span lang="EN-US" style="font-size:11.0pt"><span style="line-height:120%"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">CVR: 35 05 68 23</span></span></span></span></span></span>
  </p>
</div>`;

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} md={9} lg={6} sx={{ flex: 1 }}>
        <Box
          dangerouslySetInnerHTML={{ __html: rawHtml }}
          sx={{
            //maxHeight: "calc(100vh - 60px)",
            overflowX: "hidden",
            overflowY: "auto",
            px: 2,
            boxSizing: "border-box",
          }}
        />
      </Grid>
    </Grid>
  );
};

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} md={9} lg={6} sx={{ flex: 1 }}>
        <TermsContent />
        <Grid item container sx={{ mt: 2 }}>
          <Grid item px={1}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
              Tilbage
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
