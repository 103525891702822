import { User, Task, AppAction, Common } from "../../constants/actionTypes";
import { EmptyGuid } from "../../constants/appConstants";
import { SearchParams } from "../../interfaces/frontend";
import {
  CraftsmanTask,
  PictureRef,
  SearchResult,
  Solution,
  TaskList,
  TaskListListItem,
  TaskListStatus,
  TaskStatistics,
} from "../../interfaces/models";

export interface TaskState extends TaskList {
  count: number;
  error: boolean;
  firstRun: boolean;
  notSaved: boolean;
  loading: boolean;
  tasks: CraftsmanTask[];
  taskLists: TaskListListItem[];
  pictures: PictureRef[];
  uploadProgress: number;
  stats: TaskStatistics;
  totalPrice: number;
  discount: number;
  changed: boolean;
  localDataFound: boolean;
  search: SearchParams<TaskListStatus>;
  solutions: SearchResult<Solution>;
}

const initialState: TaskState = {
  id: EmptyGuid,
  created: new Date(),
  modified: new Date(),
  craftsmanId: "",
  count: 0,
  error: false,
  firstRun: true,
  notSaved: false,
  loading: false,
  data: {
    postcode: 0,
    address: "",
    city: "",
    cadastral: "",
    caseNumber: 0,
    reportDate: new Date(),
    houseId: EmptyGuid,
    municipality: "",
    owner: "",
    ownerLastName: "",
    latitude: 0,
    longitude: 0,
    picture: "",
    ownerPhone: "",
    ownerEmail: "",
    area: 0,
    livingArea: 0,
    unitArea: 0,
    groundArea: 0,
    basementArea: 0,
    buildYear: 0,
    rooms: 0,
    propertyType: "",
    unitType: "",
    energyLabel: ""
  },
  totalPrice: 0,
  discount: 0,
  tasks: [],
  taskLists: [],
  pictures: [],
  uploadProgress: 0,
  stats: {
    id: "",
    created: new Date(),
    modified: new Date(),
    totalTasks: 0,
    finishedTasks: 0,
    stats: { results: [], count: 0 },
  },
  status: TaskListStatus.New,
  version: 0,
  changed: false,
  draft: true,
  localDataFound: false,
  search: {
    query: "",
    status: TaskListStatus.None,
    skip: 0,
    take: 10,
    sortField: "Address",
    sortDescending: false,
  },
  solutions: {
    results: [],
    count: 0,
  },
  taskNote: "",
};

const taskReducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case Common.GET_HOUSE_DATA:
    case Task.GET_TASK_LIST:
    case Task.GET_TASK_LISTS:
    case Task.SHARE_TASK_LIST:
    case Task.GET_SOLUTIONS:
      return { ...state, loading: true };
    case Task.GET_TASK_LISTS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        firstRun: false,
        taskLists: action.payload.results,
        count: action.payload.count,
      };
    case Common.GET_HOUSE_DATA_SUCCEEDED:
      return { ...state, loading: false };
    case Task.GET_TASK_LIST_SUCCEEDED:
    case Task.SAVE_TASK_LIST_SUCCEEDED:
      return { ...state, loading: false, firstRun: false, taskNote: action.payload.taskNote ? action.payload.taskNote : "", ...action.payload, changed: false };
    case Task.ADD_TASK:
      return { ...state, tasks: [...state.tasks, action.payload], changed: true };
    case Task.REMOVE_TASK:
      return { ...state, tasks: state.tasks.filter((x) => x.id !== action.payload), changed: true };
    case Task.ADD_PICTURE:
      return {
        ...state,
        changed: true,
        tasks: state.tasks.map((x) => {
          if (x.id === action.payload.itemId) {
            return { ...x, pictures: x.pictures ? [...x.pictures, action.payload] : [action.payload] };
          }
          return x;
        }),
      };
    case Task.CHANGE_VALUE:
      return {
        ...state,
        //No need to trigger "changed" because of the calclulation
        changed: action.payload.key === "estimatedPrice" ? state.changed : true,
        tasks: state.tasks.map((x) => {
          if (x.id === action.payload.id) {
            return { ...x, [action.payload.key]: action.payload.value };
          }
          return x;
        }),
      };
    case Task.REMOVE_PICTURE:
      return {
        ...state,
        changed: true,
        tasks: state.tasks.map((x) => {
          if (x.pictures && x.pictures.some((p) => p.id === action.payload)) {
            return { ...x, pictures: x.pictures.filter((p) => p.id !== action.payload) };
          }
          return x;
        }),
      };
    case Task.CHANGE_CUSTOMER_INFO:
      return { ...state, data: { ...state.data, [action.payload.key]: action.payload.value }, changed: true };
    case Task.SET_HOUSE_DATA:
      return {
        ...state,
        changed: true,
        data: { ...state.data, ...action.payload },
      };
    case Task.CHANGE_TASK_STATUS_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        // ...state,
        // tasks: state.tasks.map((x) => {
        //   if (x.id === action.payload.taskId) {
        //     return { ...x, status: action.payload.status };
        //   }
        //   return x;
        // }),
      };
    case Task.CHANGE_TASK_LIST_STATUS_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
      };
    case Task.SHARE_TASK_LIST_SUCCEEDED:
      return { ...state, loading: false };
    case Task.GET_TASK_STATS_SUCCEEDED:
      return {
        ...state,
        stats: action.payload,
      };
    case Task.CALCULATE_TOTAL:
      return {
        ...state,
        totalPrice: calculateTotalPrice(state.tasks),
      };
    case Task.SET_DISCOUNT:
      return { ...state, discount: action.payload };
    case Task.TASK_OPERATION_FAILED:
      return { ...state, loading: false, error: action.payload };
    case User.LOGOUT:
    case User.CLEAR_USER_DATA:
    case Task.CLEAR_TASK_LIST:
      return {
        ...initialState,
        changed: false,
      };
    case Task.UPDATE_CUSTOMER_INFO:
      return { ...state, data: { ...state.data, ...action.payload }, changed: true };
    case Task.UPDATE_TASK_INFO: {
      return {
        ...state,
        changed: true,
        data: {
          ...state.data,
          ...action.payload,
        },
        tasks: state.tasks.map((t) => (t.id === action.payload.id ? action.payload : t)),
      };
    }
    case Task.UPDATE_TASKLIST_NOTE: {
      return {
        ...state,
        changed: true,
        taskNote: action.payload,        
      };
    }
    case Task.LOCAL_DATA_FOUND:
      return { ...state, localDataFound: true };
    case Task.LOAD_LOCAL_DATA:
      return { ...state, ...action.payload, changed: true };
    case Task.SAVE_SEARCH_PARAMS:
      return { ...state, search: action.payload };
    case Task.GET_SOLUTIONS_SUCCEEDED:
      return { ...state, solutions: action.payload, loading: false };
    default:
      return state;
  }
};

export default taskReducer;

const calculateTotalPrice = (tasks: CraftsmanTask[]) => {
  let total = 0;
  for (let i = 0; i < tasks.length; i++) {
    let task = tasks[i];
    const solutionPrice = task.solutions?.reduce((a: number, c: Solution) => a + c.unitPrice, 0) || 0;
    total +=
      (task.estimatedPrice ?? 0) +
      (task.estimatedMaterialPrice ?? 0) +
      solutionPrice +
      solutionPrice * 0.25 +
      ((task.estimatedPrice ?? 0) + (task.estimatedMaterialPrice ?? 0)) * 0.25;
  }

  return Math.round((total + Number.EPSILON) * 100) / 100;
};