import { Controller, useForm } from "react-hook-form";
import { RegistrationContext, StepsComponentProps } from "../../RegistrationPage";
import { useContext, useEffect, useState } from "react";
import DawaService from "../../../../services/AddressService";
import { ReadOnlyTextField, StyledForm } from "../StyledComponents";
import { Checkbox, FormControl, FormControlLabel, Grid, Link, TextField, Typography, useTheme } from "@mui/material";
import { FormattedTextField } from "../../../../shared/FormattedFields";
import NavigationButtons from "./NavigationButtons";
import TermsOfUseDialog from "./TermsOfUseDialog";

type ProfileFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  cvr: string;
  address: string;
  postCode: number;
  phone: string;
  mobile: string;
  city: string;
  acceptTerms: boolean;
  acceptEmails: boolean;
};

const Profile = (props: StepsComponentProps) => {
  const context = useContext(RegistrationContext);
  const [termsShown, setTermsShown] = useState(false);
  const theme = useTheme();

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<ProfileFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      organization: "",
      cvr: "",
      address: "",
      postCode: undefined,
      phone: "",
      mobile: "",
      city: "",
    },
    mode: "onBlur",
  });

  const watchPostCode = watch("postCode");

  useEffect(() => {
    const getAsync = async () => {
      let city = await DawaService.getDawaCityByPostCode(watchPostCode);
      if (city) {
        setValue("city", city);
        clearErrors("postCode");
      } else {
        setError("postCode", { type: "manual", message: "Ugyldigt postnummer" });
        setValue("city", "");
      }
    };
    if (watchPostCode && /^[0-9]{4}$/.test(watchPostCode.toString())) {
      getAsync();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPostCode]);

  useEffect(
    () => {
      reset({ ...context, postCode: context.postCode ? context.postCode : undefined });
      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const submit = (data: ProfileFormValues) => {
    context.firstName = data.firstName;
    context.lastName = data.lastName;
    context.email = data.email;
    context.organization = data.organization;
    context.cvr = data.cvr;
    context.address = data.address;
    context.postCode = data.postCode;
    context.city = data.city;
    context.phone = data.phone;
    context.mobile = data.mobile;
    props.nextStep();
  };

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <Grid item container xs={12} sx={{ flex: 1, justifyContent: "center", mt: 5 }}>
        <Grid container item xs={12} sm={5} direction={"column"} mx={1}>
          <Typography variant="button">Virksomhed</Typography>
          <Grid item>
            <Controller
              name="cvr"
              control={control}
              rules={{ required: true, pattern: /^[0-9]{8}$/ }}
              render={({ field }) => (
                <FormattedTextField
                  format="########"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  margin="dense"
                  id="cvr"
                  label="CVR"
                  error={!!errors.cvr}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="organization"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  id="organization"
                  label="Firma"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.organization}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="address"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  id="address"
                  label="Adresse"
                  type="text"
                  error={!!errors.address}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="postCode"
                control={control}
                rules={{ required: true, pattern: /^[0-9]{4}$/ }}
                render={({ field }) => (
                  <FormattedTextField
                    format="####"
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    id="postCode"
                    label="Postnr."
                    error={!!errors.postCode}
                    helperText={errors.postCode?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <ReadOnlyTextField
                autoComplete="off"
                margin="dense"
                fullWidth
                id="city"
                label="By"
                type="text"
                {...register("city")}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormattedTextField
                  format="+45 ## ## ## ##"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  label="Tlf"
                  type="tel"
                  error={!!errors.phone}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={5} direction={"column"} mx={1}>
          <Typography variant="button">Bruger</Typography>
          <Grid item>
            <Controller
              control={control}
              rules={{ required: true }}
              name="firstName"
              render={({ field }) => (
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  id="firstName"
                  label="Fornavn"
                  error={!!errors.firstName}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              rules={{ required: true }}
              name="lastName"
              render={({ field }) => (
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  id="lastName"
                  label="Efternavn"
                  error={!!errors.lastName}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              rules={{ required: true }}
              name="email"
              render={({ field }) => (
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  id="email"
                  label="Email"
                  error={!!errors.email}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="mobile"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormattedTextField
                  format="+45 ## ## ## ##"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  label="Mobil"
                  type="tel"
                  error={!!errors.mobile}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={10} direction={"column"} mx={1} alignItems={"flex-start"}>
          <Grid item>
            <Controller
              name="acceptTerms"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl error={!!errors.acceptTerms} required>
                  <FormControlLabel
                    control={<Checkbox />}
                    {...field}
                    label={
                      <Typography color={!!errors.acceptTerms ? "error" : ""}>
                        Jeg accepter myhouse.dk{" "}
                        <Link
                          color={!!errors.acceptTerms ? theme.palette.error.main : "inherit"}
                          href="#"
                          onClick={() => setTermsShown(true)}
                        >
                          Brugervilkår og Datapolitik.
                        </Link>
                      </Typography>
                    }
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="acceptEmails"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox checked={field.value} color={errors.acceptEmails ? "error" : "primary"} />}
                  {...field}
                  label={<Typography>myhouse.dk må sende mig information digitalt.</Typography>}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <NavigationButtons prevStep={props.prevStep} loading={false} isLastStep={props.isLastStep} />
      <TermsOfUseDialog open={termsShown} handleClose={() => setTermsShown(false)} />
    </StyledForm>
  );
};

export default Profile;
